<template>
    <div
        class="bg_light"
        style="display: flex; flex-direction: column; align-items: center;"
    >
        <img src="@/assets/_img/program_1.png" style="width:100%" />
        <div class="spacer_xl" />
        <div style=" display: flex; flex-direction: row; padding: 2vh 12vw;">
            <!-- <div class="row"> -->
            <span class="gradient_text_2xl" style="width: 45%;">
                Mental Health<br />Self Care Program
            </span>
            <span
                class="weight300"
                style="padding-left: 3vw; padding-bottom: 10vh;"
            >
                웰링비의 정신건강 셀프케어 프로그램은 2012년
                <span class="main"><b>NAAPIMHA</b></span
                >라는 단체에서 만든 프로그램으로
                <span class="main"
                    >하버드대 의대 정신건강학과 <b>Ed Wang</b> 교수와 미
                    보건복지부 지원</span
                >으로 만들었습니다.<br />
                <span class="main_brand_subdark"
                    ><b>AWH(Achieving Whole Health:</b> 몸, 마음, 정신의
                    조화)</span
                >은 사람들에게 건강한 삶을 살 수 있는 방법을 알려주고 도와주는
                프로그램입니다.<br />
                <div class="spacer_lg" />
                <div class="row" style="justify-content: center;">
                    <span
                        style="border: 2px solid #2DB24E; padding: 2vh 0; flex: 1; text-align: center;"
                        class="rnd_md main"
                        >문의하기
                        <i class="fas fa-comment-dots"></i>
                    </span>
                    <span class="spacer_xs"></span>
                    <router-link
                        to="/programDetails"
                        class="bg_green rnd_md"
                        style="color: #fff; padding: 2vh 0; text-align: center;  flex:1;"
                    >
                        신청하기 <i class="fas fa-arrow-right"></i>
                    </router-link>
                </div>
            </span>
        </div>
        <div class="flex_box bg_white" style="padding: 10vh 0vw; width:100%;">
            <span
                style="padding: 1vh 0vw; border: 2px solid #cccccc; border-radius: 100px; width: 40%"
                class="font13 weight500 gray600 flex_box"
            >
                #손쉬운 온라인 교육
            </span>
            <div class="spacer_md"></div>
            <span
                class="gray900 font40"
                style="text-align: center; width: 40%;"
            >
                몸, 마음, 정신을 함께 케어하는 웰니스 온라인 교육 프로그램
            </span>
            <img
                src="@/assets/_img/program_2.png"
                style="width: 624px; height:600px;"
            />
        </div>
        <div class="bg_gray300 flex_box" style="padding: 12vh 10vh 4vw;">
            <div
                style="padding: 1vh 0vw; border: 2px solid #cccccc; border-radius: 100px; width: 40%"
                class="font13 weight500 gray600 flex_box"
            >
                #습관부터 차근차근
            </div>
            <div class="spacer_md"></div>
            <div class="gray900 font40" style="text-align: center; width: 40%;">
                건강한 생활습관을 만들도록 도와주는 교육 프로그램
            </div>
            <div class="spacer_lg"></div>
            <div>
                NAAPIMHA가 미 보건복지부 지원으로 만든 교육입니다.<br />
                웰링비와 함께 누구나 참여할 수 있는 온라인 프로그램을
                만들었습니다.
            </div>
            <div class="spacer_lg"></div>
            <img
                src="@/assets/_img/program_3.png"
                style="width: 100%; height: auto;"
            />
        </div>
        <div class="flex_box bg_white" style="padding: 10vh 4vw; width:100%;">
            <span
                style="padding: 1vh 0vw; border: 2px solid #cccccc; border-radius: 100px; width: 40%"
                class="font13 weight500 gray600 flex_box"
                >#누구나 만족</span
            >
            <div class="spacer_md"></div>
            <div class="gray900 font40" style="text-align: center; width: 40%;">
                10년동안 50,000명 기준 4.5점 교육 효용성을 인정
            </div>
            <div class="spacer_lg"></div>
            <div>
                프로그램을 진행하면서 각 신체·심적·정신적 변화에 대하여 교육
                효용성, 문화적 관련성, 타인에게 추천 여부에 따라 설문조사를 한
                결과입니다.
            </div>
            <div class="spacer_xl"></div>
            <img src="@/assets/_img/program_4.png" />
        </div>
        <div class="bg_gray300 flex_box" style="padding: 12vh 10vh 4vw;">
            <div
                style="padding: 1vh 0vw; border: 2px solid #cccccc; border-radius: 100px; width: 150px"
                class="font13 weight500 gray600 flex_box bg"
            >
                #심플한 프로그램 구성
            </div>
            <div class="spacer_md"></div>
            <div class="gray900 font40" style="text-align: center; width: 50%;">
                AWH 교육 프로그램 안내
            </div>
            <div class="spacer_lg"></div>
            <img
                src="@/assets/_img/program_5.png"
                style="width: 100%; height: auto;"
            />
        </div>
    </div>

    <!-- <section class="blog">
        <h3 style="align-self: flex-start;">웰니스 온라인 교육 소개</h3>
        <hr class="spacer" />
        <br/>
        <img src="@/assets/_img/program1.png" />
        <br/>
        <img src="@/assets/_img/program2.png" />
        <br/>
        <img src="@/assets/_img/program3.png" />
        <br/>
        <img src="@/assets/_img/program4.png" />
    </section> -->
</template>

<script>
export default {
    name: "Program",
    data() {
        return {};
    },
    methods: {},
};
</script>

<style scoped>
.blog img {
    max-width: 65vw !important;
}
</style>
