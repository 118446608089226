<template>
    <section id="main">
        <div class="container main_container noHeaderPicSpace">
            <section id="content">
                <div id="vueContainer" v-cloak>
                    <h3>My Classes</h3>

                    <small>
                        <div style="float: left; width: 50%;">
                            <i class="fa fa-user-plus"></i> Interested Students
                            <br />
                            <i class="fa fa-user-check"></i> Confirmed Students
                        </div>
                        <div style="float: left; width: 50%;">
                            <i class="fa fa-user-graduate"></i> Teachers <br />
                            <i class="fa fa-calendar-alt"></i> Sessions
                        </div>
                        <div style="clear: both;"></div>
                    </small>

                    <div class="shadowbox" style="margin-top: 2vh;">
                        <div class="table-div" style="width: 99%;">
                            <div class="th">
                                <div style="width: 25%;">Class</div>
                                <div class="center">
                                    <i
                                        class="fa fa-user-plus"
                                        title="Interested Students"
                                    ></i>
                                </div>
                                <div class="center">
                                    <i
                                        class="fa fa-user-check"
                                        title="Confirmed Students"
                                    ></i>
                                </div>
                                <div class="center">
                                    <i
                                        class="fa fa-user-graduate"
                                        title="Teachers"
                                    ></i>
                                </div>
                                <div class="center">
                                    <i
                                        class="fa fa-calendar-alt"
                                        title="Sessions"
                                    ></i>
                                </div>
                                <div>First Class</div>
                                <div>Last Class</div>
                            </div>
                            <div v-for="(c, cindex) in classData" :key="cindex">
                                <div :style="rowCss + 'font-weight: bold;'">
                                    <Classcode
                                        :code="c.code"
                                        :color="c.color"
                                    ></Classcode>
                                    <a :href="paths.class + c.id">{{
                                        c.title
                                    }}</a>
                                </div>
                                <div :style="rowCss" class="center">
                                    {{ c.cnt_student_interested }}
                                </div>
                                <div
                                    :style="
                                        rowCss +
                                            setNumberStyle(
                                                c.cnt_student_confirmed
                                            )
                                    "
                                    class="center"
                                >
                                    {{ c.cnt_student_confirmed }}
                                </div>
                                <div
                                    :style="
                                        rowCss + setNumberStyle(c.cnt_teacher)
                                    "
                                    class="center"
                                >
                                    {{ c.cnt_teacher }}
                                </div>
                                <div
                                    :style="
                                        rowCss + setNumberStyle(c.cnt_session)
                                    "
                                    class="center"
                                >
                                    {{ c.cnt_session }}
                                </div>
                                <div :style="rowCss">
                                    {{ showDate(c.s1_start) }}
                                </div>
                                <div :style="rowCss">
                                    {{ showDate(c.sx_start) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <endless
                        ref="endlessLoader"
                        :loadpath="paths.loadmore"
                        :perpage="parseInt(perpage)"
                        mainprop="classes"
                        @loaded="appendLoaded"
                    ></endless>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import Classcode from "@/components/Classcode";
import endless from "@/components/endless";
import mixinOutputHelpers from "@/components/mixin-output-helpers.js";
import moment from "moment";
export default {
    name: "ClassListCoaches",
    mixins: [mixinOutputHelpers],
    components: {
        Classcode,
        endless,
    },
    data() {
        return {
            classData: [],
            perpage: "",

            paths: {
                class: "/coach/course/",
                loadmore: "/teacher/loadmore/courses",
            },

            rowCss: "border-bottom: 1px lightgrey solid;",
        };
    },
    created() {
        axios
            .get("/teacher/classlist/init")
            .then((res) => {
                this.classData = res.data.classData;
                this.perpage = res.data.perpage;
            })
            .catch((err) => console.log(err));
    },
    methods: {
        appendLoaded: function(loadedData) {
            this.classData.push.apply(this.classData, loadedData.classes);
        },
        setNumberStyle: function(num) {
            if (num == 0) return "color: red; font-weight: bold;";
            return "";
        },
        showDate(string) {
            if (string == 0) return "";
            return moment(string).format("YYYY-MM-DD HH:mm (ddd)");
        },
    },
};
</script>

<style></style>
