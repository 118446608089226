<template>
  <section id="main">
    <div class="container">

<p><span style="font-weight: 400;">개인정보 처리 방침</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">주식회사 웰링비 (이하 "회사"라 함)는 개인정보보호법, 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.&nbsp;</span></p>
<p><span style="font-weight: 400;">회사의 개인정보처리방침은 다음과 같습니다.</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">제 1조. 개인정보의 수집 항목 및 수집 방법</span></p>
<p><span style="font-weight: 400;">① 수집항목</span></p>
<p><span style="font-weight: 400;">회사는 회원가입, 고객상담, 각종 서비스의 제공을 위해 최초 회원가입 당시 아래와 같은 개인정보를 수집하고 있습니다.</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;1) 필수 항목 : 이메일, 닉네임, 비밀번호, 성별, 나이</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;2) 서비스 이용과정이나 사업처리 과정에서 자동으로 생성되어 수집될 수 있는 항목 : IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, SNS 계정정보, 자가진단 및 교육 서비스 관련 과제, 숙제에 따른 개인정보</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;3) 서비스를 이용하기 위한 결제과정에서 수집되는 항목 : 성명, 휴대폰 번호, 신용카드 결제정보(카드사명, 카드 번호 등), 은행계좌정보 등</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;4) 부가 서비스 및 맞춤형 개인화 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해 수집될 수 있는 항목 : 이동전화번호, 통신사, 주소, 기타 물품수령에 필요한 개인정보</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;5) 선택 : 이용자의 선택에 따라 본인의 SNS 계정 등을 연결하기 위한 계정 정보, 휴대폰 번호</span></p>
<p><span style="font-weight: 400;">② 개인정보 수집 방법</span></p>
<p><span style="font-weight: 400;">서비스 이용 또는 상담 신청 과정에서 수집</span></p>
<p><span style="font-weight: 400;">팩스, 전화, 상담, 이메일, 이벤트 응모 등의 과정에서 수집</span></p>
<p><span style="font-weight: 400;">협력회사로부터의 정보 수집 툴을 통한 수집</span></p>
<p><span style="font-weight: 400;">③ 유료 상담시 서비스의 특성상 상담 신청 전 또는 상담과정에서 개인의 건강과 관련된 정보가 수집되며, 상담 내용과 진행에 따라 추가적인 개인정보가 포함될 수 있습니다.</span></p>
<p><span style="font-weight: 400;">유료 상담 과정에서 수집되는 개인정보에는 민감정보가 포함될 수 있습니다.&nbsp;</span></p>
<p><span style="font-weight: 400;">직업, 학력, 상담경험, 정신과 진료경험, 신체적/정신적 호소 문제, 성장 과정 등&nbsp;</span></p>
<p><span style="font-weight: 400;">④ 유료 전화 상담(보이스 테라피)시 인터넷 환경의 문제로 시스템 전화 연결이 불안정할 경우 050 가상번호를 이용하여 상담이 진행될 수 있습니다. 이 때 담당 상담사에게 전화번호가 노출될 수 있으며 회사는 해당 번호를 수집하지 않습니다.&nbsp;</span></p>
<p><span style="font-weight: 400;">이외에도 서비스 이용 과정에서 서비스 이용 기록, 기기정보가 생성되어 수집될 수 있습니다.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">제 2조. 개인정보 수집 및 이용 목적</span></p>
<p><span style="font-weight: 400;">회사는 회원관리(회원 가입 의사 확인, 본인확인, 개인식별, 회원탈퇴 의사 확인 등), 회사 서비스 제공, 서비스 개발 및 개선, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.&nbsp;</span></p>
<p><span style="font-weight: 400;">① 회사 서비스 제공</span></p>
<p><span style="font-weight: 400;">서비스 이용요금 결제, 콘텐츠 제공, 상담 서비스 제공, 이메일 인증, 물품배송 또는 청구서 등 발송</span></p>
<p><span style="font-weight: 400;">② 회원관리</span></p>
<p><span style="font-weight: 400;">회원제 서비스 이용, 본인확인, 개인식별, 서비스 이용요금 결제와 상담 등 서비스 이용 과정에서 법령 및 회사 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용방지와 비인가 사용방지, 가입의사 확인, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달</span></p>
<p><span style="font-weight: 400;">③ 신규 서비스 개발 및 마케팅</span></p>
<p><span style="font-weight: 400;">신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 회원의 서비스 이용에 대한 통계, 접속빈도 파악, 심리 상담 관련 통계나 연구 등을 통해 심리상담 솔루션 개발 및 개선</span></p>
<p><span style="font-weight: 400;">④ 맞춤형 개인화 서비스 개발</span></p>
<p><span style="font-weight: 400;">개인정보 및 상담에 기반한 맞춤형 추천 알고리즘 등의 서비스 개발 및 개선</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">제 3조. 개인정보 공유 및 제공</span></p>
<p><span style="font-weight: 400;">① 회사는 이용자들의 개인정보를 &ldquo;제 2조 개인정보 수집 및 이용 목적&rdquo; 에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우를 예외로 하며 자세한 내용은 별첨 [개인정보 목적 외 이용 및 제3자 제공 지침]을 따릅니다.&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;1) 이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인정보 제공에 동의를 한 경우</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 또는 정보기관 등의 요구가 있는 경우</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;3) 이용자의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한 경우</span></p>
<p><span style="font-weight: 400;">② 기타 세금과 관련하여 부가가치세 징수 목적 또는 연말정산 등을 위해 관계법령에 따라 개인정보가 국세청 또는 관세청 등 과세 관련 처분청에 제공될 수 있습니다.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">제 4조. 개인정보의 처리 위탁</span></p>
<p><span style="font-weight: 400;">① 회사는 편리하고 더 나은 서비스를 제공하기 위해 업무 중 일부를 외부에 위탁할 수 있습니다.&nbsp;</span></p>
<p><span style="font-weight: 400;">② 회사는 이용자들이 전문적인 상담서비스를 제공받게 하기 위하여 심리상담 서비스 제공업무를 제휴된 심리상담사에게 위탁하고 있습니다.&nbsp;</span></p>
<p><span style="font-weight: 400;">③ 회사는 서비스 제공을 위하여 필요한 업무중 일부를 외부 업체에 위탁할 수 있으며, 위탁할 경우 위탁 받은 업체가 정보통신망법에 따라 개인정보를 안전하게 처리하도록 필요한 사항을 규정하고 관리/감독을 합니다.</span></p>
<p><span style="font-weight: 400;">④ 회사는 서비스 향상을 위하여 아래와 같이 개인정보를 위탁하고 있으며, 개인정보의 수탁자와 위탁업무의 범위는 아래와 같습니다.&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;(1) 나이스페이먼츠(주), 카드사 : 신용카드, 휴대폰, 가상계좌 등을 통한 결제처리 및 결제도용 방지&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;(2) 제휴된 심리상담사들 : 심리상담 서비스 제공, 심리검사 내용 확인 및 해석 등 상담 서비스와 관련된 업무 중 일부</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">제 5조. 개인정보 보유 및 이용 기간</span></p>
<p><span style="font-weight: 400;">원칙적으로 이용자가 서비스에 가입한 날로부터 계정 삭제 또는 해지 시까지 개인정보를 보유 및 이용합니다. 회원 탈퇴 시 회사는 이용자의 수집된 개인정보가 열람 또는 이용될 수 없도록 파기 처리합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">제 6조. 개인정보 파기 절차 및 방법</span></p>
<p><span style="font-weight: 400;">① 회사는 원칙적으로 이용자가 회원 탈퇴 시 회사는 특정 보호 기간을 거쳐 이용자의 수집된 개인정보가 열람 또는 이용될 수 없도록 파기 처리합니다. 단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보 보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.&nbsp;</span></p>
<p><span style="font-weight: 400;">② 개인정보 파기의 절차 및 방법은 다음과 같습니다.&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;1) 파기 절차 : 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져 (종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;2) 파기 방법 : 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적인 방법을 사용하여 삭제합니다.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">제 7조. 개인정보의 수집/이용/제공 동의 철회&nbsp;</span></p>
<p><span style="font-weight: 400;">이용자가 회원가입 등을 통해 개인정보의 수집/이용/제공에 대해 동의하신 내용을 언제든지 철회할 수 있습니다. 동의 철회는 관리 책임자에게 전자적 통신수단(E-mail 등) 및 앱 상의 기능 등을 이용하여 신청할 수 있으며, 회사는 이를 확인 후 회원 탈퇴를 위해 필요한 조치를 취합니다.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">제 8조. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</span></p>
<p><span style="font-weight: 400;">① 회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.</span></p>
<p><span style="font-weight: 400;">② 쿠키는 이용자들이 방문한 마인드카페의 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 뉴스편집, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.</span></p>
<p><span style="font-weight: 400;">③ 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.&nbsp;</span></p>
<p><span style="font-weight: 400;">④ 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.&nbsp;</span></p>
<p><span style="font-weight: 400;">설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보&nbsp;</span></p>
<p><span style="font-weight: 400;">다만, 쿠키의 저장을 거부할 경우 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">제 9조. 개인정보보호를 위한 기술적/관리적 대책</span></p>
<p><span style="font-weight: 400;">회사는 이용자의 개인정보를 보호하기 위해 기술적 대책과 관리적 대책을 마련하고 있으며, 이를 적용하고 있습니다.&nbsp;</span></p>
<p><span style="font-weight: 400;">① 개인정보 암호화</span></p>
<p><span style="font-weight: 400;">회사의 회원 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.</span></p>
<p><span style="font-weight: 400;">② 해킹 등에 대비한 대책</span></p>
<p><span style="font-weight: 400;">회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.</span></p>
<p><span style="font-weight: 400;">③ 취급 직원의 최소화 및 교육</span></p>
<p><span style="font-weight: 400;">회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 회사의 개인정보처리방침의 준수를 항상 강조하고 있습니다.</span></p>
<p><span style="font-weight: 400;">④ 개인정보보호전담기구의 운영</span></p>
<p><span style="font-weight: 400;">사내 개인정보보호전담기구 등을 통하여 회사의 개인정보처리방침 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">제 10조. 이용자의 권리</span></p>
<p><span style="font-weight: 400;">이용자는 언제든지 본 서비스에 등록되어 있는 본인의 개인정보를 열람하거나 정정할 수 있으며, 전자우편 또는 앱 상의 기능을 이용하여 요청하신 경우 열람, 정정, 삭제처리 해드리겠습니다.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;제 11조. 개인 정보 관련 담당자 연락처</span></p>
<p><span style="font-weight: 400;">귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다.</span></p>
<p><span style="font-weight: 400;">회사는 회원의 신고사항에 대해 신속하게 답변을 드릴 것입니다.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">개인정보보호책임자</span></p>
<p><span style="font-weight: 400;">이름: 이연하</span></p>
<p><span style="font-weight: 400;">소속: 주식회사 웰링비</span></p>
<p><span style="font-weight: 400;">직위: 대표</span></p>
<p><span style="font-weight: 400;">이메일: wellingbe@naver.com</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">개인정보관리담당자</span></p>
<p><span style="font-weight: 400;">이름: 김성훈</span></p>
<p><span style="font-weight: 400;">소속: 주식회사 웰링비</span></p>
<p><span style="font-weight: 400;">직위: 개발실 실장</span></p>
<p><span style="font-weight: 400;">이메일: wellingbe@naver.com</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</span></p>
<p><span style="font-weight: 400;">1) 개인정보침해신고센터 (http://www.118.or.kr / 국번없이 118)</span></p>
<p><span style="font-weight: 400;">2) 대검찰청 사이버 범죄 수사단 (http://www.spo.go.kr / 02-3480-2000)</span></p>
<p><span style="font-weight: 400;">3) 경찰청 사이버테러대응센터 (http://www.ctrc.go.kr / 1566-0112)</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">제 12조. 기타</span></p>
<p><span style="font-weight: 400;">회사에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "회사 개인정보처리방침"이 적용되지 않음을 알려 드립니다.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">제 13조 . 고지의 의무</span></p>
<p><span style="font-weight: 400;">현 &ldquo;회사 개인정보처리방침&rdquo;에 내용이 추가되거나 삭제 및 수정이 있을 시 개정 최소 7일 전부터 홈페이지의 &lsquo;공지사항&rsquo; 및 다양한 방법으로 고지할 것입니다. 단, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">공고일자: 2021.02.13</span></p>
<p><span style="font-weight: 400;">시행일자: 2021.02.13</span></p>
<p><br /><br /><br /><br /><br /></p>
<p><span style="font-weight: 400;">[개인정보 목적 외 이용 및 제3자 제공 지침]</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">주식회사 웰링비는 본 지침을 통해 수집,보유하고 있는 개인정보를 외부기관에 제공시 기준, 범위, 내용 등에 대한 판단기준을 명확히 하고자 하며, 외부기관 개인정보자료 제공등에 관하여 다른 법령, 규정 또는 지침에서 특별히 정한 것을 제외하고는 본 지침을 따릅니다.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">관련근거&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;개인정보보호법 제17조(개인정보의 제공), 제18조(개인정보의 이용,제공 제한), 제24조 (고유식별정보의 처리 제한), 개인정보보호법 시행령 제15조(개인정보의 목적 외 이용 또는 제3자 제공의 관리), 개인정보보호법 시행규칙 제2조(공공기관에 의한 개인정보의 목적 외 이용 또는 제3자 제공의 공고)</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">1.기본원칙&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;가.자료제공판단원칙&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 목적의 정당성 : 구체적으로 어떠한 목적을 위하여 당해 개인정보가 필요한지?</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 수단의 적정성 : 당해 개인정보를 제공함으로써 당해 공익목적을 달성할 수 있는 것인지?</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 피해의 최소성 : 목적달성을 위하여 필요한 최소한의 정보는 어디까지인지?&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 법익의 균형성 : 제공에 따른 이익과 정보주체가 받을 수 있는 예상피해를 비교하여 전자가 우월하다고 할 수 있는지 여부 판단</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;※ 판단원칙을 종합적으로 검토한 후 필요한 최소한의 범위내에서 제공&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;나. 요청기관의 적격 여부 확인&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 요청기관의 개별법에 자료요청의 근거조항이 구체적으로 명시된 경우 제공 가능</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 요청기관의 개별법에 자료요청 근거법이 없는 경우 정보 주체의 동의가 있었는지 여부 등 예외적 제공가능 사항에 해당되는지 확인한 후 제공 여부 결정&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;다. 제공항목 판단&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 본사가 직접 수집,생산한 개인정보가 아닌경우 자료제공 불가 (단, 정보주체의 동의가 있는 경우 제공 가능)</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 요청 목적에 부합하는 최소항목만 제공(개인정보 최소제공 원칙)</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 민감정보는 법령(법률,시행령,시행규칙)에 민감정보의 처리를 요구(허용)하도록 규정되어 있는 경우 제공&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">2.개인정보제공기준</span></p>
<p><span style="font-weight: 400;">&nbsp;가. 수집 목적 범위내에서 제공하는 경우(법 제 17조)</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 정보주체의 동의를 받은 경우&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 법 제 15조 제1항 제2호,제3호 및 제5호에 따라 개인정보를 수집한 목적 범위내에서 제공하는 경우&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;나. 수집 목적 외의 용도로 제공하는 경우(법 제 18조)</span></p>
<p><span style="font-weight: 400;">개인정보처리자는 개인정보보호법 제 18조 제1항에 따라 개인정보보호법 제 17조 제1항 및 제 3항의 범위를 초과하여 제3자에게 제공해서는 아니되나 개인정보보호법 제 18조 제2항 각 호에 해당하는 다음의 경우에는 예외적으로 제공가능함(단, 정보주체 또는 제3자의 권익을 부당하게 침해할 우려가 있다고 인정되는 때에는 제공 불가)</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 정보주체로부터 별도의 동의를 받는 경우</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 다른 법률에 특별한 규정이 있는 경우(법률에서 개인정보의 제공을 구체적으로 요구하거나 허용하고 있는 경우)</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제 3자의 급박한 생명,신체, 재산의 이익을 위해 필요하다고 인정되는 경우&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 범죄의 수사와 공소의 제기 미 유지를 위하여 필요한 경우</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;다. 업무의 일부 또는 전부를 위탁하는 경우&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 개인정보 처리업무를 위탁하는 경우 위탁업무의 목적 등이 포함된 문서에 의하여야 하며 위탁사항에 구체적으로 표기된 범위내에서 제공</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">3.제공여부 업무처리 기준&nbsp;</span></p>
<p><span style="font-weight: 400;">가. 요청절차의 적정성 확인&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 개인정보 제공 요청은 요청기관의 장으로부터 문서로 접수</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 제공요청 문서접수 시 확인할 필수사항&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1) 요청하는 법률근거(정보주체의 동의를 받은 경우 동의 여부 확인)</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) 요청 목적</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3) 요청하는 개인정보 항목</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4) 이용 기간&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">나. 자료제공 항목 판단</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 본사가 수집한 정보여부 확인&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1) 본사가 직접 수집,생산한 정보에 한하여 제공&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) 본사가 다른 기관으로 입수,활용하는 정보는 제공불가</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 요청 목적에 부합하는 최소항목만 제공(개인정보 최소제공의 원칙)</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1) 자료제공 요청 항목이 요청 목적과 부합하지 않거나 과도하다고 판단되는 경우 해당 항목은 제외하고 필요한 최소 범위 내 제공&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) 자료요청 개별 근거법에서 제공 항목을 열거하고 있는 경우 해당 항목에 한해 제공&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">다. 자료제공 여부 및 범위 판단&nbsp;</span></p>
<p><span style="font-weight: 400;">개인정보의 제공 여부 판단 및 제공 범위는 요청 기관의 개별 법률과 개인정보보호 법령 및 지침,고시 해설서 등을 종합적으로 참조하여 사례별로 판단하고, 정보주체 또는 제3자의 이익을 침해하지 않는 범위내에서 최소한의 자료만 제공&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 정보주체의 동의를 받은 경우&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1) 요청기관이 정보주체의 동의를 받은 경우 요청기관으로부터 동의서를 전수 제출받아 확인 후 제공&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) 정보주체의 동의를 받아 제공하는 경우 개인정보보호법 제 18조 제3항에 따른 의무사항 반드시 준수&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 다른 법률에 특별한 규정이 있는 경우(법 제 18조 제2항 제2호)</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1) 요청기관의 개별 법률을 근거로 자료제공 요청하는 경우 자료 제공 근거법 유무 확인</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 요청기관의 개별법에 이용기관, 이용목적, 이용하는 개인정보의 항목 등을 구체적으로 명시한 특별한 규정이 있는 경우 제공&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;※ 개별법에 &ldquo;법령상 의무이행&rdquo;과 같이 포괄적으로 규정되어 있거나 시행령,시행규칙에만 규정이 있는 경우에는 제공 불가&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) 요청목적의 적정여부확인&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-개별 법령에서 정한 이용목적 및 요청항목과 요청목적이 부합하지 않는경우에는 제공 불가&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우(법 제18조 제2항 제7호)</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1) 위 항목의 &ldquo;다른법률에 특별한 규정이 있는 경우&rdquo;의 자료 제공 기준에 준하여 판단</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) 수사 등의 목적으로 개인정보 제공을 요청받은 경우에도 무조건 제공해야 하는 것은 아니므로 영장, 사건번호, 접수번호 등을 확인한 후 자료제공 여부를 판단</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;※ 범죄의 형태와 경중, 정보주체가 받을 불이익의 정도 등 제반사정을 종합적으로&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;고려하여 개인정보 이용이 없이는&nbsp; 수사목적을 달성할 수 없는 경우에 한하여 제한적으</span></p>
<p><span style="font-weight: 400;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 로 제공 가능하나 요청기관과 협의하여 자료 제공범위 등 결정.&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다만, 범죄수사 등을 위한 경우라 하더라도 정보주체 또는 제3자의 이익을 부당하게&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;침해할 우려가 있는 경우에는 개인정보를 목적 외 용도로 이용하거나 제3자에게 제공할&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;수 없음</span></p>
<p>&nbsp;</p>
<ol start="4">
<li><span style="font-weight: 400;"> 자료제공 업무처리 기준&nbsp;</span></li>
</ol>
<p><span style="font-weight: 400;">&nbsp;가. 업무처리절차&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 상기 기준에 의하여 자료제공여부를 판단후 처리하며, 개인정보보호책임자의 결재를 득한 후 결과에 따라 처리</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 개인정보 자료 제공은 부득이한 경우를 제외하고는 문서회신을 원칙으로 하며 다음 사항을 반드시 기록&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1) 이용목적, 이용방법, 이용기간, 이용형태의 제한사항</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) 개인정보의 안전성 확보를 위해 필요한 구체적인 조치사항&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3) 자료제공 내역을 &lsquo;개인정보의 목적 외 이용 및 제3자 제공대장&rsquo;에 반드시 기록,관리</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;나. 자료제공 방법</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 우편을 이용하는 경우 내용증명으로 발송&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- e-mail 에 의한 자료제공시 업무용 컴퓨터에서 사용하는 문서작성 도구의 자체 암호화 기능 등을 통하여 개인정보 파일을 암호화하여 제공&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 자료제공시 반드시 내부결제를 득한 후 제공&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;&nbsp;- 문서 출력물에 의해 직접 전달하는 경우 요청기관의 담당자 또는 직상급자에게 직접전달(수령자 인적사항 등을 반드시 기록하여 기록 관리)</span></p>
<p><br /><br /></p>

    </div>
</section>
</template>

<script>
export default {

}
</script>

<style>

</style>