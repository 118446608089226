    /**
 * dependencies: mx-docfields.js
 */
<template>
        <div>
            <h4>AWH 달성 목표 작성하기</h4>

            <div class="shadowbox">

                <table style="width: 99%;">
                    <tr>
                        <th :style="bgPhys">신체</th>
                        <th :style="bgMental">정신</th>
                        <th :style="bgSpirit">영혼</th>
                    </tr>
                    <tr>
                        <td :style="bgPhys">
                            건강한 식생활 <br />
                            <span v-if="goals.goal_body_eat != ''">
                                {{ goals.goal_body_eat }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td :style="bgMental">
                            기분 전환하는 방법 <br />
                            <span v-if="goals.goal_mind_relax != ''">
                                {{ goals.goal_mind_relax }}
                            </span>
                            <span v-else>-</span>                          
                        </td>
                        <td :style="bgSpirit">
                            나만의 신념&관습<br />
                            <span v-if="goals.goal_spirit_belief != ''">
                                {{ goals.goal_spirit_belief }}
                            </span>
                            <span v-else>-</span>                                                                     
                        </td>
                    </tr>
                    <tr>
                        <td :style="bgPhys">
                            신체 활동 <br />
                            <span v-if="goals.goal_body_phys != ''">
                                {{ goals.goal_body_phys }}
                            </span>
                            <span v-else>-</span>                              
                        </td>
                        <td :style="bgMental">
                            낙관주의 & 희망사항 <br />
                            <span v-if="goals.goal_mind_hope != ''">
                                {{ goals.goal_mind_hope }}
                            </span>
                            <span v-else>-</span>                                                          
                        </td>
                        <td :style="bgSpirit">
                            나를 지원해주는 사람들<br />
                            <span v-if="goals.goal_spirit_relationship != ''">
                                {{ goals.goal_spirit_relationship }}
                            </span>
                            <span v-else>-</span>                                                                    
                        </td>
                    </tr>
                    <tr>
                        <td :style="bgPhys">
                            편안한 잠자는 방법 <br />
                            <span v-if="goals.goal_body_sleep != ''">
                                {{ goals.goal_body_sleep }}
                            </span>
                            <span v-else>-</span>                              
                        </td>
                        <td :style="bgMental">
                            긍정적인 태도 <br />
                            <span v-if="goals.goal_mind_attitude != ''">
                                {{ goals.goal_mind_attitude }}
                            </span>
                            <span v-else>-</span>                                                     
                        </td>
                        <td :style="bgSpirit">
                            다른 사람들에게 베푸는 친절 <br />
                            <span v-if="goals.goal_spirit_service != ''">
                                {{ goals.goal_spirit_service }}
                            </span>
                            <span v-else>-</span>                                                                   
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>                              
                        </td>
                        <td :style="bgSpirit">
                            의미와 목적 <br />
                            <span v-if="goals.goal_spirit_purpose != ''">
                                {{ goals.goal_spirit_purpose }}
                            </span>
                            <span v-else>-</span>                                                                  
                        </td>
                    </tr>
                </table>
            </div>

            <div class="shadowbox" style="margin-top: 2vh;">
                <table cellspacing="2" cellpadding="4">
                    <tr>
                        <th style="text-align: center; width: 70%;">질문</th>
                        <th style="text-align: center; width: 30%;">답변 (네/아니요)</th>
                    </tr>
                    <tr v-for="(g, gindex) in goalsFields.impact"
                        :style="getRowStyle(gindex)" :key="gindex">
                        <td>
                            {{ g.english }}<br />
                            {{ g.question }}
                        </td>
                        <td align="center">
                            <span v-if="goals[g.field] == 1"
                                class="wlb_subcolor4">
                                <i class="fa fa-check-square"></i>
                            </span>
                            <span v-else
                                class="wlb_subcolor3">
                                <i class="fa fa-times"></i>
                            </span>
                        </td>
                    </tr>
                </table>

            </div>

            <div class="shadowbox" style="margin-top: 2vh;">
                <h4>액션 플랜</h4>
                <div class="wlb_em_main1">기간</div>
                <div>{{ goals.ap_weeks }}주</div>

                <div class="wlb_em_main1">무엇을 달성할 예정이세요?</div>
                <div>{{ goals.ap_what }}</div>

                <div class="wlb_em_main1">얼마만큼 달성할 예정인가요?</div>
                <div>{{ goals.ap_howmuch }}</div>

                <div class="wlb_em_main1">얼마나 자주 달성할 예정인가요?</div>
                <div>{{ goals.ap_howoften }}</div>

                <div class="wlb_em_main1">언제 달성할 예정인가요?</div>
                <div>{{ goals.ap_when }}</div>

                <div class="wlb_em_main1">자신감 척도</div>
                <div>{{ goals.ap_confidence }}점</div>

            </div>

            <div class="shadowbox" style="margin-top: 2vh;">
                <table cellspacing="2" cellpadding="4" style="width: 99%;">
                    <tr>
                        <th style="text-align: center; width: 15%;">기간</th>
                        <th style="width: 55%;">목표를 달성하는데 필요한 단계</th>
                        <th style="text-align: center; width: 15%;">달성함<br /> 예/아니오</th>
                        <th style="text-align: center; width: 15%;">자신감<br /> 척도</th>
                    </tr>
                    <tbody v-for="windex in 8" :key="windex">
                        <tr :style="getRowStyle(windex)">
                            <td align="center">
                                <span style="font-weight: bold;">{{ windex }} 주차</span>
                            </td>
                            <td>
                                {{ goals['wk'+windex+'_goal'] }}
                                <div v-if="goals['wk'+windex+'_revision'] != ''">
                                    (수정) {{ goals['wk'+windex+'_revision'] }}
                                </div>
                            </td>
                            <td align="center">
                                <span v-if="goals['wk'+windex+'_achieved'] == 1"
                                    class="wlb_subcolor4">
                                    <i class="fa fa-check-square"></i>
                                </span>
                                <span v-else
                                    class="wlb_subcolor3">
                                    <i class="fa fa-times"></i>
                                </span>
                            </td>
                            <td align="center">
                                {{ goals['wk'+windex+'_confidence'] }}점
                            </td>
                        </tr>
                    </tbody>
                </table>
            
            </div>            
        </div>
</template>

<script>
import mxDocfields from '@/components/mx-docfields.js'
export default {
    name: 'DisplayGoals',
    mixins: [mxDocfields],
    props: {
        goals: {
            type: Object,
            required: true,
        },
    },
    data: function() {
        return {
            colorCell: ' border-bottom: 1px solid lightgrey; border-right: 1px solid lightgrey; ',
        };
    },
    computed: {
        bgPhys() { return this.colorCell + ' background-color: LavenderBlush; ' },
        bgMental() { return this.colorCell + ' background-color: HoneyDew; ' },
        bgSpirit() { return this.colorCell + ' background-color: Azure; ' },
    },
    methods: {
        getRowStyle(idx) {
            if (idx % 2 == 0)
                return 'background-color: ghostwhite';
            else return '';
        },         
    },
}
</script>

<style>

</style>