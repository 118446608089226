<template>
    <section id="main">
        <div class="container main_container noHeaderPicSpace">
            <section id="content">
                <div id="vueContainer" v-cloak>
                    <header>
                        <h2>Search</h2>
                    </header>
                    <div style="color: blue; font-size: 0.8em;">
                        Press [Tab] to switch between user and class search.
                    </div>
                    <div class="shadowbox">
                        <div>
                            <input
                                id="userSearch"
                                type="text"
                                v-model="s.u.search"
                                @focus="inputFocus('user')"
                                placeholder="Search User: (part of) Name or Email or Phone number"
                                autofocus
                            />
                            <button
                                @click="runUserSearch"
                                type="primary"
                                class="button"
                            >
                                Search User
                            </button>
                        </div>
                        <div style="margin-top: 2vh;">
                            <input
                                id="classSearch"
                                type="text"
                                v-model="s.c.search"
                                @focus="inputFocus('class')"
                                placeholder="Search Class Name"
                            />
                            <button
                                @click="runClassSearch"
                                type="primary"
                                class="button"
                            >
                                Search Class
                            </button>
                        </div>
                    </div>

                    <div class="shadowbox" style="margin-top: 4vh;">
                        <div v-if="s.mode == 'user'">
                            <div v-if="s.u.found.length > 0">
                                <h3>
                                    User Search: {{ s.u.found.length }} Results
                                </h3>
                                <div class="table-div" style="width: 100%;">
                                    <div class="th">
                                        <div>ID</div>
                                        <div>Name</div>
                                        <div>email</div>
                                        <div>mobile</div>
                                        <div>joined</div>
                                    </div>
                                    <div
                                        v-for="(st, sindex) in s.u.found"
                                        style="margin-bottom: 1vh;"
                                        :key="sindex"
                                    >
                                        <div>
                                            <span
                                                v-if="
                                                    st.level ==
                                                        $store.state.lvl.client
                                                "
                                                >Client</span
                                            >
                                            <span v-else>Teacher</span>
                                        </div>
                                        <div
                                            v-if="
                                                st.level ==
                                                    $store.state.lvl.client
                                            "
                                        >
                                            <router-link
                                                :to="
                                                    profilePath(st.level) +
                                                        st.id
                                                "
                                            >
                                                {{ st.lastname }},{{
                                                    st.firstname
                                                }}
                                                <span
                                                    v-if="st.nickname != ''"
                                                    >{{ st.nickname }}</span
                                                >
                                            </router-link>
                                        </div>
                                        <div v-else>
                                            <router-link
                                                :to="
                                                    profilePath(st.level) +
                                                        st.id
                                                "
                                            >
                                                {{ st.lastname }},{{
                                                    st.firstname
                                                }}
                                                <span
                                                    v-if="st.nickname != ''"
                                                    >{{ st.nickname }}</span
                                                >
                                            </router-link>
                                        </div>
                                        <div>{{ st.email }}</div>
                                        <div>{{ st.mobile }}</div>
                                        <div>{{ showDate(st.joined) }}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>No Results</div>
                        </div>

                        <div v-else-if="s.mode == 'class'">
                            <div v-if="s.c.found.length > 0">
                                <h3>
                                    Class Search: {{ s.c.found.length }} Results
                                </h3>
                                <div class="table-div" style="width: 100%;">
                                    <div class="th">
                                        <div></div>
                                        <div>title</div>
                                        <div>created</div>
                                        <div class="center">students</div>
                                        <div class="center">sessions</div>
                                        <!-- <div>period</div> -->
                                        <div></div>
                                    </div>
                                    <div
                                        v-for="(c, cindex) in s.c.found"
                                        style="margin-bottom: 1vh;"
                                        :key="cindex"
                                    >
                                        <div>
                                            <Classcode
                                                :color="c.color"
                                                :code="c.code"
                                            ></Classcode>
                                        </div>
                                        <div>
                                            <router-link
                                                :to="
                                                    pathAuthPrefix +
                                                        s.c.linkPath +
                                                        c.id
                                                "
                                                >{{ c.title }}</router-link
                                            >
                                        </div>
                                        <div>{{ showDate(c.created) }}</div>
                                        <div class="center">
                                            {{ c.student_count }}
                                        </div>
                                        <div class="center">
                                            {{ c.sessions.length }}
                                        </div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>No Results</div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import Classcode from "@/components/Classcode";
import moment from "moment";
export default {
    name: "Search",
    components: {
        Classcode,
    },
    data() {
        return {
            s: {
                mode: "",
                u: {
                    // users
                    searchPath: "/user/search/simple/",
                    search: "",
                    found: [],
                },
                c: {
                    // classes
                    searchPath: "/course/search/simple/",
                    linkPath: "/course/",
                    search: "",
                    found: [],
                },
            },
        };
    },
    beforeMount: function() {
        document.addEventListener("keydown", this.submitListener);
        document.addEventListener("keydown", this.switchListener);
    },
    beforeDetroy: function() {
        document.removeEventListener("keydown", this.submitListener);
        document.removeEventListener("keydown", this.switchListener);
    },
    computed: {
        pathAuthPrefix() {
            return this.$store.getters.GET_PATH;
        },
    },
    methods: {
        submitListener: function(event) {
            if (event.key == "Enter") {
                if (this.s.mode == "user") this.runUserSearch();
                else if (this.s.mode == "class") this.runClassSearch();
            }
        },
        switchListener: function(event) {
            // console.log(event.key);
            if (event.key == "Tab") {
                event.preventDefault();
                if (this.s.mode != "user") {
                    document.getElementById("userSearch").focus();
                } else {
                    document.getElementById("classSearch").focus();
                }
            }
        },
        runUserSearch: function() {
            if (this.s.u.search == "") return false;

            axios
                .get(this.s.u.searchPath, {
                    params: { search: this.s.u.search },
                })
                .then((resData) => {
                    this.s.u.found = resData.data.students
                        .map(function(el) {
                            let out = el;
                            el.level = 1;
                            return out;
                        })
                        .concat(
                            resData.data.teachers.map(function(el) {
                                let out = el;
                                el.level = 2;
                                return out;
                            })
                        );
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        runClassSearch: function() {
            if (this.s.c.search == "") return false;

            axios
                .get(this.s.c.searchPath, {
                    params: { search: this.s.c.search },
                })
                .then((result) => {
                    this.s.c.found = result.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        inputFocus: function(searchMode) {
            this.s.mode = searchMode;
        },
        showDate(string) {
            if (string == null) return "";
            else return moment(string).format("YYYY-MM-DD HH:mm");
        },
        profilePath(lvl) {
            return `${
                this.pathAuthPrefix
            }/${this.$store.getters.GET_AUTH_CATEGORY(lvl)}/profile/`;
        },
    },
};
</script>

<style></style>
