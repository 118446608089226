<template>
    <div>
        <div class="bg_light" style="padding-bottom: 10vh;">
            <div>
                <img
                    style="width: 100%;"
                    src="@/assets/_img/healthinfo_1.jpg"
                />
            </div>
            <div
                style="padding-top: 8vh; display: flex; justify-content: center; flex-direction: row; width: 85%; margin-left: 10%;"
            >
                <span style="">
                    <img
                        src="@/assets/_img/healthinfo_2.png"
                        width="580"
                        height="580"
                    />
                </span>
                <span style="width: 50%; display: inline-block;">
                    <div>
                        <img src="@/assets/_img/heartbeat.png" height="80" />
                        <div class="spacer_md"></div>
                        <div class="gradient_text_xl weight700 font80 qsand">
                            Mental Health
                        </div>
                    </div>
                    <div class="spacer_lg"></div>
                    <span class="tag_green">정신건강</span>
                    <span class="spacer_h_sm_l"></span>
                    <span class="tag_green">精神健康</span>
                    <div class="spacer_lg"></div>
                    <div>
                        인간의 심리적 기능이 어느 정도 정상적인가를 나타내는
                        개념입니다.<br />
                        일반적으로 정신질환이 없고, 정상 범주에 있으며, 잘
                        적응하고 있고,<br />
                        불만이 적고 행복감을 느끼는 사람 등의 조건을 갖춘 경우로
                        볼 수 있습니다.<br />
                        인간 정신기능의 생리적·심리적·사회적 측면이 상호 심각한
                        갈등 없이<br />
                        고유의 기능을 발휘하고 있으며 사회집단과 현실적 적응을
                        하고 있음을 가리킵니다.<br />
                    </div>
                    <div class="spacer_lg"></div>
                    <div>이것은 흔히 네 가지 측면에서 고찰할 수 있습니다.</div>
                    <div class="spacer_lg"></div>
                    <div>
                        <b>정신병리가 없는 상태</b>
                        <div class="spacer_md"></div>
                        <div class="font16">
                            질병의 대립개념인 정신건강으로서 이는 정신병리가
                            없는 상태를 가리킨다.
                        </div>
                    </div>
                    <div class="spacer_lg"></div>
                    <div>
                        <b>정신기능의 이상적인 조화</b>
                        <div class="spacer_md"></div>
                        <div class="font16">
                            이상적 상태인 정신건강으로서 최적의 정신적 기능을
                            발휘할 수 있도록 정신기능의 모든 하위영역이 조화되고
                            이상적 상태를 이루고 있는 것을 가리킨다
                        </div>
                    </div>
                    <div class="spacer_lg"></div>
                    <div>
                        <b>통계학적 정상분포</b>
                        <div class="spacer_md"></div>
                        <div class="font16">
                            통계적 개념인 정신건강으로서 수학의 통계적 개념을
                            적용하여 인간행동 내지 정신기능의 정상분포 곡선에서
                            중간부분을 건강한 것으로 보고 양극단을 비정상 내지
                            불건강한 것으로 보는 것이다
                        </div>
                    </div>
                    <div class="spacer_lg"></div>
                    <div>
                        <b>사회집단과 생리적·심리적·사회적 적응</b>
                        <div class="spacer_md"></div>
                        <div class="font16">
                            상호작용 체제인 정신건강으로서 이는 인간 정신기능의
                            생리적 측면·심리적 측면·사회적 측면이 상호 심각한
                            갈등이 없이 고유의 기능을 발휘하고 있으며 사회집단과
                            현실적 적응을 하고 있음을 가리키는 것이다.
                        </div>
                    </div>
                    <div class="spacer_lg"></div>
                    <div class="weight300">
                        과거에는 정신건강의 문제는 개인의 심리적 문제로만
                        생각하려는 경향이 있었으나 정신적 질환 내지 이상심리가
                        다른 사람, 사회나 경제 등에 미치는 영향에 대해서 점차
                        관심이 미치게 되었고 최근에는 개인의 정신건강 문제를
                        가족체제, 사회심리적 환경, 또는 사회문화적 체제와
                        밀접하게 관련된 것으로 간주하고 가정·학교·직장·지역사회
                        등의 전 사회적 망을 통해서 정신건강을 증진하고 각종
                        이상심리를 예방하고 치료하려는 경향이 점차로 강화되고
                        있습니다.
                    </div>
                </span>
            </div>
        </div>
        <div class="bg_white" style="padding-top: 6vh; padding-bottom: 8vh;">
            <div
                style="padding-top: 8vh; display: flex; justify-content: center; flex-direction: row; width: 85%; margin-left: 10%;"
            >
                <span style="width: 40%;">
                    <div class="weight500 font48 gray900">
                        다양한 정신건강 이슈들에 대해 알아볼까요?
                    </div>
                    <img :src="tabPic" style="width: 500px; height: 500px;" />
                </span>
                <span
                    style="width: 50%; display: inline-block; margin-left: 3vw;"
                >
                    <div
                        class="main_dark weight500 font24 bg_lightblue"
                        style="padding: 2vh 3vw; border-radius: 40px;
                    flex-direction: column;"
                    >
                        <div
                            style="display: flex; justify-content: space-between;"
                        >
                            <span>
                                우울증 알아보기
                            </span>
                            <span>
                                <i
                                    v-if="openTab == 'depression'"
                                    @click="openTab = ''"
                                    class="fas fa-angle-up pad_sm main_dark"
                                ></i>
                                <i
                                    v-else
                                    @click="openTab = 'depression'"
                                    class="fas fa-angle-down pad_sm main_dark"
                                ></i>
                            </span>
                        </div>

                        <div
                            v-show="openTab == 'depression'"
                            class="bg_white font20 gray700 weight300"
                            style="border-radius: 20px; padding: 3vh; 2vw; margin-top: 2vh;"
                        >
                            사람은 누구에게나 기쁨 및 슬픔과 같은 감정이 있으며,
                            살아가다 보면 기분이 좋을 때도 있고 우울할 때도
                            있습니다. 사랑하던 주변 사람이 자신 곁을 떠났을 때
                            우리는 한동안 견딜 수 없을 것 같은 슬픔이나 우울한
                            기분을 느끼게 됩니다.<br /><br />
                            그러나 이러한 느낌은 지극히 정상적인 반응이라 할 수
                            있는데, 하지만 이런 증상이 2주 이상 지속되며, 기분
                            변화의 폭이 너무 넓으면 자신의 행동이나 일에까지
                            심각한 영향을 미치거나 극복하지 못하게 되는데 이러한
                            것을 병이라 볼 수 있습니다.<br /><br />
                            우울증은 ‘정신과의 감기’라고 할 정도로 흔한
                            질병입니다. 여자는 평생 동안 10-25%가, 남자는 평생
                            동안 5-12%가 적어도 한번은 우울증에 걸립니다. 그러나
                            우울증을 병으로 인식하고 치료를 받는 사람은 약
                            10%정도 밖에 안됩니다.
                            <div class="spacer_lg"></div>
                            <hr />
                            <div class="spacer_lg"></div>
                            <div class="gray900 weight500 font20">
                                우울증의 증상으로는 어떤 점이 있나요?
                            </div>
                            <div class="spacer_lg"></div>
                            <div style="display: flex; flex-direction: row; ">
                                <img
                                    src="@/assets/_img/brain.png"
                                    style="width: 96px; height: 96px"
                                />
                                <div style="flex: 1;">
                                    <p class="main_dark font18 weight500">
                                        우울한 기분
                                    </p>
                                    <div class="spacer_sm"></div>
                                    <div
                                        style="padding: 1vh 1vw; background-color: #f7f7f7; border-radius: 8px; width:100%;"
                                    >
                                        <ul>
                                            <li>
                                                무기력하고 우울한 기분이 지속됨
                                            </li>
                                            <li>
                                                식욕이 없어지거나 과도하게
                                                증가함
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer_md"></div>
                            <div style="display: flex; flex-direction: row; ">
                                <img
                                    src="@/assets/_img/braincell.png"
                                    style="width: 96px; height: 96px;"
                                />
                                <div style="flex: 1;">
                                    <p class="main_dark font18 weight500">
                                        사고장애
                                    </p>
                                    <div class="spacer_sm"></div>
                                    <div class="listBox">
                                        <ul>
                                            <li>
                                                비관적인 생각이 들고, 자살사고나
                                                충동이 지속됨
                                            </li>
                                            <li>
                                                상실감, 죄책감을 느낌
                                            </li>
                                            <li>
                                                건강염려증, 피해망상이 생김
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer_md"></div>
                            <div style="display: flex; flex-direction: row; ">
                                <img
                                    src="@/assets/_img/brain_battery.png"
                                    style="width: 96px; height: 96px;"
                                />
                                <div style="flex: 1;">
                                    <p class="main_dark font18 weight500">
                                        의욕상실 및 행동의 어려움
                                    </p>
                                    <div class="spacer_sm"></div>
                                    <div class="listBox">
                                        <ul>
                                            <li>
                                                매사에 흥미나 재미가 없음
                                            </li>
                                            <li>
                                                대인관계가 적어지고 집에서 혼자
                                                지내는 시간이 늘어남
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer_md"></div>
                            <div style="display: flex; flex-direction: row; ">
                                <img
                                    src="@/assets/_img/first_aid.png"
                                    style="width: 96px; height: 96px;"
                                />
                                <div style="flex: 1;">
                                    <p class="main_dark font18 weight500">
                                        신체적 증상
                                    </p>
                                    <div class="spacer_sm"></div>
                                    <div class="listBox">
                                        <ul>
                                            <li>
                                                이유없이 몸이 아픔
                                            </li>
                                            <li>
                                                불면증이나 과다한 수면상태가
                                                지속됨
                                            </li>
                                            <li>
                                                식욕저하나 체중감소 또는
                                                폭식으로 인한 체중 증가
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer_md"></div>
                            <div style="display: flex; flex-direction: row; ">
                                <img
                                    src="@/assets/_img/labyrinth.png"
                                    style="width: 96px; height: 96px;"
                                />
                                <div style="flex:1;">
                                    <p class="main_dark font18 weight500">
                                        인지/정서적 증상
                                    </p>
                                    <div class="spacer_sm"></div>
                                    <div class="listBox">
                                        <ul>
                                            <li>
                                                집중력이 떨어짐
                                            </li>
                                            <li>
                                                건망증
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer_md"></div>
                            <div
                                @click="openTab = 'depression'"
                                class="main font16"
                                style="padding: 2vh; display: block; border: 2px solid #2DB34E; text-align: center; border-radius: 4px;"
                            >
                                우울증에 대해 자세히 알아보기
                                <i class="fa fa-arrow-right" />
                            </div>
                        </div>
                    </div>
                    <div class="spacer_lg"></div>
                    <div
                        class="main_dark weight500 font24 bg_lightblue"
                        style="padding: 2vh 3vw; border-radius: 40px;"
                    >
                        <div
                            style="display: flex; justify-content: space-between;"
                        >
                            <span>
                                스트레스 알아보기
                            </span>
                            <span>
                                <i
                                    v-if="openTab == 'stress'"
                                    @click="openTab = ''"
                                    class="fas fa-angle-up"
                                ></i>
                                <i
                                    v-else
                                    @click="openTab = 'stress'"
                                    class="fas fa-angle-down"
                                ></i>
                            </span>
                        </div>
                        <div
                            v-show="openTab == 'stress'"
                            class="bg_white font20 gray700 weight300"
                            style="border-radius: 20px; padding: 3vh; 2vw; margin-top: 2vh;"
                        >
                            외부환경으로부터 오는 새로운 요구나 변화에 대응해야
                            할 때 우리에게 발생하는 긴장이나 불안 같은 것을
                            의미합니다. 아주 일상적인 일에서부터 커다란 사건까지
                            우리가 스트레스를 받는 일은 매우 다양합니다.<br /><br />
                            꼭 부정적인 일이 생겼을 때만 스트레스를 받는 것은
                            아닙니다. 새로 이성친구를 사귀게 되거나 승진을 하는
                            경우와 같이 긍정적인 일도 스트레스가 될 수 있습니다.
                            이것은 아무도 스트레스 없이 살 수 없다는 것을
                            의미합니다.<br /><br />
                            적당한 스트레스는 우리에게 적절한 긴장감을 주어
                            문제해결이나 업무수행능력을 증진시켜주지만,
                            스트레스가 너무 강하거나 만성화되면 오히려
                            수행능력을 저하시키고 정신건강뿐만 아니라
                            신체적으로도 유해한 결과를 초래하게 됩니다. 때문에
                            스트레스의 부정적인 영향이 장기화되지 않도록
                            스트레스를 적절히 해결하는 것이 매우 중요합니다.
                            <div class="spacer_lg"></div>
                            <hr />
                            <div class="spacer_lg"></div>
                            <div class="gray900 weight500 font20">
                                스트레스의 증상으로는 어떤 점이 있나요?
                            </div>
                            <div class="spacer_lg"></div>
                            <div style="display: flex; flex-direction: row; ">
                                <img
                                    src="@/assets/_img/first_aid.png"
                                    style="width: 96px; height: 96px"
                                />
                                <div style="flex: 1;">
                                    <p class="main_dark font18 weight500">
                                        신체적 증상
                                    </p>
                                    <div class="spacer_sm"></div>
                                    <div class="listBox">
                                        <ul>
                                            <li>
                                                두통, 만성피로, 불면증
                                            </li>
                                            <li>
                                                근육통이나 경직 (특히
                                                목·어깨·허리)
                                            </li>
                                            <li>
                                                가슴 두근거림, 혈압상승,
                                                과호흡증상
                                            </li>
                                            <li>
                                                식욕부진, 소화장애,
                                                위장장애(위염,위궤양)
                                            </li>
                                            <li>
                                                울렁거림, 어지럼증, 땀, 입마름
                                            </li>
                                            <li>
                                                사지냉증, 설사, 변비, 감각이상
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer_md"></div>
                            <div style="display: flex; flex-direction: row; ">
                                <img
                                    src="@/assets/_img/labyrinth.png"
                                    style="width: 96px; height: 96px;"
                                />
                                <div style="flex: 1;">
                                    <p class="main_dark font18 weight500">
                                        인지/정서적 증상
                                    </p>
                                    <div class="spacer_sm"></div>
                                    <div class="listBox">
                                        <ul>
                                            <li>
                                                집중력 혹은 기억력의 감소
                                            </li>
                                            <li>
                                                주의산만
                                            </li>
                                            <li>
                                                우유부단, 혼란스러움
                                            </li>
                                            <li>
                                                불안, 불쾌, 짜증, 분노, 신경과민
                                            </li>
                                            <li>
                                                우울감, 좌절감, 근심, 걱정,
                                                조급함
                                            </li>
                                            <li>인내심 부족</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer_md"></div>
                            <div style="display: flex; flex-direction: row; ">
                                <img
                                    src="@/assets/_img/hands.png"
                                    style="width: 96px; height: 96px;"
                                />
                                <div style="flex: 1;">
                                    <p class="main_dark font18 weight500">
                                        행동적 증상
                                    </p>
                                    <div class="spacer_sm"></div>
                                    <div class="listBox">
                                        <ul>
                                            <li>
                                                안절부절 못함, 다리 떨기
                                            </li>
                                            <li>
                                                우는 행동
                                            </li>
                                            <li>
                                                과격한 행동, 충동적인 행동
                                            </li>
                                            <li>
                                                손톱 깨물기
                                            </li>
                                            <li>
                                                폭식, 폭음, 욕설
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer_md"></div>

                            <div
                                @click="openTab = 'depression'"
                                class="main font16"
                                style="padding: 2vh; display: block; border: 2px solid #2DB34E; text-align: center; border-radius: 4px;"
                            >
                                스트레스에 대해 자세히 알아보기
                                <i class="fa fa-arrow-right" />
                            </div>
                        </div>
                    </div>
                    <div class="spacer_lg"></div>
                    <div
                        class="main_dark weight500 font24"
                        style="padding: 2vh 3vw; border-radius: 40px;
                    background-color: #E5F9EA; "
                    >
                        <div
                            style="display: flex; justify-content: space-between;"
                        >
                            <span>
                                조울증 알아보기
                            </span>
                            <span>
                                <i
                                    v-if="openTab == 'bipolar'"
                                    @click="openTab = ''"
                                    class="fas fa-angle-up"
                                ></i>
                                <i
                                    v-else
                                    @click="openTab = 'bipolar'"
                                    class="fas fa-angle-down"
                                ></i>
                            </span>
                        </div>
                        <div
                            v-show="openTab == 'bipolar'"
                            class="bg_white font20 gray700 weight300"
                            style="border-radius: 20px; padding: 3vh; 2vw; margin-top: 2vh;"
                        >
                            양극성장애라고도 불리는 조울증은 어떤 때는
                            조증(상승한 상태)이 나타나고 또 어떤 때는
                            우울증(가라앉은 상태) 기분이 나타나며 양 극단
                            사이에서 일정기간을 두고 기분상태가 바뀌는 것이
                            반복적으로 나타나는 것을 말합니다.
                            <div class="spacer_lg"></div>
                            <hr />
                            <div class="spacer_lg"></div>
                            <div class="gray900 weight500 font20">
                                조울증의 증상으로는 어떤 점이 있나요?
                            </div>
                            <div class="spacer_lg"></div>
                            <div style="display: flex; flex-direction: row; ">
                                <img
                                    src="@/assets/_img/happy.png"
                                    style="width: 96px; height: 96px"
                                />
                                <div style="flex: 1;">
                                    <p class="main_dark font18 weight500">
                                        조증 증상
                                    </p>
                                    <div class="spacer_sm"></div>
                                    <div class="listBox">
                                        <ul>
                                            <li>
                                                쓸데없는 과대망상 및 자신에 대한
                                                지나친 과대평가
                                            </li>
                                            <li>
                                                심하게 과장된 자신감 또는 자기
                                                존중감
                                            </li>
                                            <li>수면에 대한 욕구 감소</li>
                                            <li>
                                                평소보다 말이 많아지며 목소리가
                                                커지고 말이 빨라짐
                                            </li>
                                            <li>
                                                사고의 비약 또는 사고가 연달아
                                                일어나는 주관적인 경험
                                            </li>
                                            <li>주의산만</li>
                                            <li>
                                                고통스러운 결과를 초래할
                                                쾌락적인 활동에 지나치게 몰두
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer_md"></div>
                            <div style="display: flex; flex-direction: row; ">
                                <img
                                    src="@/assets/_img/sad.png"
                                    style="width: 96px; height: 96px;"
                                />
                                <div style="flex: 1;">
                                    <p class="main_dark font18 weight500">
                                        우울증 증상
                                    </p>
                                    <div class="spacer_sm"></div>
                                    <div class="listBox">
                                        <ul>
                                            <li>
                                                하루의 대부분, 거의 매일
                                                지속되는 우울한 기분
                                            </li>
                                            <li>
                                                일상 활동에 대한 흥미나 즐거운이
                                                뚜렷하게 저하
                                            </li>
                                            <li>
                                                체중감소나 체중 증가
                                            </li>
                                            <li>
                                                빈번한 불면이나 과다수면
                                            </li>
                                            <li>지속되는 피로감</li>
                                            <li>
                                                과도한 죄책감, 상실감을 느낌
                                            </li>
                                            <li>
                                                고통스러운 결과를 초래할
                                                쾌락적인 활동에 지나치게 몰두
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer_md"></div>
                            <div
                                class="main font16"
                                style="padding: 2vh; display: block; border: 2px solid #2DB34E; text-align: center; border-radius: 4px;"
                            >
                                조울증에 대해 자세히 알아보기
                                <i class="fa fa-arrow-right" />
                            </div>
                        </div>
                    </div>
                    <div class="spacer_lg"></div>
                    <div
                        class="main_dark weight500 font24"
                        style="padding: 2vh 3vw; border-radius: 40px;
                    background-color: #E5F9EA; "
                    >
                        <div
                            style="display: flex; justify-content: space-between;"
                        >
                            <span>
                                불안장애 알아보기
                            </span>
                            <span>
                                <i
                                    v-if="openTab == 'anxiety'"
                                    @click="openTab = ''"
                                    class="fas fa-angle-up"
                                ></i>
                                <i
                                    v-else
                                    @click="openTab = 'anxiety'"
                                    class="fas fa-angle-down"
                                ></i>
                            </span>
                        </div>

                        <div
                            v-show="openTab == 'anxiety'"
                            class="bg_white font20 gray700 weight300"
                            style="border-radius: 20px; padding: 3vh; 2vw; margin-top: 2vh;"
                        >
                            사람은 누구나 위협적인 상황에 처하거나 위험이 예상될
                            때 경고반응을 일으켜서 몸과 마음의 준비를 하도록
                            만듭니다. 예를 들어 갑자기 차가 튀어 나올 때, 중요한
                            시험을 치르기 전에 수술날짜가 다가올 때 불안한
                            마음이 드는 것은 당연합니다.<br /><br />
                            그러나 위험한 상황이 아님에도 공포심이 들 정도로
                            경고반응이 울리는 경우 불안장애라고 합니다.<br /><br />
                            <div class="spacer_lg"></div>
                            <hr />
                            <div class="spacer_lg"></div>
                            <div class="gray900 weight500 font20">
                                공황장애란?
                            </div>
                            <div class="spacer_lg"></div>
                            <div style="display: flex; flex-direction: row; ">
                                <img
                                    src="@/assets/_img/first_aid.png"
                                    style="width: 96px; height: 96px"
                                />
                                <div style="flex:1;">
                                    <p class="main_dark font18 weight500">
                                        신체적 증상
                                    </p>
                                    <div class="spacer_sm"></div>
                                    <div class="listBox">
                                        <ul>
                                            <li>
                                                심장이 두근거리거나 빨리짐
                                            </li>
                                            <li>
                                                땀이 많이 남
                                            </li>
                                            <li>사지 떨림</li>
                                            <li>숨이막히거나 답답한 느낌</li>
                                            <li>질식할 것 같은 느낌</li>
                                            <li>메스꺼움</li>
                                            <li>
                                                어지업거나 쓰러질 것 같은 느낌
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer_md"></div>
                            <div style="display: flex; flex-direction: row; ">
                                <img
                                    src="@/assets/_img/labyrinth.png"
                                    style="width: 96px; height: 96px;"
                                />
                                <div style="flex:1;">
                                    <p class="main_dark font18 weight500">
                                        인지/정서적 증상
                                    </p>
                                    <div class="spacer_sm"></div>
                                    <div class="listBox">
                                        <ul>
                                            <li>
                                                비현실감(비현실적인 느낌) 또는
                                                이인증(자신이 달라진 느낌)
                                            </li>
                                            <li>
                                                미쳐 버리거나 자제력을 잃어버릴
                                                것 같은 두려움
                                            </li>
                                            <li>
                                                죽을 것 같은 두려움
                                            </li>
                                            <li>지각이상(둔하거나 따끔거림)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="spacer_md"></div>
                            <div
                                class="main font16"
                                style="padding: 2vh; display: block; border: 2px solid #2DB34E; text-align: center; border-radius: 4px;"
                            >
                                공황장애에 대해 자세히 알아보기
                                <i class="fa fa-arrow-right" />
                            </div>
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>
    <!-- <section class="blog">
            <h3 class="blog_left_align">코로나 블루 제대로 알아보기</h3>
            <hr class="spacer" />

            <img src="@/assets/_img/health_info_1.png">
            <p class="blog_text">
                서울 시민과 함께 해온 covid19 심리지원단의 마음 처방전이 다시 시작되었습니다. <br/>
                매주 새로운 처방전과 소식들로 하반기 코로나 스트레스와 코로나 블루를 함께 극복해나가는,
                서울시민의 마음친구가 되겠습니다. <br/>
                서울시민의 코로나 블루 극복을 위한 가을 마음처방전 첫 번째 <br/>
            </p>

            <div style="margin-top: 2vh;"></div>
            <img src="@/assets/_img/health_info_2.png">
                
            <p class="blog_text">
                <b>코로나 19로 인한 장기적인 긴장과 스트레스는 우리의 마음과 몸에 어떤 변화를 일으킬까요?</b><br/>
                젤리를 오랜 시간 누르고, 비비고 던지면, 처음에는 괜찮지만 이를 여러 번 반복하면, 
                결국 젤리는 터져버리게 됩니다. 코로나 19로 인해 <br/>
                참고, 애쓰고, 견디면서 지내온 여러분들의 마음과 몸 상태 중 터져버리기 일보 직전인 부분은 없나요?
            </p>

        <div style="margin-top: 2vh;"></div>
            <img src="@/assets/_img/health_info_3.png">
            <p class="blog_text">
                코로나 19로 인하여 평상시 보다 높은 스트레스 상태에서 안정을 유지하려고 애를 쓰려면, 스트레스를 스스로 낮추기 위한 활동을 하거나
                훨씬 더 많은 에너지를 만들어서 스트레스를 받지 않게 몸을 새로운 시스템으로 작동시켜야 합니다.<br/>
                하지만 오랜 시간이 지나면서,이 시스템이 계속 유지되느냐 마느냐 하는 것은 사람들의 스트레스 조절 능력에 따라 달라질 수 있습니다.<br/>
                코로나 바이러스로 인해 생겨난 여러 심리적, 신체적 스트레스를 조절하는데 (누적되지 않게 잘 방출하거나 승화시키면서) 실패하거나, 이 
                시기에 더 많은 에너지를 요구하는 스트레스를 경험할 때 몸과 마음이 제대로 대처하지 못하는 상태, 즉 원래 지니고 있는 질병의 악화 혹은
                새로운 질병이 발병될 수 있습니다.<br/>
                세계적인 의학자들이 많은 환자들의 증상 악화나 사망 혹은 새로운 환자의 발생에 대해 만성적 스트레스 누적으로 인한 “신체 마모현상
                (Allostatic load)”를 본격적으로 이야기하기 시작했습니다.<br/>
                심장병의 악화, 뇌출혈 그리고 비만과 이로 인한 합병증의 증가를 예시로 제시하고 있습니다. 특히 미국의 인종 간 차이에 따른 사망률에<br/>
                대해서 근거이론으로 인용되기도 합니다.<br/>
                (네이버 영어 사전에서 allostatic load를 찾아보니, allostatic load refer heavy, chronic stress causing wear and tear on the<br/>
                body over time 으로 나와 있네요).
            </p>
        
            <img src="@/assets/_img/health_info_4.png">
            <p class="blog_text">
                만성 누적 스트레스 과부하 (신체 마모, Allostatic Load) 상태가 되지 않기 위해 해야 할 에너지 효율, 가성비 놓은 자기 돌봄 4가지<br/>
                방법을 소개합니다.
            </p>

            <img src="@/assets/_img/health_info_5.png">
            <p class="blog_text">
                <b>첫째, 내 몸과 마음의 상태를 알아차려야 합니다.</b><br/>
                코로나19 시작과 함께 스트레스를 받지 않은 사람은 없습니다. 하지만 스트레스를 받기 전 건강 상태는 모든 사람이 달랐습니다.<br/>
                여러분 자신의 몸과 마음의 상태를 알아차리는 것, 특히 스트레스가 내 몸과 마음에 어떤 변화를 일으키고 있는지를 알아차리는 것이<br/>
                기본적으로 가장 중요합니다.<br/>
                혈압, 혈당, 맥박, 수면과 같은 기본적 생리 상태부터 출발하여 자신의 걱정, 근심, 분노, 불안, 괴로운 감정 등이 얼마나 자신을 차지하고<br/>
                있는지를 아는 것이 필요합니다.<br/>
                여러분의 상태를 알고 조절할 수 있도록 여러분 자신을 살펴보며 지내세요!
            </p>

            <img src="@/assets/_img/health_info_6.png">
            <p class="blog_text">
                <b>둘째, 내 몸과 마음을 최대한 고요하게 안정시키고 회복시킬 시간이 필요합니다.</b><br/>
                지금은 전쟁 중, 난리 중인 상태처럼 긴장하며 지낼 수밖에 없습니다.<br/>
                마스크를 쓰고 지내면서 동선을 파악하기 위해 뉴스를 듣고, 또 온갖 부정적 뉴스를 들어야 합니다.<br/>
                그러므로 내 몸과 마음의 상태를 파악한 후에 남은 에너지를 효율적으로 쓰도록 해야 합니다. 효율적으로 에너지를 쓰는 가장 쉬운 방법은<br/>
                마음과 몸을 시끄럽지 않게, 고요하게 안정시키는 것입니다.<br/>
                비행기 폭격이 시작되었을 때 집의 불을 켜고 마루와 방, 지하실을 오고 가는 사람은 없습니다.그것은 자살 행위이니까요.<br/>
                불을 끄고 조용히 방공 지하실에서 최소한의 에너지만 쓰면서 몸과 마음을 고요한 상태에서 쉬게 합니다.
            </p>

            <img src="@/assets/_img/health_info_7.png">
            <p class="blog_text">
                <b>셋째, 스트레스를 줄일 수 있는 나만의 활동 특히 몸을 쓰는 활동을 해야 합니다.</b><br/>
                몸을 쓰는 활동은 호흡으로 시작하여 호흡으로 끝나게 하시면서 요란하지 않은 편하고 쉬운 운동을 하세요.<br/>
                몸에 무리가 가지 않게, 혈중 콜티졸이라는 스트레스 호르몬이 줄어들 수 있게, 살짝 땀이 날 정도, 호흡의 가빠짐을 살짝 느껴질 정도의<br/>
                운동을 하루 30분 이상 해주세요.<br/>
                약만큼 좋은 스트레스 안정효과를 발휘합니다.
            </p>

            <img src="@/assets/_img/health_info_8.png">
            <p class="blog_text">
                <b>넷째, 자신의 몸과 마음에 긍정적 기운을 불어넣어 주는 활동을 해야 합니다.</b><br/>
                자꾸 걱정과 의심, 부정적 생각에 이끌려가는 자신을 붙잡아야 합니다. 스트레스에 더 빠져들수록 더 나쁜 뉴스나 나쁜 생각을 많이 하게<br/> 
                되어 있다는 연구보고도 있습니다.<br/>
                자신의 상태를 파악하고 마음을 고요하게 하고, 호흡과 운동을 하면 전반적인 스트레스 상태는 완화되고 낮아집니다.<br/>
                이 상태가 유지되게 하기 위해서는 끝으로 자신에게 긍정적인 말을 해주어야 합니다. 긍정적인 기운과 말들은 스트레스가 침투하지 못하게<br/>
                하는 막을 우리 몸과 마음에 형성시켜준다고도 합니다. 이 긍정적인 느낌으로 하루를 마무리한다면 더욱 좋습니다.
            </p>

            <img src="@/assets/_img/health_info_9.png">
            <p class="blog_text">
                다음은 사람들이 뽑은 자신에게 하는 긍정적인 말 중 비교적 효과가 높은 <b>“자신에게 건네는 5가지 긍정 대화”</b> 입니다.(과대하고 요란하며<br/>
                비현실적인 긍정은 오히려 부담을 줍니다.)<br/>
                “어려운 시간인데, 그래도 내가 나를 잘 버티고 조절하고 있네”<br/>
                “내일은 오늘보다 조금 더 좋을지도 몰라”<br/>
                “모두가 힘든 시간, 나 스스로 잘하는 것이 다른 사람들에게도 조금 도움이 될 거야”<br/>
                “평상시보다 못하지만 그래도 해야 할 일을 조금 줄여서 차분히 앞으로 나가자!”<br/>
                “욕심내지 말고 기본만 하자! 화내지 말고 그러려니 하고, 가능하면 이해해주자!”<br/>
            </p>

            <img src="@/assets/_img/health_info_10.png">
            <p class="blog_text">
                <small>* 이 마음처방전은 하버드 비즈니스 리뷰와 더 사이언티스트 등의 자료를 참조하여 코비드19 심리지원단과 정신과 전문의 김현수가 새로이 작성한 것입니다.
                    <br/>
                    <a href="https://hbr.org/2020/03/coping-with-fatigue-fear-and-panic-during-a-crisis"
                    target="_blank">
                        https://hbr.org/2020/03/coping-with-fatigue-fear-and-panic-during-a-crisis</a>
                    <br/>
                    <a href="https://www.the-scientist.com/critic-at-large/opinion-coronavirus-pandemic-highlights-dangers-of-health-disparities-67718"
                    target="_blank">
                        https://www.the-scientist.com/critic-at-large/opinion-coronavirus-pandemic-highlights-dangers-of-health-disparities-67718</a>
                    <br/>
                    <a href="https://www.fatherly.com/love-money/allostatic-load-chronic-stress/"
                    target="_blank">
                        https://www.fatherly.com/love-money/allostatic-load-chronic-stress/</a>
                    <br/>
                    <br/>
                    <a href="http://covid19seoulmind.org/prescription/4187/"
                    target="_blank">
                        <span style="color: red;">[출처]</span> http://covid19seoulmind.org/prescription/4187/</a>
                </small>

                
                
                
            </p>

            <div class="blog_left_align" style="width: 25vw; border: solid 1px rgba(0,0,0,.3);">
                <a href="http://covid19seoulmind.org/prescription/4187/">
                    <img src="@/assets/_img/health_info_1.png" style="width: 25vw; ">
                    <div class="blog_text" style="color: black;">
                        <small>
                            <b>코로나블루 | 코로나 스트레스 과부하 현상과 이를 극복할 수 있...</b><br/>
                            서울 시민과 함께 해은 covid19 심리지원단의 마음 처방전이 다시 시작되...
                        </small>
                        <br/>
                        <br>
                        <p class="text_green">covid19seoulmind.org</p>
                    </div>
                </a>
            </div>
            

        
    </section> -->
</template>

<script>
export default {
    name: "HealthInfo",
    data() {
        return {
            openTab: "",
        };
    },
    computed: {
        tabPic() {
            if (this.openTab == "stress")
                return require("@/assets/_img/healthinfo_4.png");
            else if (this.openTab == "bipolar")
                return require("@/assets/_img/healthinfo_5.png");
            else if (this.openTab == "stress")
                return require("@/assets/_img/healthinfo_6.png");
            else return require("@/assets/_img/healthinfo_3.png");
        },
    },
    methods: {},
};
</script>

<style scoped>
ul {
    list-style-position: inside;
    font-size: 0.85rem;
}
.listBox {
    padding: 1vh 1vw;
    width: 100%;
    border-radius: 8px;
    background-color: #f7f7f7;
}
</style>
