<template>
    <transition name="slide">
        <div class="head" v-show="!hideNav">
            <router-link to="/" class="noDeco">
                <img
                    v-if="headerType == 'white'"
                    src="../assets/_img/wb_logo_transp.png"
                />
                <img v-else src="../assets/_img/wb_logo_color.png" />
            </router-link>

            <div :class="headerTextColor">
                <div
                    v-if="isMobile"
                    @click="toggleShowTopnav"
                    style="margin: 1.5vh 3vw;"
                >
                    <i class="fas fa-bars fa-2x"></i>
                </div>
                <div v-if="!isMobile || showTopnav" :class="topnavStyle">
                    <ul style="align-items: center;">
                        <!-- dynamic ---- PUBLIC ---items  -->
                        <li v-show="isPublicOrClient">
                            <router-link to="/about" :class="headerTextColor">
                                회사소개</router-link
                            >
                        </li>
                        <li v-show="isPublicOrClient">
                            <router-link
                                to="/healthinfo"
                                :class="headerTextColor"
                            >
                                건강정보</router-link
                            >
                        </li>
                        <li v-show="isPublicOrClient">
                            <router-link
                                to="/selfdiagnosis"
                                :class="headerTextColor"
                            >
                                자기진단</router-link
                            >
                        </li>
                        <li v-show="isPublicOrClient">
                            <router-link to="/program" :class="headerTextColor">
                                프로그램</router-link
                            >
                        </li>

                        <!-- dynamic ---- CLIENT ---items  -->
                        <li v-show="mode == 'client'" class="">
                            <router-link
                                to="/client/home"
                                :class="headerTextColor"
                            >
                                마이서비스</router-link
                            >
                        </li>
                        <!-- <li v-show="mode == 'client'" class="">
                            <router-link to="/client/course/list" class="">
                                신청하기</router-link
                            >
                        </li> -->
                        <!-- <li v-show="mode == 'client'" class="">
                            <router-link to="/docs" class="">
                                숙제하기</router-link
                            >
                        </li> -->
                        <!-- dynamic ---- COACH ---items  -->

                        <li v-show="mode == 'coach'" class="">
                            <router-link to="/coach/course/list" class="">
                                classes</router-link
                            >
                        </li>

                        <!-- dynamic ---- ADMIN ---items  -->

                        <li v-show="mode == 'admin'" class="dropdown">
                            <button class="dropbtn" href="#">수업관리</button>
                            <div class="dropdown-content">
                                <router-link
                                    to="/admin/course/list/all"
                                    class="head-drpdwn-link"
                                >
                                    모든 클래스</router-link
                                >

                                <router-link
                                    to="/admin/course/list/1"
                                    class="head-drpdwn-link"
                                >
                                    현재 진행 중인 수업</router-link
                                >

                                <router-link
                                    to="/admin/course/list/2"
                                    class="head-drpdwn-link"
                                >
                                    예정된 수업</router-link
                                >

                                <router-link
                                    to="/admin/course/list/3"
                                    class="head-drpdwn-link"
                                >
                                    종료된 수업</router-link
                                >

                                <router-link
                                    to="/admin/course/list/0"
                                    class="head-drpdwn-link"
                                >
                                    미확정된 수업</router-link
                                >
                            </div>
                        </li>
                        <li v-show="mode == 'admin'" class="dropdown">
                            <button class="dropbtn" href="#">회원관리</button>
                            <div class="dropdown-content">
                                <router-link
                                    to="/admin/students/list/o"
                                    class="head-drpdwn-link"
                                >
                                    모든 학생</router-link
                                >

                                <router-link
                                    to="/admin/students/list/ai"
                                    class="head-drpdwn-link"
                                >
                                    수업 신청한 학생</router-link
                                >

                                <router-link
                                    to="/admin/students/list/a"
                                    class="head-drpdwn-link"
                                >
                                    예정된 수업 학생</router-link
                                >

                                <router-link
                                    to="/admin/students/list/p"
                                    class="head-drpdwn-link"
                                >
                                    종료된 수업 학생</router-link
                                >

                                <router-link
                                    to="/admin/students/list/n"
                                    class="head-drpdwn-link"
                                >
                                    미확정된 수업 학생</router-link
                                >

                                <router-link
                                    to="/admin/teacher/list"
                                    class="head-drpdwn-link"
                                >
                                    선생님 관리</router-link
                                >
                            </div>
                        </li>
                        <li v-show="mode == 'admin'" class="nav-item">
                            <router-link class="" to="/admin/search/simple"
                                ><i class="fa fa-search"></i
                            ></router-link>
                        </li>
                        <!-- dynamic ---- GENERIC USER ---items  -->
                        <li v-if="user" class="dropdown">
                            <button class="dropbtn" :class="headerTextColor">
                                <i class="fas fa-user"></i>{{ user.firstname }}
                            </button>
                            <div class="dropdown-content">
                                <router-link
                                    to="/profile"
                                    title="go to profile page"
                                    >내 정보</router-link
                                >
                                <a @click="logout" href="#0"> 로그아웃</a>
                            </div>
                        </li>
                        <li v-else>
                            <router-link to="/login" :class="loginButtonClass">
                                Login
                                <i class="fa fa-arrow-right"></i>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <!-- <button class="header-toggle">
                    <span></span>
                </button> -->
            </div>
        </div>
    </transition>
</template>

<script>
import firebase from "firebase";
export default {
    props: {
        isMobile: Boolean,
    },
    data() {
        return {
            yPositionScroll: 0,
            hideNav: false,
            showTopnav: false,
            // showUserMenu: false,
        };
    },
    mounted() {
        console.log("from header: ", this.isMobile);
        // let $ = window.jQuery;
        // //
        // // Open/Close Header Menu //
        // //
        // var headerMenu = $(".header-menu");
        // var headerToggle = $(".header-toggle");
        // headerToggle.on("click", function() {
        //     if (headerMenu.hasClass("show")) {
        //         headerMenu.removeClass("show");
        //         headerToggle.removeClass("toggle-close");
        //     } else {
        //         headerMenu.addClass("show");
        //         headerToggle.addClass("toggle-close");
        //     }
        // });
        // $(document).on("click", function(e) {
        //     if (
        //         $(e.target).closest(".header-menu, .header-toggle").length === 0
        //     ) {
        //         if (headerMenu.hasClass("show")) {
        //             headerMenu.removeClass("show");
        //             headerToggle.removeClass("toggle-close");
        //         }
        //     }
        // });
        // var navDropdown = $(".nav-dropdown");
        // var navSubdropdown = $(".nav-subdropdown");
        // navDropdown.each(function() {
        //     var $this = $(this);
        //     $this
        //         .parent(".nav-item")
        //         .children(".nav-link")
        //         .addClass("nav-dropdown-toggle");
        // });
        // navSubdropdown.each(function() {
        //     var $this = $(this);
        //     $this
        //         .parent(".nav-dropdown-item")
        //         .children(".nav-dropdown-link")
        //         .addClass("nav-subdropdown-toggle");
        // });
        // //
        // // Dropdown Menu //
        // //
        // var navDropdownToggle = $(".nav-dropdown-toggle");
        // var navSubdropdownToggle = $(".nav-subdropdown-toggle");
        // navDropdownToggle.each(function() {
        //     var $this = $(this);
        //     if ($this.parent(".nav-item").children(".nav-dropdown").length) {
        //         var navDropdown = $this
        //             .parent(".nav-item")
        //             .children(".nav-dropdown");
        //         $this.on("click", function(e) {
        //             if ($this.hasClass("active")) {
        //                 $this.removeClass("active");
        //                 navDropdown.removeClass("show");
        //             } else {
        //                 $this.addClass("active");
        //                 navDropdown.addClass("show");
        //             }
        //             e.preventDefault();
        //         });
        //     }
        // });
        // //
        // // Subdropdown Menu //
        // //
        // navSubdropdownToggle.each(function() {
        //     var $this = $(this);
        //     if (
        //         $this.parent(".nav-dropdown-item").children(".nav-subdropdown")
        //             .length
        //     ) {
        //         var navSubDropdown = $this
        //             .parent(".nav-dropdown-item")
        //             .children(".nav-subdropdown");
        //         $this.on("click", function(e) {
        //             if ($this.hasClass("active")) {
        //                 $this.removeClass("active");
        //                 navSubDropdown.removeClass("show");
        //             } else {
        //                 $this.addClass("active");
        //                 navSubDropdown.addClass("show");
        //             }
        //             e.preventDefault();
        //         });
        //     }
        // });
        // /*===============================================
        //   5. Fullscreen Menu
        // ===============================================*/
        // var fm = $(".fullscreen-menu");
        // if (fm.length) {
        //     var fmToggle = $(".fm-toggle");
        //     var fmClose = $(".fm-close");
        //     //
        //     // Open //
        //     //
        //     fmToggle.on("click", function() {
        //         if (fm.hasClass("fm-show")) {
        //             fm.removeClass("fm-show");
        //         } else {
        //             fm.addClass("fm-show");
        //         }
        //     });
        //     //
        //     // Close //
        //     //
        //     fmClose.on("click", function() {
        //         fm.removeClass("fm-show");
        //         fmToggle.removeClass("fm-toggle-hide");
        //     });
        // }
        // window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        // window.removeEventListener("scroll", this.onScroll);
    },
    watch: {
        // yPositionScroll: function(oldVal, newVal) {
        //     if (this.mode == "public") {
        //         if (oldVal < newVal) this.hideNav = false;
        //         else this.hideNav = true;
        //     } else {
        //         return false;
        //     }
        // },
    },
    computed: {
        user() {
            return this.$store.state.currentUser;
        },
        mode() {
            return this.$store.getters.GET_PATH.substring(1);
        },
        isPublicOrClient() {
            if (this.mode == "public" || this.mode == "client") return true;
            else return false;
        },
        headerType() {
            let colorPages = [
                "/",
                "/home",
                "/about",
                "/selfdiagnosis",
                "/healthinfo",
                "/program",
                "/client/home",
            ];
            if (colorPages.some((x) => x == this.$route.path)) return "white";
            else return "color";
            //     this.$route.path == "/programDetails" ||
            //     this.$route.path == "/payment" ||
            //     this.$route.path == "/login"
            // )
            //     return "color";
            // else return "white";
        },
        headerTextColor() {
            if (this.headerType == "white") return "white";
            else return "gray900";
        },
        loginButtonClass() {
            if (this.headerType == "white") return "login_btn_w";
            else return "login_btn_c";
        },
        topnavStyle() {
            if (this.isMobile) return "small";
            else return "large";
        },
    },
    methods: {
        // onScroll() {
        //     this.yPositionScroll = window.pageYOffset;
        // },
        logout() {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    this.$store.commit("SET_CURRENT_USER", {});
                    this.$router.push({ path: "/login" });
                })
                .catch((err) => alert(err.message));
        },
        toggleShowTopnav() {
            this.showTopnav = !this.showTopnav;
            console.log("fired toggleShowTopnav. val: ", this.showTopnav);
        },
    },
    created() {},
};
</script>
<style scoped>
.login_btn_w {
    border: 1px solid #fff;
    color: #fff;
}
.login_btn_c {
    border: 1px solid #2db24e !important;
    color: #2db24e !important;
}
.login_btn {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 700;
    border-radius: 6px;
    text-transform: uppercase;
}

.head,
.large ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: 0;
    /* z-index: 993; */
    overflow: visible;
    /* background: black; */
    background: transparent;
    width: 100%;
    height: 5vh;
    padding: 2vh 0vw;
    list-style-type: none;
}
/* .large li {
    padding: 
    list-style-type: none;
} */
.head a,
head-drpdwn-item,
head-drpdwn-toggle,
head-drpdwn-link {
    text-decoration: none;
    font-weight: 500;
    font-size: 1em;
    margin-right: 1vw;
    padding: 0.75vh 1vw;
    border: 1px solid transparent;
}
a:visited {
    text-decoration: none;
    color: inherit;
}
a:hover,
router-link:hover {
    border: 1px solid;
    color: inherit;
}
/* Dropdown Button */
.dropbtn {
    /* background-color: #04aa6d; */
    /* color: white; */
    padding: 0.5vh 1vw;
    font-size: 1rem;
    border: 1px solid white;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: rgba(241, 241, 241, 0.8);
    padding: 1vh 0;
    min-width: 12vw;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0.5vw;
    border-radius: 6px;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 1vh 1.5vw;
    text-decoration: none;
    display: block;
    width: 100%;
}
/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: rgba(229, 249, 234, 0.7);
    border: 1px solid transparent;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: #cccccc;
}
.noDeco:hover {
    text-decoration: none;
    border: 1px solid transparent;
}

/* .small a:not(:first-child) {
    display: none;
} */
.small a.icon {
    float: right;
    display: block;
}

.small {
    position: absolute;
    background-color: #f7f7f7;
    border: 2px solid #999999;
    color: black;
    font-weight: 500;
    line-height: 2em;
    top: 7vh;
    right: 2vw;
    width: 35vw;
    border-radius: 20px;
}
.small .icon {
    position: absolute;
    right: 0;
    top: 0;
}
.small a {
    float: none;
    display: block;
    text-align: left;
    padding: 1.5vh 2vw;
    margin: 0;
    border: none;
}
.small .login_btn {
    border-radius: 0;
    border: none;
}
.small ul {
    display: flex;
    flex-direction: column;
    padding: 2vh 0;
}
.small li {
    width: 100%;
}
.small a:hover {
    border: none;
    background-color: #cccccc;
}
</style>
