<template>
    <div>
            <h4>건강을 개선하고 싶은 이유(5가지)</h4>
            <div class="shadowbox">
                <div class="table-div" style="width: 99%;">
                    <div>
                        <div>1)</div>
                        <div>{{ sa.improve_reason1 }}</div>
                    </div>
                    <div>
                        <div>2)</div>
                        <div>{{ sa.improve_reason2 }}</div>
                    </div>
                    <div>
                        <div>3)</div>
                        <div>{{ sa.improve_reason3 }}</div>
                    </div>
                    <div>
                        <div>4)</div>
                        <div>{{ sa.improve_reason4 }}</div>
                    </div>
                    <div>
                        <div>5)</div>
                        <div>{{ sa.improve_reason5 }}</div>
                    </div>

                </div>
                <div>
                    <div class="wlb_em_main1">
                        건강을 개선하는 가장 중요한 이유을 말해주세요.
                    </div>
                    <div>{{ sa.most_important_reason }}</div>
                </div>
                <div>
                    <div class="wlb_em_main1">
                        현재 나의 건강 목표를 말해주세요.
                    </div>
                    <div>{{ sa.whole_health_goal }}</div>
                </div>
            </div>

            <div class="shadowbox" style="margin-top: 2vh;">
                <h4>건강평가</h4>
                <div>
                    <small>
                        <div class="table-div">
                            <div>
                                <div>XX</div>
                                <div>전혀 그렇지 않다</div>
                            </div>
                            <div>
                                <div>X</div>
                                <div>그렇지 않다</div>
                            </div>
                            <div>
                                <div>O</div>
                                <div>그렇다</div>
                            </div>
                            <div>
                                <div>OO</div>
                                <div>매우 그렇다</div>
                            </div>
                            <div>
                                <div>?</div>
                                <div>모르겠다</div>
                            </div>
                        </div>
                    </small>
                </div>
                <div class="card-3">
                    <table style="width: 99%;">
                        <tr>
                            <th style="width:75%">평소건강 상태</th>
                            <th style="width:5%" class="center">XX</th>
                            <th style="width:5%" class="center">X</th>
                            <th style="width:5%" class="center">O</th>
                            <th style="width:5%" class="center">OO</th>
                            <th style="width:5%" class="center">?</th>
                        </tr>
                        <tr v-for="(item, i) in saFields.genHealth" :key="i">
                            <td :style="cs">{{ item.display }}</td>
                            <td :style="cs" class="center">
                                <span v-if="sa[item.field] == 1">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                                                         
                            <td :style="cs" class="center">
                                <span v-if="sa[item.field] == 2">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                                                         
                            <td :style="cs" class="center">
                                <span v-if="sa[item.field] == 3">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                                                         
                            <td :style="cs" class="center">
                                <span v-if="sa[item.field] == 4">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                                                         
                            <td :style="cs" class="center">
                                <span v-if="sa[item.field] == 0">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                                                         
                        </tr>
                    </table>
                </div>
                <div class="card-3" style="margin-top: 2vh;">
                    <table style="width: 99%;">
                        <tr>
                            <th style="width:75%">건강하고 생기 있는 라이프스타일</th>
                            <th style="width:5%" class="center">XX</th>
                            <th style="width:5%" class="center">X</th>
                            <th style="width:5%" class="center">O</th>
                            <th style="width:5%" class="center">OO</th>
                            <th style="width:5%" class="center">?</th>
                        </tr>
                        <tr v-for="(item, i) in saFields.healthResilience" :key="i">
                            <td :style="cs">{{ item.display }}</td>
                            <td :style="cs">
                                <span v-if="sa[item.field] == 1">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                                                         
                            <td :style="cs">
                                <span v-if="sa[item.field] == 2">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                                                         
                            <td :style="cs">
                                <span v-if="sa[item.field] == 3">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                                                         
                            <td :style="cs">
                                <span v-if="sa[item.field] == 4">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                                                         
                            <td :style="cs">
                                <span v-if="sa[item.field] == 0">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                             
                        </tr>
                    </table>
                </div>

                <div class="card-3" style="margin-top: 2vh;">
                    <table style="width: 99%;">
                        <tr>
                            <th style="width:75%">Mind and Spirit</th>
                            <th style="width:5%" class="center">XX</th>
                            <th style="width:5%" class="center">X</th>
                            <th style="width:5%" class="center">O</th>
                            <th style="width:5%" class="center">OO</th>
                            <th style="width:5%" class="center">?</th>
                        </tr>
                        <tr v-for="(item, i) in saFields.mindSpirit" :key="i">
                            <td :style="cs">{{ item.display }}</td>
                            <td :style="cs" class="center">
                                <span v-if="sa[item.field] == 1">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                                                         
                            <td :style="cs" class="center">
                                <span v-if="sa[item.field] == 2">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                                                         
                            <td :style="cs" class="center">
                                <span v-if="sa[item.field] == 3">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                                                         
                            <td :style="cs" class="center">
                                <span v-if="sa[item.field] == 4">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                                                         
                            <td :style="cs" class="center">
                                <span v-if="sa[item.field] == 0">
                                    <i class="fa fa-check"></i>
                                </span>
                            </td>                               
                        </tr>
                    </table>
                </div>

                <div class="card-3" style="margin-top: 2vh;">
                    <div class="wlb_em_main1">
                        어떤 이유로 현재 나의 생활방식이 건강하고 생기 있다고 생각하세요?
                    </div>
                    <div>{{ sa.current_lifestyle }}</div>
                </div>
            </div>            
            
        </div>
</template>

<script>
import mxDocfields from '@/components/mx-docfields.js'
export default {
    name: "DisplaySA",
    mixins: [mxDocfields],
    props: {
        sa: {
            type: Object,
            required: true,
        },
    },
    data: function() {
        return {
            cs: 'border-right: 1px solid lightgrey; border-bottom: 1px solid lightgrey;',
        };
    },
    methods: {},
}
</script>

<style>

</style>

/**
 * dependencies: mx-docfields.js
 */
