<template>
  
<section id="main ">
    <div class="container main_container noHeaderPicSpace">

        <section id="content">
            <div id="app" v-cloak>
                <Modal v-if="sa.show" @closeme="sa.show = false" id="modal">
                    <h4>
                        건강을 개선하고 싶은 이유(5가지)
                    </h4>    
                        <p>내용을 자세하고 솔직하게 적어 주시면 좋아요!</p>
                        <div class="shadowbox">
                            <div class="table-div" style="width: 99%;">
                                <div>
                                    <div>1)</div>
                                    <div>
                                        <input type="text"
                                            v-model="sa.cmp.improve_reason1" />
                                    </div>
                                </div>
                                <div>
                                    <div>2)</div>
                                    <div>
                                        <input type="text"
                                            v-model="sa.cmp.improve_reason2" />
                                    </div>
                                </div>
                                <div>
                                    <div>3)</div>
                                    <div>
                                        <input type="text"
                                            v-model="sa.cmp.improve_reason3" />
                                    </div>
                                </div>
                                <div>
                                    <div>4)</div>
                                    <div>
                                        <input type="text"
                                            v-model="sa.cmp.improve_reason4" />
                                    </div>
                                </div>
                                <div>
                                    <div>5)</div>
                                    <div>
                                        <input type="text"
                                            v-model="sa.cmp.improve_reason5" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    건강을 개선하는 가장 중요한 이유을 말해주세요.
                                </div>
                                <div>
                                    <input type="text"
                                        v-model="sa.cmp.most_important_reason" />
                                </div>
                            </div>
                            <div>
                                <div>
                                    현재 나의 건강 목표를 말해주세요.
                                </div>
                                <div>
                                    <input type="text"
                                        v-model="sa.cmp.whole_health_goal" />
                                </div>
                            </div>
                        </div>

                        <div class="shadowbox" style="margin-top: 2vh;">
                            <h4>건강평가</h4>
                            <div>
                                질문에 알맞은 답을 선택해주세요.
                                <div class="table-div" style="color: blue;">
                                    <div>
                                        <div>XX</div>
                                        <div>전혀 그렇지 않다</div>
                                    </div>
                                    <div>
                                        <div>X</div>
                                        <div>그렇지 않다</div>
                                    </div>
                                    <div>
                                        <div>O</div>
                                        <div>그렇다</div>
                                    </div>
                                    <div>
                                        <div>OO</div>
                                        <div>매우 그렇다</div>
                                    </div>
                                    <div>
                                        <div>?</div>
                                        <div>모르겠다</div>
                                    </div>
                                </div>
                                전체 건강 목표를 정하기 위해 모두 작성해주세요.
                            </div>
                            <div class="card-3">
                                <table style="width: 99%;">
                                    <tr>
                                        <th style="width:75%">평소건강 상태</th>
                                        <th style="width:5%" class="center">XX</th>
                                        <th style="width:5%" class="center">X</th>
                                        <th style="width:5%" class="center">O</th>
                                        <th style="width:5%" class="center">OO</th>
                                        <th style="width:5%" class="center">?</th>
                                    </tr>
                                    <tr v-for="(item, idx) in saFields.genHealth" :key="idx">
                                        <td>{{ item.display }}</td>
                                        <td class="center">
                                            <input type="radio"
                                                value="1"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>
                                        <td class="center">
                                            <input type="radio"
                                                value="2"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>
                                        <td class="center">
                                            <input type="radio"
                                                value="3"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>
                                        <td class="center">
                                            <input type="radio"
                                                value="4"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>
                                        <td class="center">
                                            <input type="radio"
                                                value="0"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>                                
                                    </tr>
                                </table>
                            </div>
                            <div class="card-3" style="margin-top: 2vh;">
                                <table style="width: 99%;">
                                    <tr>
                                        <th style="width:75%">건강하고 생기 있는 라이프스타일</th>
                                        <th style="width:5%" class="center">XX</th>
                                        <th style="width:5%" class="center">X</th>
                                        <th style="width:5%" class="center">O</th>
                                        <th style="width:5%" class="center">OO</th>
                                        <th style="width:5%" class="center">?</th>
                                    </tr>
                                    <tr v-for="(item, idx) in saFields.healthResilience" :key="idx">
                                        <td>{{ item.display }}</td>
                                        <td class="center">
                                            <input type="radio"
                                                value="1"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>
                                        <td class="center">
                                            <input type="radio"
                                                value="2"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>
                                        <td class="center">
                                            <input type="radio"
                                                value="3"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>
                                        <td class="center">
                                            <input type="radio"
                                                value="4"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>
                                        <td class="center">
                                            <input type="radio"
                                                value="0"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>                                
                                    </tr>
                                </table>
                            </div>

                            <div class="card-3" style="margin-top: 2vh;">
                                <table style="width: 99%;">
                                    <tr>
                                        <th style="width:75%">Mind and Spirit</th>
                                        <th style="width:5%" class="center">XX</th>
                                        <th style="width:5%" class="center">X</th>
                                        <th style="width:5%" class="center">O</th>
                                        <th style="width:5%" class="center">OO</th>
                                        <th style="width:5%" class="center">?</th>
                                    </tr>
                                    <tr v-for="(item, idx) in saFields.mindSpirit" :key="idx">
                                        <td>{{ item.display }}</td>
                                        <td class="center">
                                            <input type="radio"
                                                value="1"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>
                                        <td class="center">
                                            <input type="radio"
                                                value="2"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>
                                        <td class="center">
                                            <input type="radio"
                                                value="3"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>
                                        <td class="center">
                                            <input type="radio"
                                                value="4"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>
                                        <td class="center">
                                            <input type="radio"
                                                value="0"
                                                :name="item.field"
                                                v-model="sa.cmp[item.field]" />
                                        </td>                                
                                    </tr>
                                </table>
                            </div>

                            <div class="card-3" style="margin-top: 2vh;">
                                <div>
                                    어떤 이유로 현재 나의 생활방식이 건강하고 생기 있다고 생각하세요?
                                </div>
                                <div>
                                    <textarea v-model="sa.cmp.current_lifestyle">
                                    </textarea>
                                </div>
                            </div>
                        </div>


                        <div style="margin-top: 2vh;">
                            <button @click="submitSA"
                                class="button">저장</button>
                            <button @click="sa.show = false"
                                class="button">취소</button>                       
                        </div>
                                       
                </Modal>

                <Modal v-if="goals.show" @closeme="goals.show = false">
                    <h4>AWH 달성 목표 작성하기</h4>

                    <div class="shadowbox">
                        <p>
                            모든 빈 칸에 1개 이상의 내용을 적어주세요.
                            모든 빈 칸을 채워주세요. 
                            <br />
                            (만약 대답하기 힘든 내용이 있으면 비워 두셔도 됩니다.)
                        </p>
                        <table style="width: 99%;">
                            <tr>
                                <th :style="goals.bgPhys">신체</th>
                                <th :style="goals.bgMental">정신</th>
                                <th :style="goals.bgSpirit">영혼</th>
                            </tr>
                            <tr>
                                <td :style="goals.bgPhys">
                                    건강한 식생활 <br />
                                    <textarea v-model="goals.cmp.goal_body_eat">
                                    </textarea>
                                </td>
                                <td :style="goals.bgMental">
                                    기분 전환하는 방법 <br />
                                    <textarea v-model="goals.cmp.goal_mind_relax">
                                    </textarea>                                
                                </td>
                                <td :style="goals.bgSpirit">
                                    나만의 신념&관습<br />
                                    <textarea v-model="goals.cmp.goal_spirit_belief">
                                    </textarea>                                         
                                </td>
                            </tr>
                            <tr>
                                <td :style="goals.bgPhys">
                                    신체 활동 <br />
                                    <textarea v-model="goals.cmp.goal_body_phys">
                                    </textarea>
                                </td>
                                <td :style="goals.bgMental">
                                    낙관주의 & 희망사항 <br />
                                    <textarea v-model="goals.cmp.goal_mind_hope">
                                    </textarea>                                
                                </td>
                                <td :style="goals.bgSpirit">
                                    나를 지원해주는 사람들<br />
                                    <textarea v-model="goals.cmp.goal_spirit_relationship"
                                        placeholder="예시) 정신적 지지자, 친구, 단체, 아는 사람">
                                    </textarea>                                         
                                </td>
                            </tr>
                            <tr>
                                <td :style="goals.bgPhys">
                                    편안한 잠자는 방법 <br />
                                    <textarea v-model="goals.cmp.goal_body_sleep">
                                    </textarea>
                                </td>
                                <td :style="goals.bgMental">
                                    긍정적인 태도 <br />
                                    <textarea v-model="goals.cmp.goal_mind_attitude">
                                    </textarea>                                
                                </td>
                                <td :style="goals.bgSpirit">
                                    다른 사람들에게 베푸는 친절 <br />
                                    <textarea v-model="goals.cmp.goal_spirit_service">
                                    </textarea>                                         
                                </td>
                            </tr>
                            <tr>
                                <td>
                                </td>
                                <td>                              
                                </td>
                                <td :style="goals.bgSpirit">
                                    의미와 목적 <br />
                                    <textarea v-model="goals.cmp.goal_spirit_purpose">
                                    </textarea>                                         
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="shadowbox" style="margin-top: 2vh;">
                        <table cellspacing="2" cellpadding="4">
                            <tr>
                                <th style="width: 70%;">질문</th>
                                <th style="width: 30%;">답변 (네/아니요)</th>
                            </tr>
                            <tr v-for="(g, gindex) in goalsFields.impact"
                                :style="getRowStyle(gindex)" :key="gindex">
                                <td>
                                    {{ g.english }}<br />
                                    {{ g.question }}
                                </td>
                                <td align="center">
                                    <input type="checkbox"
                                        v-model="goals.cmp[g.field]" />
                                </td>
                            </tr>
                        </table>

                    </div>

                    <div class="shadowbox" style="margin-top: 2vh;">
                        <p>
                            6개 질문 중 어느 하나라도 ‘아니오’라도 대답한 경우 ‘달성목표 작성’을 다시 작성하여  Impact기준에 더 가깝게 맞추세요.
                        </p>
                        <h4>액션 플랜</h4>
                        <div>
                            기간 
                            <select v-model="goals.cmp.ap_weeks">
                                <option value="1">1주</option>
                                <option value="2">2주</option>
                                <option value="3">3주</option>
                                <option value="4">4주</option>
                                <option value="5">5주</option>
                                <option value="6">6주</option>
                                <option value="7">7주</option>
                                <option value="8">8주</option>
                            </select>
                        </div>

                        <div>
                            무엇을 달성할 예정이세요?
                        </div>
                        <div>
                            <input type="text" v-model="goals.cmp.ap_what" />
                        </div>
                        <div>
                            얼마만큼 달성할 예정인가요?
                        </div>
                        <div>
                            <input type="text" v-model="goals.cmp.ap_howmuch" />
                        </div>
                        <div>
                            얼마나 자주 달성할 예정인가요?
                        </div>
                        <div>
                            <input type="text" v-model="goals.cmp.ap_howoften" />
                        </div>
                        <div>
                            언제 달성할 예정인가요?
                        </div>
                        <div>
                            <input type="text" v-model="goals.cmp.ap_when" />
                        </div>
                        <div>
                            자신감 척도
                            <select v-model="goals.cmp.ap_confidence">
                                <option value="0">0점</option>
                                <option value="1">1점</option>
                                <option value="2">2점</option>
                                <option value="3">3점</option>
                                <option value="4">4점</option>
                                <option value="5">5점</option>
                                <option value="6">6점</option>
                                <option value="7">7점</option>
                                <option value="8">8점</option>
                                <option value="9">9점</option>
                                <option value="10">10점</option>
                            </select>
                        </div>
                    </div>

                    <div class="shadowbox" style="margin-top: 2vh;">
                        <table cellspacing="2" cellpadding="4" style="width: 99%;">
                            <tr>
                                <th style="width: 15%;">기간</th>
                                <th style="width: 55%;">목표를 달성하는데 필요한 단계</th>
                                <th style="width: 15%;">자신감 척도</th>
                                <th style="width: 15%;">달성 여부</th>
                            </tr>
                            <tbody v-for="windex in 8" :key="windex">
                                <tr :style="getRowStyle(windex)">
                                    <td align="center">
                                        <span style="font-weight: bold;">{{ windex }} 주차</span>
                                    </td>
                                    <td>
                                        <span v-if="goals.mode == 'create'">
                                            <textarea v-model="goals.cmp['wk'+windex+'_goal']">
                                            </textarea>
                                        </span>
                                        <span v-else>
                                            원래목표: {{ goals.cmp['wk'+windex+'_goal'] }}
                                            <textarea v-model="goals.cmp['wk'+windex+'_revision']"
                                                placeholder="변경사항">
                                            </textarea>
                                        </span>
                                        
                                    </td>
                                    <td align="center">
                                        <select v-model="goals.cmp['wk'+windex+'_confidence']">
                                            <option value="0">0점</option>
                                            <option value="1">1점</option>
                                            <option value="2">2점</option>
                                            <option value="3">3점</option>
                                            <option value="4">4점</option>
                                            <option value="5">5점</option>
                                            <option value="6">6점</option>
                                            <option value="7">7점</option>
                                            <option value="8">8점</option>
                                            <option value="9">9점</option>
                                            <option value="10">10점</option>
                                        </select>                                    
                                    </td>
                                    <td align="center">
                                        <input type="checkbox"
                                            v-model="goals.cmp['wk'+windex+'_achieved']" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    
                    </div>
                    <div style="margin: 2vh;">
                        <button @click="submitGoals"
                            class="button">저장</button>
                        <button @click="goals.show = false"
                            class="button">취소</button>                        
                    </div>

                </Modal>

                <Modal v-if="sa.view" @closeme="sa.view = false">
                    <DisplaySA
                        :sa="saData"></DisplaySA>
                </Modal>

                <Modal v-if="goals.view" @closeme="goals.view = false">
                    <DisplayGoals
                        :goals="goalsData"></DisplayGoals>
                </Modal>


                <div>
                    <ul>
                        <li>원활한 교육 진행으로 위해 꼭 참여 부탁드립니다.</li>
                        <li>‘작성하기’ 눌러주세요.</li>
                        <li>내용을 작성한 다음 제일 하단의 ‘저장’을 눌러주세요.</li>
                    </ul>
                </div>

                <div style="margin-top: 4vh;"></div>

                <h3>[ 사전 설문] 자기평가표</h3>
                <div class="shadowbox">
                    <div v-if="countSA == 0">
                        <button @click="openCreateSA"
                            class="button">작성하기</button>
                    </div>
                    <div v-else>
                        {{ showDate(saData.timestamped) }} 에 제출함 (<a @click="sa.view = true">보기</a>)
                        <div>
                            <button @click="openEditSA"
                                class="button">수정</button>
                            <button @click="deleteSA"
                                class="button">삭제</button>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 4vh;"></div>


                <h3>[3일차 설문] AWH 달성 목표</h3>
                <div class="shadowbox">
                    <div v-if="countGoals == 0">
                        <button @click="openCreateGoals"
                            class="button">목표 작성하기</button>
                    </div>
                    <div v-else>
                        {{showDate(goalsData.timestamped) }} 에 제출함 (<a @click="goals.view = true">보기</a>)
                        <div>
                            <button @click="openEditGoals"
                                class="button">수정</button>
                            <button @click="deleteGoals"
                                class="button">삭제</button>
                        </div>

                    </div>
                </div>
                
            </div>
        </section>

    </div>
</section>
</template>

<script>
import axios from 'axios'
import DisplayGoals from '@/components/DisplayGoals'
import DisplaySA from '@/components/DisplaySA'
import mxDocfields from '@/components/mx-docfields.js'
import mxNotify from '@/components/mx-notify.js'
import Modal from '@/components/Modal'
import moment from 'moment'
export default {
    mixins: [mxDocfields, mxNotify],
    components: {
        DisplayGoals,
        DisplaySA,
        Modal,
    },
    name: "Docs",

    data() {
        return {
            countSA: '',
            countGoals: '',
            saData: [],
            goalsData: [],
            sa: {
                show: false, // create, edit
                view: false, // view only
                mode: '', // create, edit
                cmp: {}
            },
    
            goals: {
                show: false, // create, edit
                view: false, // view only
                mode: '', // create, edit
                cmp: {},
                
                bgPhys: ' background-color: LavenderBlush; ',
                bgMental: ' background-color: HoneyDew; ',
                bgSpirit: ' background-color: Azure; ',
            }
        }
    },
    created() {
        axios.get('/doc/client/init')
            .then(res => {
                console.log(res.data)
                this.countSA = res.data.cnt_sa;
                this.countGoals = res.data.cnt_goals;
                this.saData = res.data.saData;
                this.goalsData = res.data.goals;
            })
    },
    methods: {
        setCleanSA() {
            this.sa.cmp = {
                improve_reason1: '',
                improve_reason2: '',
                improve_reason3: '',
                improve_reason4: '',
                improve_reason5: '',
                most_important_reason: '',
                whole_health_goal: '',
                blood_pressure: 0,
                blood_sugar: 0,
                cholesterol: 0,
                weight: 0,
                reg_exam: 0,
                primary_doc: 0,
                no_chronic: 0,
                control_chronic: 0,
                meds_coping: 0,
                sobriety: 0,
                areas_improve: 0,
                healthy_food: 0,
                better_healthy_food: 0,
                exercise: 0,
                better_exercise: 0,
                sleep: 0,
                better_sleep: 0,
                reduce_stress: 0,
                better_reduce_stress: 0,
                help_others: 0,
                better_help_others: 0,
                support_network: 0,
                better_support_network: 0,
                optimistic: 0,
                better_optimistic: 0,
                positive: 0,
                better_positive: 0,
                spiritual: 0,
                better_spiritual: 0,
                purpose: 0,
                better_purpose: 0,
                current_lifestyle: '',
            };
        },
        setEditSA(obj) {
            this.sa.cmp = {};
            this.sa.cmp.updateId = obj.id;

            let exclude = ['id', 'user_id', 'timestamped'];
            for (let prop in obj) { 
                if (!exclude.includes(prop)) {
                    this.sa.cmp[prop] = obj[prop];
                }
            }
            
        },
        setCleanGoals() {
            this.goals.cmp = {
                goal_body_eat: '',
                goal_body_phys: '',
                goal_body_sleep: '',
                goal_mind_relax: '',
                goal_mind_hope: '',
                goal_mind_attitude: '',
                goal_spirit_belief: '',
                goal_spirit_relationship: '',
                goal_spirit_service: '',
                goal_spirit_purpose: '',
                q_improve: false,
                q_measurable: false,
                q_positive: false,
                q_achievable: false,
                q_action: false,
                q_time: false,
                ap_weeks: 8,
                ap_what: '',
                ap_howmuch: '',
                ap_howoften: '',
                ap_when: '',
                ap_confidence: 0,
            };
            for (let i=1; i<=8; i++) {
                let pre = 'wk'+i+'_';
                this.goals.cmp[pre+'goal'] = '';
                this.goals.cmp[pre+'revision'] = '';
                this.goals.cmp[pre+'achieved'] = false;
                this.goals.cmp[pre+'confidence'] = 0;
            }
        },
        setEditGoals(obj) {
            this.goals.cmp = {};
            this.goals.cmp = {
                updateId: obj.id,
                goal_body_eat: obj.goal_body_eat,
                goal_body_phys: obj.goal_body_phys,
                goal_body_sleep: obj.goal_body_sleep,
                goal_mind_relax: obj.goal_mind_relax,
                goal_mind_hope: obj.goal_mind_hope,
                goal_mind_attitude: obj.goal_mind_attitude,
                goal_spirit_belief: obj.goal_spirit_belief,
                goal_spirit_relationship: obj.goal_spirit_relationship,
                goal_spirit_service: obj.goal_spirit_service,
                goal_spirit_purpose: obj.goal_spirit_purpose,

                q_improve: (obj.q_improve == 1) ? true : false,
                q_measurable: (obj.q_measurable == 1) ? true : false,
                q_positive: (obj.q_positive == 1) ? true : false,
                q_achievable: (obj.q_achievable == 1) ? true : false,
                q_action: (obj.q_action == 1) ? true : false,
                q_time: (obj.q_time == 1) ? true : false,
                ap_weeks: obj.ap_weeks,
                ap_what: obj.ap_what,
                ap_howmuch: obj.ap_howmuch,
                ap_howoften: obj.ap_howoften,
                ap_when: obj.ap_when,
                ap_confidence: obj.ap_confidence,
            };
            for (let i=1; i<=8; i++) {
                let pre = 'wk'+i+'_';
                this.goals.cmp[pre+'goal'] = obj[pre+'goal'];
                this.goals.cmp[pre+'revision'] = obj[pre+'revision'];
                this.goals.cmp[pre+'achieved'] = (obj[pre+'achieved'] == 1) ?  true : false;
                this.goals.cmp[pre+'confidence'] = obj[pre+'confidence'];
            }
        },
        openCreateSA() {
            this.setCleanSA();
            this.sa.mode = 'create';
            this.sa.show = true;
        },
        openEditSA() {
            this.setEditSA(this.saData);
            this.sa.mode = 'edit';
            this.sa.show = true;
        },
        openCreateGoals() {
            this.setCleanGoals();
            this.goals.mode = 'create';
            this.goals.show = true;
        },
        openEditGoals() {
            this.setEditGoals(this.goalsData);
            this.goals.mode = 'edit';
            this.goals.show = true;
        },
        async deleteSA() {
            if (!confirm("Are you sure?"))
                return false;
            let pl = { deleteId: this.saData.id };
            try {
                let res = await axios.get('/doc/deleteSA', {params: pl})
                this.refreshData(res.data);
            } catch {this.ntfError("Oops. Something went wrong. Please try again later!", true);}
        },
        async deleteGoals() {
            if (!confirm("Are you sure?"))
                return false;
            let pl = { deleteId: this.goalsData.id };
            try {
                let res = await axios.get('/doc/deleteGoals', {params: pl});
                this.refreshData(res.data);
            } catch {this.ntfError("Oops. Something went wrong. Please try again later!", true);}
            
        },
        refreshData(resData) {
            this.countSA = resData.cnt_sa;
            this.countGoals = resData.cnt_goals;
            this.saData = resData.saData;
            this.goalsData = resData.goals;
        },
        async submitSA() {
            let path = '/doc/insertSA';
            if (this.sa.mode == 'edit')
                path = '/doc/update/SA';
            
            let pl = this.sa.cmp;
            try {
                let res = await axios.post(path, pl);
                this.refreshData(res.data);
                this.sa.show = false;
                this.ntfSuccess("Assessment Data saved");
            } catch {this.ntfError("Oops. Something went wrong. Please try again later!", true);}
            
        },
        async submitGoals() {
            let path = '/doc/insertGoals';
            if (this.goals.mode == 'edit')
                path = '/doc/update/goals';
            
            let pl = this.goals.cmp;
            try {
                let res = await axios.post(path, pl);
                this.refreshData(res.data);
                this.goals.show = false;
                this.ntfSuccess("Goals saved");
            } catch {this.ntfError("Oops. Something went wrong. Please try again later!", true);}
            
        },
        getRowStyle(idx) {
            if (idx % 2 == 0)
                return 'background-color: ghostwhite';
            else return '';
        },
        showDate(string) {
            if (string == null)
                return '';
            else return moment(string).format("YYYY-MM-DD HH:mm)");
        }, 
        
    },
}
</script>

<style scoped>
</style>