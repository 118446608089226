<template>
    <div>
        <img style="width: 100%;" src="@/assets/_img/about_1.jpg" />
        <img style="width: 100%;" src="@/assets/_img/about_2.jpg" />
        <img style="width: 100%;" src="@/assets/_img/about_3.png" />
        <div style="text-align: center;">
            <span class="font32 weight700 main_green "
                >&#183; vision &#183;</span
            >
            <div class="gradient_text_xl">
                Personalized Mental Health<br />Management Solution
            </div>
            <div style="margin-top: 2vh;" class="font24 weight300">
                자가 진단을 통해 현재 상태를 확인이 가능하고, 정신건강 증진을
                위한 프로그램을 제공하여<br />
                개인 맞춤형 정신건강 관리 솔루션 서비스를 추구합니다.
            </div>
        </div>
        <div style="margin-top: 12vh;"></div>
        <img style="width: 100%;" src="@/assets/_img/about_4.png" />
        <!-- <div style="margin-top: 6vh;"></div> -->
        <div class="bg_light flex_box">
            <div>
                <div
                    class="font32 weight700 main_green spacer_lg"
                    style="text-align: center;"
                >
                    &#183; brand identity &#183;
                </div>
                <div style="margin-top: 2vh;"></div>
                <div style="text-align: center;">
                    <b>w, b </b>가 자연스럽게 이어지게 디자인하여<br />
                    마음과 마음, 마음과 육체, 사람과 사람을 이어주는<br />
                    정신건강 서비스라는 뜻을 담은 디자인이다.
                </div>
                <div style="margin-top: 3vh;"></div>
            </div>
            <div
                class="bg_white flex_box pad_md"
                style="padding-bottom: 10vh; width: 80%;"
            >
                <div class="font24 weight500 gray900">
                    English Logo Type
                </div>
                <div class="spacer_md"></div>

                <div class="row">
                    <div style="padding: 8vh 9vw; background-color: #f7f7f7;">
                        <img src="@/assets/_img/wb_logo_en_bw.png" />
                    </div>
                    <span class="spacer_h_sm"></span>
                    <div style="padding: 8vh 9vw; background-color: #f7f7f7;">
                        <img src="@/assets/_img/wb_logo_en_c.png" />
                    </div>
                </div>
                <div class="spacer_lg"></div>
                <!-- <div style="margin-top: 12vh;"></div> -->

                <div class="font24 weight500 gray900">Korean Logo Type</div>
                <div class="spacer_md"></div>
                <div class="row">
                    <span style="padding: 8vh 9vw; background-color: #f7f7f7;">
                        <img src="@/assets/_img/wb_logo_kr_bw.png" />
                    </span>
                    <span class="spacer_h_sm"></span>
                    <span style="padding: 8vh 9vw; background-color: #f7f7f7;">
                        <img src="@/assets/_img/wb_logo_kr_c.png"
                    /></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LandingAbout",
};
</script>

<style></style>
