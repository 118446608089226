<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div
                    class="modal-container"
                    :class="boxStyle"
                    :style="containerDimensions"
                    :id="'mc-' + modalId"
                >
                    <div class="modal-top-bar" :style="closeStyleTop">
                        <a @click="emitClose()">[닫기]</a>
                    </div>
                    <slot></slot>
                    <div
                        @click="emitClose()"
                        class="bord_gray pad_md spacer_lg"
                        :style="closeStyleBott"
                    >
                        닫기
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: "modal",
    props: {
        width: {
            type: String,
            required: false,
            default: "65vw",
        },
        height: {
            type: String,
            required: false,
            default: "80vh",
        },
        type: {
            type: String,
            required: false,
            default: "0",
        },
    },
    data() {
        return {
            modalId: 0,
        };
    },
    beforeMount: function() {
        this.modalId = btoa(Math.random()).substring(0, 12);
        console.log(this.modalId);

        document.addEventListener("keydown", this.modalKeys);
    },
    beforeDestroy: function() {
        document.removeEventListener("keydown", this.modalKeys);
    },
    computed: {
        containerDimensions: function() {
            return "width: " + this.width + "; height: " + this.height + ";";
        },
        boxStyle() {
            if (this.type == "1") return "border-radius: 20px; ";
            else return "overflow-y: scroll;";
        },
        closeStyleTop() {
            if (this.type == "0") return "display: block;";
            else return "display: none;";
        },
        closeStyleBott() {
            let out = "text-align: center; border-radius: 6px;";
            if (this.type == "1") return `${out} display: block;`;
            else return `${out} display: none;`;
        },
    },
    methods: {
        modalKeys: function(event) {
            if (event.key == "Escape") this.emitClose();
        },
        emitClose: function() {
            this.$emit("closeme");
        },
        scrollUp: function() {
            let el = document.getElementById("mc-" + this.modalId);
            el.scrollTop = 0;

            // resetScrollPos()
        },
    },
};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 300px;
    height: 80vh;
    margin: 0px auto;
    padding: 4vh 3vw;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    /* font-family: Helvetica, Arial, sans-serif; */
    border-radius: 20px;
}

.modal-top-bar {
    text-align: right;
    font-weight: bold;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
<!-- 
<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div
                    class="modal-container"
                    :style="[containerDimensions, overflowStyle]"
                    :id="'mc-' + modalId"
                >
                    <div class="modal-top-bar">
                        <a @click="emitClose()">[close]</a>
                    </div>
                    <slot></slot>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: "modal",
    props: {
        width: {
            type: String,
            required: false,
            default: "65vw",
        },
        height: {
            type: String,
            required: false,
            default: "80vh",
        },
        noscroll: {
            type: String,
            required: false,
            default: "0",
        },
    },
    data() {
        return {
            modalId: 0,
        };
    },
    beforeMount: function() {
        this.modalId = btoa(Math.random()).substring(0, 12);
        console.log(this.modalId);

        document.addEventListener("keydown", this.modalKeys);
    },
    beforeDestroy: function() {
        document.removeEventListener("keydown", this.modalKeys);
    },
    computed: {
        containerDimensions: function() {
            return "width: " + this.width + "; height: " + this.height + ";";
        },
        overflowStyle() {
            if (this.noscroll == "1") return "overflow-y: hidden;";
            else return "";
        },
    },
    methods: {
        modalKeys: function(event) {
            if (event.key == "Escape") this.emitClose();
        },
        emitClose: function() {
            this.$emit("closeme");
        },
        scrollUp: function() {
            let el = document.getElementById("mc-" + this.modalId);
            el.scrollTop = 0;

            // resetScrollPos()
        },
    },
};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 300px;
    height: 80vh;
    overflow-y: scroll;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-top-bar {
    text-align: right;
    font-weight: bold;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>


-->
