<template>
    <section id="main">
        <div class="container main_container noHeaderPicSpace">
            <section id="content">
                <div id="vueContainer" v-cloak>
                    <h3>선생님 관리</h3>

                    <div class="shadowbox" style="margin-top: 2vh;">
                        <div class="table-div">
                            <div class="th">
                                <div>이름</div>
                                <div>이메리</div>
                                <div>전화번호</div>
                                <div></div>
                            </div>
                            <div v-for="(t, tindex) in tData" :key="tindex">
                                <div>
                                    <router-link
                                        :to="paths.teacherProfile + t.id"
                                    >
                                        {{ t.firstname }} {{ t.lastname }}
                                    </router-link>
                                </div>
                                <div>{{ t.email }}</div>
                                <div>{{ t.mobile }}</div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import axios from "axios";
export default {
    name: "TeacherList",
    data() {
        return {
            tData: [],

            paths: {
                teacherProfile: "/admin/coach/profile/",
            },
        };
    },
    created() {
        axios
            .get("/teacher/list/get")
            .then((res) => {
                this.tData = res.data.tData;
            })
            .catch((err) => console.log(err));
    },
};
</script>

<style></style>
