<template>
    <div class="foot" style="">
        <!-- <div id="spacer" style="margin-top: 30vh;"></div> -->

        <!-- style="justify-content: space-between; flex-direction: row; display: flex;" -->

        <div style="">
            <p
                class="font-family-primary weight500 gray900"
                style="font-size: 1.6em;"
            >
                주식회사 웰링비
            </p>
            <div style="margin-top: 3vh;">
                <span style="display: inline-block;">
                    <div>
                        <span class="gray500 pad_r_sm  weight300">
                            대표이사
                        </span>
                        <span class="gray700  weight500">
                            이문수, 이연하
                        </span>
                    </div>
                    <div style="margin-top: 1vh;">
                        <span class="gray500 pad_r_sm  weight300">
                            이메일
                        </span>
                        <span class="gray700  weight500">
                            wellingbe@naver.com
                        </span>
                    </div>
                </span>
                <span style="display: inline-block; margin-left: 3vw;">
                    <div>
                        <span class="gray500 pad_r_sm  weight300">
                            사업자등록번호
                        </span>
                        <span class="gray700  weight500">
                            433-86-02105
                        </span>
                    </div>
                    <div style="margin-top: 1vh;">
                        <span class="gray500 pad_r_sm weight300">
                            주소
                        </span>
                        <span class="gray700 weight500">
                            서울특별시 구로구 새말로 31 111동 202호
                        </span>
                    </div>
                </span>
            </div>
            <p style="margin-top: 3vh;" class="gray500 weight400">
                Copyright &copy; wellingbe Corp. All rights reserved
            </p>
        </div>

        <div>
            <div>
                <span>
                    <img class="sns_logo" src="@/assets/_img/insta.png" />
                </span>
                <span>
                    <img
                        src="@/assets/_img/naver.png"
                        style="margin-left: 1vw;"
                    />
                </span>
            </div>
            <div class="gray900 weight500" style="margin-top: 2vh;">
                개인정보처리방침
            </div>
            <div class="weight300" style="margin-top: 1vh; text-align: right;">
                이용약관
            </div>
            <div class="weight300" style="margin-top: 1vh; text-align: right;">
                고객센터
            </div>
        </div>
        <!-- // Scroll to top button  -->
        <div class="scrolltotop" :style="scrollTopStyle">
            <a class="button-circle" href="#"
                ><i class="fas fa-angle-double-up"></i>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {
            yPositionScroll: 0,
            showScrollTop: false,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
    },
    watch: {
        yPositionScroll: function(newVal) {
            if (newVal > 100) this.showScrollTop = true;
            else this.showScrollTop = false;
        },
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
        scrollTopStyle() {
            if (!this.showScrollTop) return "visibility: hidden;";
            else return "visibility: visible; opacity: 1; margin: 2vh 2vw;";
        },
    },
    methods: {
        onScroll() {
            this.yPositionScroll = window.pageYOffset;
        },
    },
};
</script>

<style scoped>
.sns_logo {
    width: 40;
    height: 40;
    margin-left: 2vw;
}
.foot {
    display: flex;
    justify-content: space-between;
    padding: 5vh 10vw;
    height: 25vh;
}

.scrolltotop {
    position: fixed;
    right: 20px;
    bottom: 20px;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    z-index: 992;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: ease-out 0.12s;
    transition: ease-out 0.12s;
}

.scrolltotop.scrolltotop-show {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
}
.button-circle {
    display: inline-block;
    border-radius: 50%;
    color: black;
    text-align: center;
    -webkit-transition: ease-out 0.12s;
    transition: ease-out 0.12s;
    width: 45px;
    height: 45px;
    line-height: 45px;
    background: transparent;
    border: 1px solid #cccccc;
}
*.button-circle {
    color: black;
}

.button-circle:hover,
a.button-circle:hover {
    color: #7c7c7c;
}
</style>
