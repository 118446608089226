<template>
    <div
        class="bg_white row"
        style="padding: 4vh 2vw; justify-content: center;"
    >
        <span style="max-Width: 60%; margin-top: 4vh;">
            <div>
                <p class="main font18">
                    <b>12주 과정</b>
                </p>
                <p class="gray900 font48"><b>AWH</b> 웰니스 온라인 교육</p>
            </div>
            <div>
                <img
                    src="@/assets/_img/program_details_1.png"
                    style="width: 100%; height: auto;"
                />
            </div>
            <img
                src="@/assets/_img/program_details_2.png"
                style="width: 100%; height: auto;"
            />

            <div
                class="bg_gray300 padding_md"
                style="width: 100%; margin: 4vh 0;"
            >
                <span
                    v-for="(tab, i) in tabs"
                    :key="i"
                    :class="tabSelectorClass(tab)"
                    @click="selectedTab = tab"
                >
                    {{ tab }}</span
                >
            </div>

            <div class="spacer_lg"></div>
            <div v-show="selectedTab == '교육소개'" style="width: 100%;">
                <div>
                    <p class="gray900 font24 weight500">교육소개</p>
                    <div class="spacer_lg"></div>
                    <p>
                        2012년 NAAPIMHA라는 단체에서 만든 프로그램으로 하버드대
                        의대 정신건강학과 Ed Wang 교수와 미 보건복지부 지원으로
                        만들어진 교육입니다.
                    </p>
                </div>
                <div class="spacer_lg"></div>
                <div>
                    <p class="gray700 font20">
                        <b>AWH (Achieving Whole Health)</b>
                    </p>
                    <div class="spacer_lg"></div>
                    <p>
                        몸, 마음, 정신의 조화를 통해 사람들에게 건강한 삶을 살
                        수 있는 방법을 알려주고 ‘나만의 8주 건강목표 달성하기’를
                        통해 건강한 습관을 만들기를 도와주는 교육
                        프로그램입니다.
                    </p>
                </div>
                <div class="spacer_lg"></div>
                <div class="bg_gray300" style="padding: 3vh 2vw;">
                    <div
                        class="row"
                        style="align-items: center; flex-wrap: no-wrap;"
                    >
                        <div
                            class="bg_gray900"
                            style="border-radius: 50%;  padding: 1vh; margin-right: 1vw; color: #fff;"
                        >
                            1
                        </div>
                        <div>
                            건강한 삶을 위해 몸, 마음, 정신을 함께 관리하는
                            방법을 함께 배워보세요.
                        </div>
                    </div>
                    <div class="spacer_md"></div>
                    <div
                        class="row"
                        style="align-items: center; flex-wrap: no-wrap;"
                    >
                        <span
                            class="bg_gray900"
                            style="border-radius: 50%;  padding: 1vh; margin-right: 1vw; color: #fff;"
                            >2</span
                        >
                        <span
                            >1회성 교육이 아닌 3개월동안 달성할 목표를 세우고
                            달성해서 건강한 삶의 습관을 함께 만들어가요.
                        </span>
                    </div>
                    <div class="spacer_md"></div>
                    <div
                        class="row"
                        style="align-items: center; flex-wrap: no-wrap;"
                    >
                        <span
                            class="bg_gray900"
                            style="border-radius: 50%;  padding: 1vh; margin-right: 1vw; color: #fff;"
                            >3</span
                        >
                        <span
                            >스마트밴드를 통해 나의 건강데이터를 바탕으로
                            스마트한 헬스케어 서비스까지 누려보세요.
                        </span>
                    </div>
                    <div class="spacer_md"></div>
                    <div
                        class="row"
                        style="align-items: center; flex-wrap: no-wrap;"
                    >
                        <span
                            class="bg_gray900"
                            style="border-radius: 50%;  padding: 1vh; margin-right: 1vw; color: #fff;"
                            >4</span
                        >
                        <span
                            >나뿐만 아니라 타인의 삶에도 긍정적인 변화를 일으킬
                            수 있는 방법을 알려줍니다.
                        </span>
                    </div>
                </div>
                <div class="spacer_lg"></div>
                <hr />
                <div class="spacer_lg"></div>
                <div class="weight900 font24">
                    웰니스의 3가지 요소
                    <div class="spacer_md"></div>
                    <p class="gray800 font18">
                        몸, 마음, 정신의 3가지 영역과 10가지 건강 요소, 삶의
                        회복력에 중점을 둔 교육입니다.
                    </p>
                </div>
                <div class="spacer_lg"></div>
                <div>
                    <img src="@/assets/_img/program_details_3.png" />
                </div>
                <div class="spacer_lg"></div>
                <hr />
                <div class="spacer_lg"></div>
                <div style="padding-bottom: 5vh;">
                    <p class="weight500 font24">
                        단순한 웰니스 교육이 아닙니다.
                    </p>
                    <div class="spacer_md"></div>
                    <ul>
                        <li>
                            50,000명이상의 수료한 교육으로 참가자들의 반응은
                            4.5점(5점 만점)으로 좋은피드백을 받았습니다.
                        </li>
                        <li>
                            참가자들은 교육을 통해 자신과 각자를 이해하는 데
                            도움이 되었고, AANHPI를 효과적으로 사용하여 삶의
                            좋은 습관으로 만드는 데 도움이 되었다고 말합니다.
                        </li>
                        <li>
                            현재 교육 참여자 중에서 500명 이상의 웰니스 코치로
                            활동을 하며 웰니스교육을 진행하고 있습니다.
                        </li>
                    </ul>
                </div>
            </div>
            <div v-show="selectedTab == '커리큘럼'" style="width: 100%;">
                <div style="align-self: flex-start; display: inline-block;">
                    <p class="gray900 font24 weight500">
                        웰니스 온라인 교육
                    </p>
                    <div class="spacer_lg"></div>
                    <p>웰니스 교육은 총 12주 과정입니다.</p>
                </div>
                <div class="spacer_lg"></div>

                <div class="bg_gray300 padding_md">
                    <span
                        class="bg_gray900"
                        style="border-radius: 50%;  padding: 1vh 1vw; margin-right: 1vw; color: #fff;"
                        >1</span
                    >
                    <span class="gray900 weight500 font20"
                        >온라인 그룹 교육</span
                    >
                    <span
                        class="gray600 bg_white"
                        style="padding: .4vh 1vw; border: 1px solid #CCCCCC; border-radius: 100px; margin-left: 1vw;"
                        >6시간</span
                    >
                    <div class="spacer_md"></div>
                    <table>
                        <tr>
                            <td width="50%">
                                <span class="gray900 weight500 font18"
                                    >1주</span
                                >
                                <span class="spacer_h_sm_l gray400 weight 400"
                                    >(2시간)</span
                                >
                            </td>
                            <td>
                                <ul>
                                    <li>건강한 삶의 질</li>
                                    <li>건강한 식습관 3단계</li>
                                    <li>편안한 수면</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="gray900 weight500 font18"
                                    >2주</span
                                >
                                <span class="spacer_h_sm_l gray400 weight 400"
                                    >(2시간)</span
                                >
                            </td>
                            <td>
                                <ul>
                                    <li>안정을 취하는 법</li>
                                    <li>낙관주의와 희망</li>
                                    <li>긍정적인 자세</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="gray900 weight500 font18"
                                    >3주</span
                                >
                                <span class="spacer_h_sm_l gray400 weight 400"
                                    >(2시간)</span
                                >
                            </td>

                            <td>
                                <ul>
                                    <li>영적 믿음과 행위</li>
                                    <li>지지적 관계</li>
                                    <li>삶의 의미와 목적</li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="spacer_lg"></div>
                <!-- table2 -->

                <div class="bg_gray300 padding_md">
                    <span
                        class="bg_gray900"
                        style="border-radius: 50%;  padding: 1vh 1vw; margin-right: 1vw; color: #fff;"
                        >2</span
                    >
                    <span class="gray900 weight500 font20"
                        >달성 목표 세우기</span
                    >
                    <span
                        class="gray600 bg_white"
                        style="padding: .4vh 1vw; border: 1px solid #CCCCCC; border-radius: 100px; margin-left: 1vw;"
                        >2시간</span
                    >
                    <div class="spacer_md"></div>
                    <table>
                        <tr>
                            <td width="50%">
                                <span class="gray900 weight500 font18"
                                    >1단계</span
                                >
                            </td>
                            <td>
                                <ul>
                                    <li>자가 건강 진단표 작성</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="gray900 weight500 font18"
                                    >2단계</span
                                >
                            </td>
                            <td>
                                <ul>
                                    <li>
                                        IMPACT에 따른 건강 달성 목표 정하기
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="gray900 weight500 font18"
                                    >3단계</span
                                >
                            </td>

                            <td>
                                <ul>
                                    <li>8주 건강 달성 목표 세우기</li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="spacer_lg"></div>
                <!-- table3 -->

                <div class="bg_gray300 padding_md">
                    <span
                        class="bg_gray900"
                        style="border-radius: 50%;  padding: 1vh 1vw; margin-right: 1vw; color: #fff;"
                        >3</span
                    >
                    <span class="gray900 weight500 font20"
                        >건강한 습관 만들기</span
                    >
                    <span
                        class="gray600 bg_white"
                        style="padding: .4vh 1vw; border: 1px solid #CCCCCC; border-radius: 100px; margin-left: 1vw;"
                        >8주</span
                    >
                    <div class="spacer_md"></div>
                    <table>
                        <tr>
                            <td width="50%">
                                <span class="gray900 weight500 font18"
                                    >1단계</span
                                >
                            </td>
                            <td>
                                <ul>
                                    <li>
                                        8주간 본인이 설정한 건강 달성 목표에
                                        실천하기
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="gray900 weight500 font18"
                                    >2단계</span
                                >
                            </td>
                            <td>
                                <ul>
                                    <li>
                                        스마트밴드로 바이오 리듬을 측정하고
                                        스트레스·운동·수면관리 하기
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="gray900 weight500 font18"
                                    >3단계</span
                                >
                            </td>

                            <td>
                                <ul>
                                    <li>교육 전·후 리포트 받기</li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div v-show="selectedTab == '교육키트'" style="width: 100%;">
                <div>
                    <p class="gray900 font24 weight500">교육키트</p>
                    <div class="spacer_lg"></div>
                    <p>
                        교육 진행에 필요한 교육키트를 사전에 보내드립니다.<br />
                        (교육키트는 별도 비용이 발생하지 않으며, 교육비에
                        포함되어 있습니다.)
                    </p>
                </div>
                <hr class="spacer_lg" />
                <div>
                    <p class="gray900 font24 weight500">DOFIT 스마트밴드</p>
                    <div class="spacer_lg"></div>
                    <p>
                        스마트밴드로 측정한 신체 데이터를 바탕으로 목표를
                        실천하도록 독려하고, 측정 가능한 지표를 통해 건강을
                        개선할 수 있도록 도와줍니다. )
                    </p>
                    <div class="spacer_lg"></div>
                    <div
                        class="bg_gray300"
                        style="width: 100%; text-align: center; padding: 5vh 0;"
                    >
                        <img
                            src="@/assets/_img/program_details_4.png"
                            style="width: 280px; height: 320px;"
                        />
                        <div class="gray900 weight800 font24">
                            87,000원 상당의<br />Do Fit 스마트 밴드
                        </div>
                    </div>
                </div>
                <div class="spacer_lg"></div>
                <div style="display: flex; flex-direction: row; ">
                    <span class="blackTag">
                        걸음수 측정
                    </span>
                    <div class="font18">
                        서두르는 출퇴근길, 가벼운 산책, 격한 런닝까지~ DOFIT은
                        걸음, 빠른걸음, 달리기 등의 다양한 걷기 형태를 모두
                        구분하여 측정합니다.
                    </div>
                </div>
                <div class="spacer_lg"></div>
                <div style="display: flex; flex-direction: row;">
                    <span class="blackTag">
                        소모 칼로리 계산
                    </span>
                    <div class="font18">
                        사용자의 활동량 및 걷기형태 등을 분석해 정확한 소모
                        칼로리를 구분 합니다. 소모 칼로리는 자정마다 초기화
                        되며, 앱과 동기화 되기 전까지 안전하게 저장됩니다.
                    </div>
                </div>
                <div class="spacer_lg"></div>
                <div style="display: flex; flex-direction: row;">
                    <span class="blackTag">
                        심박수 측정
                    </span>
                    <div class="font18">
                        사용자가 아무 신경쓰지 않아도 DOFIT은 규칙적으로 심박을
                        측정하고 저장합니다. 이렇게 측정된 심박은 칼로리 계산,
                        수면 측정 등 여러 상황에서 활용됩니다.
                    </div>
                </div>
                <div class="spacer_lg"></div>
                <div style="display: flex; flex-direction: row;">
                    <span class="blackTag">
                        스트레스 측정
                    </span>
                    <div class="font18">
                        10분에 한번씩 사용자의 HRV(심박변이도)를 통해 스트레스
                        상태를 구분하여 기록합니다. 알게 모르게 쌓이고 풀리는
                        스트레스! DOFIT을 통해 확인해 보세요!
                    </div>
                </div>
                <div class="spacer_lg"></div>
                <div style="display: flex; flex-direction: row;">
                    <span class="blackTag">
                        수면 측정
                    </span>
                    <div class="font18">
                        밴드만 차고 생활하세요. 1시간 이상의 수면이라면 언제
                        자고, 언제 일어나는지 부터, 잘 잤는지, 못 잤는지 까지
                        모두 체크해 드립니다.
                    </div>
                </div>
                <div class="spacer_lg"></div>
                <div style="display: flex; flex-direction: row;">
                    <span class="blackTag">
                        전화·SMS·카카오톡 알림
                    </span>
                    <div class="font18">
                        스마트폰에 걸려오는 전화, 시시때때로 들어오는 SMS와
                        카카오톡 메시지, 어느 것 하나 놓치지 않고 DOFIT이
                        알려드립니다.
                    </div>
                </div>

                <hr class="spacer_lg" />
                <div>
                    <p class="gray900 font24 weight500">
                        세컨드 윈드 앱 무료 이용권
                    </p>
                    <div class="spacer_lg"></div>
                    <p class="font18">
                        '세컨드 윈드'는 사용자의 건강한 삶을 위한 개인 맞춤형
                        전문 건강관리 솔루션입니다. 국내의 최고의 의료진들과
                        건강관리 임상 전문가들이 함께 개발한 솔루션으로 영양,
                        운동관리, 복약/금연 관리, 질환별 교육등의 기능을 갖춘
                        헬스케어 서비스입니다.
                    </p>
                </div>
                <div class="spacer_lg"></div>
                <div style="padding-bottom: 3vh;">
                    <div style="display: flex; flex-direction: row;">
                        <div
                            class="bg_gray300"
                            style="width: 45%; text-align: center;"
                        >
                            <img
                                src="@/assets/_img/program_details_5.png"
                                style="width: 320px; height: 351px;"
                            />
                        </div>
                        <div
                            class="bg_gray300"
                            style="width: 45%; margin-left: 1vw; text-align: center;"
                        >
                            <img
                                src="@/assets/_img/program_details_6.png"
                                style="width: 320px; height: 351px;"
                            />
                        </div>
                    </div>
                    <div class="spacer_md"></div>
                    <div class="row">
                        <div
                            class="bg_gray300"
                            style="width: 45%; text-align: center;"
                        >
                            <img
                                src="@/assets/_img/program_details_7.png"
                                style="width: 320px; height: 351px;"
                            />
                        </div>
                        <div
                            class="bg_gray300"
                            style="width: 45%; margin-left: 1vw; text-align: center;"
                        >
                            <img
                                src="@/assets/_img/program_details_8.png"
                                style="width: 320px; height: 351px;"
                            />
                        </div>
                    </div>
                </div>
                <hr class="spacer_lg" />
                <div>
                    <p class="gray900 font24 weight500">교육 준비물</p>
                    <div class="spacer_lg"></div>
                    <p>
                        온라인 그룹 교육시 필요한 준비물을 보내드립니다. 1, 2,
                        3주차별로<br />
                        교육 진행 시 함께 챙겨 주세요.
                    </p>
                </div>
            </div>
            <div v-show="selectedTab == '환불정책'" style="width: 100%;">
                <div>
                    <p class="gray900 font24 weight500">환불 정책</p>
                    <div class="spacer_lg"></div>
                    <p class="weight500 font18">
                        <span style="color: #F24949;"
                            >수강 시작으로부터 7일 이내 환불</span
                        >하셔야 위약금없이 환불이 가능합니다.
                    </p>
                    <p>
                        <a>전체 환불 정책 보기</a>
                    </p>
                </div>
            </div>
        </span>
        <!-- right bar -->
        <span
            style=" position: sticky;
            background-color: #E5F9EA; top: 6vh; height: 35%;
            margin-left: 1vw; border-radius: 20px; padding: 2vh 2vw; width: 25%;"
        >
            <p class="main weight500 font20">
                AWH 웰니스 교육 패키지
            </p>
            <div
                style="padding: 1vh 1vw; width: 100%; border-radius: 10px; margin-top: 1vh;"
                class="weight500 font16 bg_white"
            >
                ️12주 과정 온라인 강의
            </div>
            <div
                style="padding: 1vh 1vw; width: 100%; border-radius: 10px;margin-top: .5vh; "
                class="weight500 font16 bg_white "
            >
                87,000원 상당의 스마트밴드 증정
            </div>
            <div style="text-align: right;">
                <p style="text-decoration: line-through;">
                    200,000원
                </p>
                <div
                    class="gray400 font40"
                    style="display: flex; align-items: center; flex-direction: row; justify-content: flex-end; margin-top: -1vh;"
                >
                    <div
                        class="bg_sysRed weight500 font13"
                        style="padding: .5vh .5vw; color: #fff; border-radius: 4px;"
                    >
                        25% 할인
                    </div>
                    <div>
                        <span class="font40 weight800 sysRed"> 150,000</span
                        ><span class="font16 gray900 weight400">원</span>
                    </div>
                </div>
            </div>
            <div class="spacer_md"></div>
            <router-link
                to="/payment"
                class="bg_green"
                style="color: #fff; padding: 1.5vh 0; display: block; text-align: center; border-radius: 4px;"
            >
                구매하기 <i class="fas fa-arrow-right"></i>
            </router-link>
            <div class="spacer_sm"></div>
            <div
                style="border: 2px solid #2DB24E; padding: 1.5vh 0; display: block; text-align: center; border-radius: 4px;"
                class="rounded_md main bg_white"
            >
                문의하기
                <i class="fas fa-comment-dots"></i>
            </div>
        </span>
        <!-- </div> -->
    </div>
</template>

<script>
export default {
    name: "ProgramDetails",
    data() {
        return {
            selectedTab: "교육소개",
            tabs: ["교육소개", "커리큘럼", "교육키트", "환불정책"],
        };
    },
    computed: {},
    methods: {
        tabSelectorClass(tab) {
            if (this.selectedTab == tab)
                return "gray900 weight500 selectedTab spacer_h_md_r pad_md";
            else return "gray301 spacer_h_md_r pad_md notSelectedTab";
        },
    },
};
</script>

<style scoped>
table {
    padding: 2vh 2vw;
    font-weight: 300;
    font-size: 15;
    color: #333333;
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    text-align: center;
}
td {
    padding: 1vh 5vw;
    border: 1px solid #f7f7f7;
}
ul {
    text-align: left;
}
.blackTag {
    background-color: #1a1a1a;
    white-space: nowrap;
    border-radius: 100px;
    padding: 0.8vh 1vw;
    color: #fff;
    margin-right: 1vw;
    font-weight: 500;
    font-size: 16px;
    display: inline-block;
    align-items: flex-start;
    align-content: flex-start;
    text-align: center;
}
</style>
