import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
import firebase from "firebase";

import LandingHome from "../views/z_Home.vue";
import Login from "../views/AuthLogin.vue";
import Register from "../views/AuthRegister.vue";

import LandingAbout from "../views/z_About.vue";
import AccessDenied from "../views/z_AccessDenied";
import ContractEula from "../views/z_ContractEula.vue";
import ContractPrivacy from "../views/z_ContractPrivacy.vue";
// import Depression from '../views/z_Depression.vue'
import Selfdiagnosis from "../views/z_Selfdiagnosis.vue";
import HealthInfo from "../views/z_HealthInfo.vue";
import Program from "../views/z_Program.vue";
import ProgramDetails from "../views/z_ProgramDetails.vue";
import Payment from "../views/z_Payment.vue";

import ProfileUser from "../views/ProfileUser.vue";

import HomeClient from "../views/HomeClient.vue";
import Docs from "../views/Docs.vue";
import ClassListClients from "../views/ClassListClients.vue";

import HomeCoach from "../views/HomeCoach.vue";
import StudentList from "../views/StudentList.vue";
import ClassListCoaches from "../views/ClassListCoaches.vue";

import ProfileClient from "../views/ProfileClient.vue";
import ProfileCoach from "../views/ProfileCoach.vue";
import ClassViewAttendance from "../views/ClassViewAttendance.vue";
import ClassViewSessions from "../views/ClassViewSessions.vue";
import ClassViewSetAttendance from "../views/ClassViewSetAttendance.vue";
import ClassViewStudents from "../views/ClassViewStudents.vue";
import ClassViewTeachers from "../views/ClassViewTeachers.vue";
import ClassListAdmin from "../views/ClassListAdmin.vue";
import TeacherList from "../views/TeacherList.vue";
import Search from "../views/Search";

Vue.use(VueRouter);

const routes = [
    { path: "/", component: LandingHome },
    { path: "/login", component: Login },
    { path: "/register/:mode", component: Register },

    { path: "/about", component: LandingAbout },
    { path: "/access_denied", component: AccessDenied },
    { path: "/contract/eula", component: ContractEula },
    { path: "/contract/privacy", component: ContractPrivacy },
    // { path: "/depression", component: Depression },
    { path: "/selfdiagnosis", component: Selfdiagnosis },
    { path: "/healthinfo", component: HealthInfo },
    { path: "/program", component: Program },
    { path: "/programDetails", component: ProgramDetails },
    { path: "/payment", component: Payment },

    //////////////////AUTH
    { path: "/profile", component: ProfileUser, meta: { requiresAuth: true } },
    {
        path: "/auth/home",
        redirect: (to) => {
            console.log("redirecting to: ", to);
            return store.getters.GET_PATH + "/home";
        },
    },
    ///////////// CLIENT
    {
        path: "/client/home",
        component: HomeClient,
        meta: {
            authLevel: store.state.lvl.client,
            requiresAuth: true,
        },
    },
    {
        path: "/client/course/list",
        component: ClassListClients,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.client,
        },
    },
    {
        path: "/docs",
        component: Docs,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.client,
        },
    },
    ////////////////////// TEACHER
    {
        path: "/coach/home",
        component: HomeCoach,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.coach,
        },
    },
    {
        path: "/coach/client/profile/:user_id",
        component: ProfileClient,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.coach,
        },
    },
    {
        path: "/coach/studentlist",
        component: StudentList,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.coach,
        },
    },

    {
        path: "/coach/course/list",
        component: ClassListCoaches,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.coach,
        },
    },
    {
        path: "/coach/course/:cid",
        component: ClassViewStudents,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.coach,
        },
    },
    {
        path: "/coach/course/attendance/:cid",
        component: ClassViewAttendance,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.coach,
        },
    },
    {
        path: "/coach/course/attendance/set/:sid",
        component: ClassViewSetAttendance,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.coach,
        },
    },
    {
        path: "/coach/course/sessions/:cid",
        component: ClassViewSessions,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.coach,
        },
    },

    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }

    {
        path: "/admin/coach/profile/:tid",
        component: ProfileCoach,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.admin,
        },
    },
    {
        path: "/admin/course/attendance/:cid",
        component: ClassViewAttendance,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.admin,
        },
    },
    { path: "/admin/home", redirect: "/admin/students/list/ai" },
    {
        path: "/admin/course/sessions/:cid",
        component: ClassViewSessions,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.admin,
        },
    },
    {
        path: "/admin/course/attendance/set/:sid",
        component: ClassViewSetAttendance,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.admin,
        },
    },
    {
        path: "/admin/course/:cid",
        component: ClassViewStudents,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.admin,
        },
    },
    {
        path: "/admin/course/teachers/:cid",
        component: ClassViewTeachers,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.admin,
        },
    },
    {
        path: "/admin/client/profile/:user_id",
        component: ProfileClient,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.admin,
        },
    },
    {
        path: "/admin/students/list/:in_class",
        component: StudentList,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.admin,
        },
    },
    {
        path: "/admin/teacher/list",
        component: TeacherList,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.admin,
        },
    },
    {
        path: "/admin/course/list/:mode",
        component: ClassListAdmin,
        name: ClassListAdmin,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.admin,
        },
    },
    {
        path: "/admin/search/simple",
        component: Search,
        meta: {
            requiresAuth: true,
            authLevel: store.state.lvl.admin,
        },
    },
];

const router = new VueRouter({
    // mode: "history",
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // requiredAuth guard set
        if (!firebase.auth().currentUser) {
            //not logged in -> redirect to login
            next({
                path: "/login",
                query: {
                    redirect: to.fullPath,
                },
            });
        } else {
            if (!to.meta.authLevel) {
                next();
            }

            // logged in -> proceed to route/redirect
            else if (to.meta.authLevel == store.state.currentUser.level) {
                next();
            } else {
                // 	// let tmp = JSON.stringify(to);
                next("/access_denied");
            }
        }
    } else {
        // requiredAuth guard NOT set -> proceed
        next();
    }
});

export default router;
