<template>
    <section id="main">
        <div class="container main_container noHeaderPicSpace">
            <!-- body -->
            <div class="row gtr-200">
                <!-- left -->
                <div class="col-3 col-12-medium">
                    <section id="sidebar">
                        Account Info
                    </section>
                </div>
                <!-- right -->
                <div class="col-9 col-12-medium imp-medium">
                    <section id="content">
                        <div id="vueContainer">
                            <modal
                                v-if="edit.show"
                                @closeme="edit.show = false"
                            >
                                <h3>Change User Information</h3>
                                <div class="shadowbox">
                                    <div class="table-div">
                                        <div>
                                            <div>Firstname</div>
                                            <div>
                                                <input
                                                    type="text"
                                                    v-model="edit.firstname"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div>Lastname</div>
                                            <div>
                                                <input
                                                    type="text"
                                                    v-model="edit.lastname"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div>Mobile</div>
                                            <div>
                                                <input
                                                    type="number"
                                                    v-model="edit.mobile"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        @click="submitEdit"
                                        style="margin-top: 2vh;"
                                        class="button"
                                    >
                                        Update
                                    </button>
                                </div>
                            </modal>
                            <!-- <modal v-if="pw.show == true" @closeme="pw.show = false">
                            <h3>Change Password</h3>
                            <div class="shadowbox">
                                <div class="table-div">
                                    <div>
                                        <div>New Password</div>
                                        <div><input type="password" v-model="pw.new" /></div>
                                        <div>{{ pwNewStatus }}</div>
                                    </div>
                                    <div>
                                        <div>Confirm New Password</div>
                                        <div><input type="password" v-model="pw.confirm" /></div>
                                        <div>{{ pwConfirmStatus }}</div>
                                    </div>
                                </div>
                            </div>
                            <button v-if="pwCanSubmit"
                                @click="updatePassword"
                                class="button primary">Submit</button>
                            <button v-else disabled>Submit</button>
                        </modal> -->

                            <!-- <modal v-if="email.show == true" @closeme="email.show = false">
                            <h3>Change Email</h3>
                            <div class="shadowbox">
                                <div class="table-div">
                                    <div>
                                        <div>New Email</div>
                                        <div>
                                            <input type="text"
                                                v-model="email.new"
                                                @blur="checkUnique()" />
                                        </div>
                                        <div>{{emailNewStatus}} </div>
                                    </div>
                                </div>
                            </div>
                            <button v-if="emailCanSubmit"
                                @click="updateEmail"
                                class="button primary">Submit</button>
                            <button v-else disabled>Submit</button>
                        </modal> -->

                            <header class="header">
                                <h2>내 정보</h2>
                            </header>
                            <div class="shadowbox">
                                <div class="table-div">
                                    <div>
                                        <div>Name</div>
                                        <div>
                                            {{ userData.firstname }}
                                            {{ userData.lastname }}
                                        </div>
                                        <div></div>
                                    </div>
                                    <div>
                                        <div>Email</div>
                                        <div>{{ userData.email }}</div>
                                        <!-- <div><a @click="openEmailModal">change</a></div> -->
                                    </div>
                                    <div>
                                        <div>Mobile</div>
                                        <div>{{ userData.mobile }}</div>
                                        <div></div>
                                    </div>
                                    <div>
                                        <div>Member since</div>
                                        <div>
                                            {{ showDate(userData.joined) }}
                                        </div>
                                        <div></div>
                                    </div>
                                    <!-- <div>
                                    <div>Password</div>
                                    <div>******</div>
                                    <div><a @click="openPasswordModal">change</a></div>
                                </div> -->
                                    <div>
                                        <div>Password</div>
                                        <div>******</div>
                                        <div>
                                            <a @click="resetPW">reset</a>
                                        </div>
                                    </div>
                                    <button
                                        @click="loadEditModal"
                                        class="button"
                                        style="margin-top: 2vh;"
                                    >
                                        Edit My Information
                                    </button>
                                </div>
                            </div>
                            <!-- shadowbox -->
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import modal from "@/components/Modal";
import moment from "moment";
import mxNotify from "@/components/mx-notify.js";
import Validate from "@/components/Validate.js";
export default {
    name: "ProfileUser",
    mixins: [Validate, mxNotify],
    components: {
        modal,
    },
    data() {
        return {
            userData: [],
            paths: {
                mypage: "/profile/",
            },
            edit: {
                show: false,
                firstname: "",
                lastname: "",
                mobile: "",
            },
        };
    },
    created() {
        this.userData = this.$store.state.currentUser;
    },
    computed: {
        canUpdate() {
            if (this.edit.firstname.length > 2 && this.edit.lastname.length > 2)
                return true;
            else return false;
        },
    },
    methods: {
        async resetPW() {
            try {
                let res = await this.$store.dispatch("RESET_PW");
                if (res == "success")
                    alert(
                        "Please check your Email and follow the instructions to reset your password!"
                    );
                else
                    "Something went wrong. Please try again later. \n If you keep getting an error please contact us!";
            } catch (err) {
                console.log(err);
                this.ntfError(
                    "Oops. Something went wrong. Please try again later!",
                    true
                );
            }
        },
        loadEditModal() {
            this.edit.firstname = this.userData.firstname;
            this.edit.lastname = this.userData.lastname;
            this.edit.mobile = this.userData.mobile;
            this.edit.show = true;
        },
        submitEdit() {
            if (!this.canUpdate) {
                alert("Please enter valid names with >2 characters");
                return false;
            }
            this.ntfInfo("Submitting Data");
            let pl = { ...this.edit };
            delete pl["show"];
            axios
                .get("/user/update", { params: pl })
                .then(async (res) => {
                    this.edit.show = false;
                    this.userData = res.data;
                    this.$store.dispatch("SET_USER", res.data);
                    this.ntfSuccess("Updated Data", true);
                })
                .catch((err) => {
                    console.log(err);
                    this.ntfError(
                        "Oops. Something went wrong. Please try again later!",
                        true
                    );
                });
        },
        showDate(string) {
            if (string == null) return "";
            else return moment(string).format("YYYY-MM-DD HH:mm");
        },
    },
};
</script>

<style scoped>
/* .header {
		margin-top: 14vh;
	} */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
