<template>
    <section id="main">
        <div class="container main_container noHeaderPicSpace">
            <section id="content">
                <div id="vueContainer" v-cloak>
                    <h3>{{ listTitle }}</h3>

                    <small>
                        <div style="float: left; width: 33%;">
                            cnf F-교육 확정/강의 예정<br />
                            cnf P-교육 확정/강의 종료
                        </div>
                        <div style="float: left; width: 33%;">
                            int F-교육 신청/강의 예정<br />
                            int P-교육 신청/강의 종료
                        </div>
                        <div style="float: left; width: 33%;">
                            <i class="fa fa-file-medical"></i> ‘자기 평가표’
                            완료<br />
                            <i class="fa fa-file-signature"></i> ‘AWH달성목표’
                            완료
                        </div>
                        <div style="clear: both;"></div>
                    </small>

                    <div class="shadowbox" style="margin-top: 2vh;">
                        <div class="table-div">
                            <div class="th">
                                <div>이름</div>
                                <div>이메일</div>
                                <div>cnf F</div>
                                <div>cnf P</div>
                                <div>int F</div>
                                <div>int P</div>
                                <div></div>
                            </div>
                            <div v-for="(s, sindex) in sData" :key="sindex">
                                <div>
                                    <a :href="paths.profile + s.id"
                                        >{{ s.firstname }} {{ s.lastname }}</a
                                    >
                                </div>
                                <div>{{ s.email }}</div>
                                <div class="center">
                                    {{ s.future_course_cnf_count }}
                                </div>
                                <div class="center">
                                    {{ s.past_course_cnf_count }}
                                </div>
                                <div class="center">
                                    {{ s.future_course_int_count }}
                                </div>
                                <div class="center">
                                    {{ s.past_course_int_count }}
                                </div>
                                <div>
                                    <i
                                        v-if="s.sac_count > 0"
                                        class="fa fa-file-medical"
                                        title="self assessment done"
                                    ></i>
                                    <i
                                        v-if="s.goal_count > 0"
                                        class="fa fa-file-signature"
                                        title="goals filled in"
                                    ></i>
                                </div>
                            </div>
                        </div>

                        <div style="margin-top: 2vh;"></div>
                        <endless
                            ref="endlessLoader"
                            mainprop="student_data"
                            :additional-payload="endlessPayload"
                            :loadpath="paths.loadmore"
                            :perpage="parseInt(perpage)"
                            @loaded="appendLoaded"
                        ></endless>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import endless from "@/components/endless";
import mixinOutputHelpers from "@/components/mixin-output-helpers.js";
export default {
    name: "StudentList",
    mixins: [mixinOutputHelpers],
    components: {
        endless,
    },
    data() {
        return {
            sData: [],
            inClass: null,
            perpage: "",

            paths: {
                profile: "/admin/client/profile/",
                loadmore: "/student/loadmore", // use computed value!
            },
        };
    },
    created() {
        if (this.$route.params.in_class)
            this.inClass = this.$route.params.in_class;

        axios
            .get("/student/list", { params: { inClass: this.inClass } })
            .then((res) => {
                this.sData = res.data.sData;
                this.perpage = res.data.perpage;
                console.log(res);
            })
            .catch((err) => console.log(err));
    },
    computed: {
        listTitle() {
            if (this.inClass == "o") return "모든 학생";
            else if (this.inClass == "a") return "예정된 수업 학생";
            else if (this.inClass == "ai") return "수업 신청한 학생";
            else if (this.inClass == "p") return "종료된 수업 학생";
            else if (this.inClass == "n") return "미확정된 수업 학생";
            else return "학생";
        },
        endlessPayload() {
            return {
                inClass: this.inClass,
            };
        },
    },
    watch: {},
    methods: {
        appendLoaded: function(loadedData) {
            this.sData.push.apply(this.sData, loadedData.student_data);
        },
        resetEndless: function() {
            if (this.$refs.endless != undefined) this.$refs.endless.reset();
        },
    },
};
</script>

<style></style>
