<template>
    <section id="main">
        <div class="container main_container noHeaderPicSpace">
            <section id="content">
                <div id="vueContainer" v-cloak>
                    <h3>웰니스 교육 신청하기</h3>

                    <ul>
                        <li>
                            원하는 선생님의 수업 날짜를 확인하고 ‘신청하기’를
                            클릭해 주세요.
                        </li>
                        <li>선생님이 확인하면 ‘확정‘ 으로 변경됩니다.</li>
                        <li>
                            수업 날짜를 변경하고 싶으면 ‘취소＇를 누르세요.
                            원하는 날짜를 다시 신청해 주세요.
                        </li>
                    </ul>

                    <div style="margin-top: 4vh;"></div>

                    <div style="width: 99%;">
                        <div
                            v-for="(c, cindex) in classDataAug"
                            class="shadowbox"
                            style="margin-bottom: 1vh;"
                            :key="cindex"
                        >
                            <div
                                style="height: 99%; float: left; width: 40%; flex-flow: column nowrap; justify-content: center;"
                            >
                                <div>
                                    <Classcode
                                        :code="c.code"
                                        :color="c.color"
                                    ></Classcode>
                                </div>
                                <div style="font-size: 1.6em;">
                                    {{ c.title }}
                                </div>
                            </div>
                            <div
                                style="float: left; width: 40%;"
                                class="center"
                            >
                                수업날짜
                                <span v-if="c.sessions != undefined">
                                    ({{ c.sessions.length }})
                                </span>
                                <div
                                    v-for="(s, idx) in c.sessions"
                                    style="margin-left: 1vw; margin-bottom: 0.5vh;"
                                    :key="idx"
                                >
                                    {{ showDate(s.start) }}
                                </div>
                            </div>
                            <div style="float: left; width: 20%;">
                                <span v-if="c.confirmed">
                                    [교육 확정]
                                    <br />
                                    <button
                                        @click="cancelSignup(c.id)"
                                        class="button"
                                    >
                                        취소
                                    </button>
                                </span>
                                <span v-else-if="c.interested">
                                    [승인 대기]
                                    <br />
                                    <button
                                        @click="cancelSignup(c.id)"
                                        class="button"
                                    >
                                        취소
                                    </button>
                                </span>
                                <span v-else>
                                    <button
                                        @click="submitSignup(c.id)"
                                        class="button"
                                    >
                                        신청
                                    </button>
                                </span>
                            </div>
                            <div style="clear: both;"></div>
                        </div>
                    </div>
                    <endless
                        ref="endlessLoader"
                        :loadpath="paths.loadmore"
                        :perpage="parseInt(perpage)"
                        mainprop="classes"
                        @loaded="appendLoaded"
                    ></endless>

                    <!-- <button @click="getClasses" class="button">get classes </button>
                    <div v-for="(x, idx) in classdata" :key="'c' + idx">{{x}}</div>
                    <button @click="getInterested" class="button">get interested </button>
                    <div v-for="(x, idx) in classesInterested" :key="'d' + idx">{{x}}</div>
                    <button @click="getConfirmed" class="button">get confirmed </button>
                    <div v-for="(x, idx) in classesConfirmed" :key="'e' + idx">{{x}}</div> -->
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import Classcode from "@/components/Classcode";
import endless from "@/components/endless";
import mixinOutputHelpers from "@/components/mixin-output-helpers.js";
import moment from "moment";
import mxNotify from "@/components/mx-notify.js";
import Vue from "vue";
export default {
    name: "ClassListClients",
    mixins: [mixinOutputHelpers, mxNotify],
    components: {
        Classcode,
        endless,
    },
    data() {
        return {
            classdata: [],
            classesInterested: [],
            classesConfirmed: [],
            loadedPeriod: [],
            perpage: "",
            create: {
                show: false,
                title: "",
                code: "",
                color: "#ffffff",
                pricePerHour: 60000,
            },
            edit: {
                show: false,
                id: 0,
                code: "",
                color: "",
                title: "",
            },

            rowCss: "border-bottom: 1px lightgrey solid;",
            paths: {
                class: "/course/",
                signup: "/client/signup",
                cancel: "/client/cancel",
                loadmore: "/client/loadmore/courses",
            },
        };
    },

    created() {
        axios.get("/client/classlist/init").then((res) => {
            console.log(res.data);
            this.classdata = res.data.classes;
            this.classesInterested = res.data.interested;
            this.classesConfirmed = res.data.confirmed;
            this.perpage = res.data.perpage;
        });
    },
    computed: {
        classDataAug() {
            return this.classdata.map((el) => {
                let out = el;

                let cnfIdx = this.classesConfirmed.findIndex((el2) => {
                    return el2.id == el.id;
                });
                Vue.set(out, "confirmed", cnfIdx > -1);

                let intIdx = this.classesInterested.findIndex((el2) => {
                    return el2.id == el.id;
                });
                Vue.set(out, "interested", intIdx > -1);

                return out;
            });
        },
    },
    watch: {},
    methods: {
        async submitSignup(cid) {
            let pl = await {
                cid: cid,
                period: this.loadedPeriod,
            };
            this.ntfInfo("Sending Data", true);
            axios
                .get(this.paths.signup, { params: pl })
                .then((res) => {
                    this.classesInterested = res.data.interested;
                    this.classesConfirmed = res.data.confirmed;
                    this.ntfSuccess("Sign Up Complete", true);
                })
                .catch((err) => {
                    this.ntfError(
                        "Oops. Something went wrong. Please try again later!",
                        true
                    );
                    console.log(err);
                });
        },
        async cancelSignup(cid) {
            if (!confirm("취소하시겠습니까?")) return false;
            let pl = {
                cid: cid,
                period: this.loadedPeriod,
            };
            this.ntfInfo("Sending Data", true);
            axios
                .get(this.paths.cancel, { params: pl })
                .then((res) => {
                    this.classesInterested = res.data.interested;
                    this.classesConfirmed = res.data.confirmed;
                    this.ntfSuccess("Sign Up Canceled ", true);
                })
                .catch((err) => {
                    this.ntfError(
                        "Oops. Something went wrong. Please try again later!",
                        true
                    );
                    console.log(err);
                });
        },
        appendLoaded(loadedData) {
            this.classdata.push.apply(this.classdata, loadedData.classes);
        },
        resetEndless() {
            if (this.$refs.endless != undefined) this.$refs.endless.reset();
        },
        showDate(string) {
            return moment(string).format("YYYY-MM-DD HH:mm (ddd)");
        },
    },
};
</script>

<style></style>
