var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"main main_container noHeaderPicSpace"}},[_c('div',{staticClass:"container",attrs:{"id":"vueContainer"}},[_c('div',{staticClass:"row gtr-200"},[_c('div',{staticClass:"col-3 col-12-medium"},[_c('section',{attrs:{"id":"sidebar"}},[_c('strong',[_vm._v(" Other class dates ")]),_c('div',{staticClass:"table-div"},_vm._l((_vm.sesListAug),function(date,i){return _c('div',{key:i},[_c('div',[_vm._v(_vm._s(date.displayWeekDay))]),_c('div',[(date.id != _vm.sesData.id)?_c('span',[_c('a',{attrs:{"href":_vm.pathPrefix +
                                                _vm.paths.setAttend +
                                                date.id}},[_vm._v(" "+_vm._s(date.displayStart)+" ")])]):_c('span',[_vm._v(_vm._s(date.displayStart))])])])}),0)])]),_c('div',{staticClass:"col-9 col-12-medium imp-medium"},[_c('section',{attrs:{"id":"content"}},[_c('div',[_c('a',{attrs:{"href":_vm.classPath}},[_vm._v("Back to Class View")])]),_c('div',[_c('h2',[_vm._v("Set Attendance")]),_vm._v(" Class: "),_c('strong',[_vm._v(_vm._s(_vm.sesData.course_title))]),_c('br'),_vm._v(" Session: "),_c('strong',[_vm._v(_vm._s(_vm.displayStart))]),_c('br'),_vm._v(" Duration: "),_c('strong',[_vm._v(_vm._s(_vm.sesData.duration)+" minutes")]),_c('div',[_c('LogDisplay',{attrs:{"query-id":parseInt(_vm.sesData.id),"load-path":_vm.paths.logs,"comment-path":_vm.paths.logComment}})],1),_c('div',{staticClass:"table-div",staticStyle:{"margin-top":"2vh"}},[_vm._m(0),_vm._l((_vm.aData),function(s,sindex){return _c('div',{key:sindex},[_c('div',[_vm._v(_vm._s(sindex + 1))]),_c('div',[_c('a',{attrs:{"href":_vm.paths.studentProfile +
                                                s.student_id,"target":"_blank"}},[_vm._v(_vm._s(s.custom_id))]),_vm._v(" "+_vm._s(s.firstname)+" "),(s.nickname != '')?_c('span',[_vm._v(_vm._s(s.nickname))]):_vm._e(),_vm._v(" "+_vm._s(s.lastname)+" ")]),_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(
                                            _vm.showAttendance(s.att_status)
                                        )}})]),_c('div',[(s.att_status == 't')?_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(s.att_missed_minutes),expression:"s.att_missed_minutes"}],staticStyle:{"width":"3em"},attrs:{"type":"number","min":"0","max":_vm.sesData.duration,"step":"5"},domProps:{"value":(s.att_missed_minutes)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(s, "att_missed_minutes", $event.target.value)},function($event){return _vm.checkTardyMinutes(sindex)}]}}),_vm._v(" minutes ")]):_vm._e()]),_c('div',[_c('a',{staticStyle:{"background-color":"lightseagreen","color":"white","padding-left":"0.5vw","padding-right":"0.5vw"},on:{"click":function($event){return _vm.selectAttendanceStatus(
                                                'p',
                                                sindex
                                            )}}},[_vm._v("P")]),_c('a',{staticStyle:{"background-color":"lightsalmon","color":"white","padding-left":"0.5vw","padding-right":"0.5vw"},on:{"click":function($event){return _vm.selectAttendanceStatus(
                                                't',
                                                sindex
                                            )}}},[_vm._v("T")]),_c('a',{staticStyle:{"background-color":"crimson","color":"white","padding-left":"0.5vw","padding-right":"0.5vw"},on:{"click":function($event){return _vm.selectAttendanceStatus(
                                                'a',
                                                sindex
                                            )}}},[_vm._v("A")])]),_c('div',[(s.notYetSaved == true)?_c('span',[_vm._v("(not saved)")]):_vm._e()]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(s.att_comment),expression:"s.att_comment"}],attrs:{"type":"text","maxlength":"255"},domProps:{"value":(s.att_comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(s, "att_comment", $event.target.value)}}})])])})],2),(_vm.canSave)?_c('button',{staticClass:"button",on:{"click":_vm.submitAttendance}},[_vm._v(" Save ")]):_vm._e()])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"th"},[_c('div'),_c('div',[_vm._v("Student")]),_c('div',[_vm._v("Status")]),_c('div'),_c('div',[_vm._v("Set")]),_c('div'),_c('div',[_vm._v("Comment")])])}]

export { render, staticRenderFns }