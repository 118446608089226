<template>
    <ul v-if="userLevel>= this.$store.state.lvl.admin">
        <router-link :to="'/admin/course/' + cid">학생</router-link> / 
        <router-link :to="'/admin/course/sessions/' + cid">세션</router-link> /
        <router-link :to="'/admin/course/attendance/' + cid">출석</router-link> /
        <router-link :to="'/admin/course/teachers/' + cid">선생님</router-link>
    </ul>
    <ul v-else>
        <router-link :to="'/coach/course/' + cid">학생</router-link> /
        <router-link :to="'/coach/course/sessions/' + cid">세션</router-link> /
        <router-link :to="'/coach/course/attendance/' + cid">출석</router-link>
    </ul>
</template>

<script>

export default {
    name: "ClassViewTopNav",
    props: {
        userLevel: {
            type: Number,
            required: true
        },
        cid: {
            type: Number,
            required: true
        }
    }
}
</script>

<style>

</style>