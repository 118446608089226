<template>
    <section id="main main_container">
        <div>
            <div style="position: relative;">
                <img
                    src="@/assets/_img/client_home.png"
                    style="width: 100%; height: 400px;"
                />
                <div
                    style="position: absolute; top: 12vh; left: 5vw;"
                    class="white font42"
                >
                    {{ this.$store.state.currentUser.firstname }}, 어서오세요
                    :)<br />오늘도 웰니스와 함께 할 준비가 되셨나요?
                </div>
            </div>
            <div id="vueContainer" v-cloak>
                <div
                    class="bg_lightblue row"
                    style="justify-content: center; padding: 4vh 0;"
                >
                    <div
                        v-for="(btn, i) in buttons"
                        :key="i"
                        style=" margin: 0 4vw; text-align: center;"
                    >
                        <div
                            class="bg_white"
                            style="border-radius: 50%; padding: 2vh; 2vw;"
                        >
                            <img
                                :src="btn[0]"
                                style="width: 80px; height: 80px;"
                            />
                        </div>
                        <p class="font20 gray900 weight500 spacer_sm">
                            {{ btn[1] }}
                        </p>
                    </div>
                </div>
                <div class="row" style="padding: 2vh 6vw;">
                    <!-- cat -->
                    <div
                        style="align-items: flex-start align-content: flex-end; display: flex; justify-content: flex-end; flex:1;"
                    >
                        <img
                            src="@/assets/_img/wallet.png"
                            style="height: 40px; width: 40px;"
                        />
                        <span
                            class="catTitle"
                            style="align-items: center; margin-left: .5vw;"
                            >결제 정보</span
                        >
                    </div>
                    <div style=" flex: 6;">
                        <div
                            v-for="(c, i) in DUMMYDATA"
                            :key="i"
                            class="classBox"
                        >
                            <div
                                class="row"
                                style="justify-content: space-between;"
                            >
                                <div>
                                    <div>
                                        <span class="gray400 weight400"
                                            >주문번호</span
                                        >
                                        <span class="main">{{
                                            c.orderNo
                                        }}</span>
                                        <span class="spacer_h_md"></span>
                                        <span class="gray400 weight400"
                                            >주문번호?</span
                                        >
                                        <span class="main">orderNoPH?</span>
                                    </div>
                                    <div class="spacer_md"></div>
                                    <span
                                        class="spacer_md weight500 gray900 font24"
                                        >{{ c.itemName }}</span
                                    >
                                    <span
                                        class="weight500 bg_sysYel white pad_sm rnd_md spacer_h_xs"
                                        >{{ c.orderStatus }}</span
                                    >
                                    <div class="spacer_lg"></div>
                                    <div class="font20 row">
                                        <span class="weight400 gray800 "
                                            >주문</span
                                        >
                                        <span
                                            class="weight700 gray900 spacer_h_xs_l"
                                            >{{ c.price }}</span
                                        >
                                        <div class="spacer_h_lg"></div>
                                        <span class="weight400 gray800"
                                            >총 결제액</span
                                        >
                                        <span
                                            class="weight700  gray800 spacer_h_xs_l"
                                            >{{ c.paid }}</span
                                        >
                                    </div>
                                </div>
                                <div
                                    style="display: flex; align-items: center;"
                                >
                                    <i
                                        @click="showClass = i"
                                        v-if="showClass !== i"
                                        class="fas fa-angle-down fa-2x"
                                        style="color: black;"
                                    ></i>
                                    <i
                                        v-else
                                        @click="showClass = ''"
                                        class="fas fa-angle-up fa-2x"
                                        style="color: black;"
                                    ></i>
                                </div>
                            </div>
                            <!-- showClass == true -->
                            <div
                                v-show="showClass === i"
                                class="rnd_xl bg_white pad_md spacer_xl"
                            >
                                <div class="pad_md">
                                    <p class="main">주문하시는 분</p>
                                    <hr class="spacer_md gray600" />
                                </div>
                                <div
                                    class="row pad_sm"
                                    style="justify-content: space-between;"
                                >
                                    <div style="flex:1;">
                                        <div class="detBoxField">
                                            이름
                                        </div>
                                        <div class="detBoxVal">
                                            {{ c.client.name }}
                                        </div>
                                        <div class="detBoxField">
                                            E-mail
                                        </div>
                                        <div class="detBoxVal">
                                            {{ c.client.mail }}
                                        </div>
                                        <div class="detBoxField">
                                            주소
                                        </div>
                                        <div class="detBoxVal">
                                            {{ c.client.add }}
                                        </div>
                                    </div>
                                    <div style="flex:1;">
                                        <div class="detBoxField">
                                            휴대폰 번호
                                        </div>
                                        <div class="detBoxVal">
                                            {{ c.client.fon }}
                                        </div>

                                        <div class="detBoxField">
                                            전화번호
                                        </div>
                                        <div class="detBoxVal"></div>
                                    </div>
                                </div>
                                <div
                                    style="padding-top: 2vh;"
                                    class="spacer_lg"
                                ></div>
                            </div>
                            <!-- showClass == true  PAYMENT DETAILS-->
                            <div
                                v-show="showClass === i"
                                class="rnd_xl bg_white pad_md spacer_md"
                            >
                                <div class="pad_md">
                                    <p class="main">결제정보</p>
                                    <hr class="spacer_md gray600" />
                                </div>
                                <div
                                    class="row pad_sm"
                                    style="justify-content: space-between;"
                                >
                                    <div style="flex:1;">
                                        <div class="detBoxField">
                                            결제방식
                                        </div>
                                        <div class="detBoxVal">
                                            {{ c.payment.mode }}
                                        </div>
                                        <div class="detBoxField">
                                            입금자명
                                        </div>
                                        <div class="detBoxVal">
                                            {{ c.payment.name }}
                                        </div>
                                    </div>
                                    <div style="flex: 1">
                                        <div class="detBoxField">
                                            결제금액
                                        </div>
                                        <div class="detBoxVal">
                                            {{ c.payment.price }}
                                        </div>

                                        <div class="detBoxField">
                                            입금계좌
                                        </div>
                                        <div class="detBoxVal">
                                            {{ c.payment.acc }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="main bord_gray"
                            style="width: 95%; margin-left: 2vw; text-align: center; "
                        >
                            더보기
                        </div>
                    </div>
                </div>
                <!-- calendar -->
                <div>
                    <div class="row" style="padding: 2vh 6vw; margin-top: 4vh;">
                        <!-- left -->
                        <div
                            style="flex:1; align-items: flex-start; align-content: flex-end; justify-content: flex-end;"
                        >
                            <img
                                src="@/assets/_img/calendar.png"
                                style="height: 40px; width: 40px;"
                            />
                            <span class="catTitle" style=" margin-left: .5vw;"
                                >교육 일정</span
                            >
                        </div>
                        <!-- right -->
                        <div style="flex:6; margin-left: 4vw;">
                            <ul>
                                <li>
                                    원하는 선생님의 수업 날짜를 확인하고
                                    ‘신청하기’를 클릭해 주세요.
                                </li>
                                <li>
                                    선생님이 확인하면 ‘확정’ 으로 변경됩니다.
                                    수업 날짜를 변경하고 싶으면 ‘취소’를
                                    누르세요. 원하는 날짜를 다시 신청해 주세요.
                                </li>
                            </ul>
                            <div class="spacer_lg"></div>
                            <div class="bg_gray300 rnd_lg pad_md ">
                                <span
                                    v-for="(t, i) in tabs"
                                    :key="i"
                                    style="margin-left: 1vw; padding: 1vh 0;"
                                    :class="tabSelectorClass(t)"
                                    @click="selectedTab = t"
                                    >{{ t }}</span
                                >
                            </div>
                            <div>
                                <div v-if="tabData.length > 0">
                                    <div
                                        v-for="(c, i) in tabData"
                                        :key="i"
                                        class="classCard card"
                                    >
                                        <div style="flex: 4;">
                                            {{ c.title }}
                                        </div>
                                        <div style="flex: 1;">
                                            <div class="classField">횟수</div>
                                            <p class="qsand">
                                                {{ c.sessions.length }}
                                            </p>
                                        </div>
                                        <div style="flex: 1;">
                                            <div class="classField">
                                                첫 수업
                                            </div>
                                            <p class="qsand">
                                                {{ c.s1_start }}
                                            </p>
                                        </div>
                                        <div style="flex: 1;">
                                            <div class="classField">
                                                마지막 수업
                                            </div>
                                            <p class="qsand">
                                                {{ c.sx_start }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-else
                                    class="weight500 font16 gray400"
                                    style="padding: 10vh 0; text-align: center; height: 20vh;"
                                >
                                    {{ tabNoClassTextStub }} 수업이 없습니다.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- goals -->
                <!-- <div>
                    <div class="row" style="padding: 2vh 6vw; margin-top: 4vh;">
                        
                        <div
                            style="flex:1; align-items: flex-start; align-content: flex-end; justify-content: flex-end;"
                        >
                            <img
                                src="@/assets/_img/goal.png"
                                style="height: 40px; width: 40px;"
                            />
                            <span class="catTitle" style=" margin-left: .5vw;"
                                >숙제하기</span
                            >
                        </div>
                        
                        <div style="flex:6; margin-left: 4vw;">
                            <ul>
                                <li>
                                    원활한 교육 진행을 위해 꼭 참여
                                    부탁드립니다.
                                </li>
                                <li>
                                    ‘작성하기’를 누른 후 내용을 작성한 다음
                                    하단의 ‘제출하기’를 눌러주세요.
                                </li>
                            </ul>
                            <div class="spacer_lg"></div>
                            <div>
                                <div v-if="tabData.length > 0">
                                    <div
                                        v-for="(s, i) in DUMMYSADATA"
                                        :key="i"
                                        class="SACard card"
                                    >
                                        <div>
                                            <div><span class="classField">제출일시</span>
                                            {{s.timestamped}}</div>
                                            [사전 설문] 자기평가표
                                            <span v-if="s." class="pad_sm bg_green white rnd_md"></span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div
                                    v-else
                                    class="weight500 font16 gray400"
                                    style="padding: 10vh 0; text-align: center; height: 20vh;"
                                >
                                    
                                    ??? 없습니다.
                                </div>
                                <div
                                    class="main bord_gray"
                                    style="width: 95%; text-align: center; "
                                >
                                    더보기
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="spacer_xl"></div>
                <h3>나의 수업 일정</h3>
                <div v-if="sessionsAug.length > 0">
                    <div
                        v-for="(s, sindex) in sessionsAug"
                        class="shadowbox"
                        :style="getSessionStyle(sindex)"
                        :key="sindex"
                    >
                        {{ showDate(s.start) }}
                        <span v-if="s.google_meet_url != ''">
                            -
                            <a
                                :href="s.google_meet_url"
                                target="_blank"
                                title="Google Meet link"
                            >
                                <i class="fa fa-video"></i>
                                {{ s.google_meet_url }}
                            </a>
                        </span>
                        <br />
                        <Classcode
                            :code="s.course_code"
                            :color="s.course_color"
                        >
                        </Classcode>
                        {{ s.course_title }}
                    </div>
                </div>
                <div v-else>
                    다음 수업이 없습니다.
                </div>

                <div
                    v-if="intAug.length > 0"
                    class="shadowbox"
                    style="margin-top: 4vh;"
                >
                    <h4>신청한 수업</h4>
                    <div class="table-div" style="width: 99%;">
                        <div class="th">
                            <div style="width: 40%;">수업</div>
                            <div style="width: 10%;" class="center">
                                횟수
                            </div>
                            <div style="width: 25%;">첫 수업</div>
                            <div style="width: 25%;">마지막 수업</div>
                        </div>
                        <div v-for="(c, idx) in intAug" :key="idx">
                            <div>
                                <Classcode :code="c.code" :color="c.color">
                                </Classcode>
                                {{ c.title }}
                            </div>
                            <div class="center">
                                {{ c.sessions.length }}
                            </div>
                            <div>{{ showDate(c.s1_start) }}</div>
                            <div>{{ showDate(c.sx_start) }}</div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="cnfAug.length > 0"
                    class="shadowbox"
                    style="margin-top: 4vh;"
                >
                    <h4>수료한 수업</h4>
                    <div class="table-div" style="width: 99%;">
                        <div class="th">
                            <div style="width: 40%;">수업</div>
                            <div style="width: 10%;" class="center">
                                횟수
                            </div>
                            <div style="width: 25%;">첫 수업</div>
                            <div style="width: 25%;">마지막 수업</div>
                        </div>
                        <div v-for="(c, idx) in cnfAug" :key="idx">
                            <div>
                                <Classcode :code="c.code" :color="c.color">
                                </Classcode>
                                {{ c.title }}
                            </div>
                            <div class="center">
                                {{ c.sessions.length }}
                            </div>
                            <div>{{ showDate(c.s1_start) }}</div>
                            <div>{{ showDate(c.sx_start) }}</div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import mixinOutputHelpers from "@/components/mixin-output-helpers.js";
// import Classcode from "@/components/Classcode";
import moment from "moment";

export default {
    name: "HomeClient",
    components: {
        // Classcode,
    },
    mixins: [mixinOutputHelpers],
    data() {
        return {
            DUMMYDATA: [
                {
                    orderNo: "2021010112345",
                    itemName: "AWH 웰니스 교육 패키지",
                    price: "150,000",
                    orderStatus: "입금 확인 중",
                    paid: 0,
                    client: {
                        name: "홍길동",
                        fon: "010-0000-0000",
                        mail: "test@test.com",
                        add:
                            "(137863) 서울특별시 서초구 강남대로 37길 24-6, 2층 (서초동, 성해빌딩)",
                    },
                    payment: {
                        mode: "무통장",
                        name: "홍길동",
                        price: "150,000원",
                        acc: "OO은행 00000-00-00000 예금주명",
                    },
                },
                {
                    orderNo: "2021010999999",
                    itemName: "AWH 웰니스 교육 패키지2",
                    price: "150,000",
                    orderStatus: "입금 확인 중",
                    paid: 0,
                    client: {
                        name: "홍길동2",
                        fon: "010-0000-0001",
                        mail: "test@tes2t.com",
                        add:
                            "(137863) 서울특별시 서초구 강남대로 37길 24-6, 2층 (서초동, 성해빌딩)",
                    },
                    payment: {
                        mode: "무통장",
                        name: "홍길동",
                        price: "150,000원",
                        acc: "OO은행 00000-00-00000 예금주명",
                    },
                },
                {
                    orderNo: "202101011442345",
                    itemName: "AWH 웰니스 교육 패키지",
                    price: "250,000",
                    orderStatus: "입금 확인 중",
                    paid: 0,
                    client: {
                        name: "홍1길동",
                        fon: "010-0020-0000",
                        mail: "3test@test.com",
                        add:
                            "(137863) 서울특별시 서초구 강남대로 37길 24-6, 2층 (서초동, 성해빌딩)",
                    },
                    payment: {
                        mode: "무통장",
                        name: "홍길동",
                        price: "150,000원",
                        acc: "OO은행 00000-00-00000 예금주명",
                    },
                },
            ],
            showClass: "",
            buttons: [
                [require("@/assets/_img/wallet.png"), "결제 정보"],
                [require("@/assets/_img/calendar.png"), "교육 일정"],
                [require("@/assets/_img/goal.png"), "숙제하기"],
                [require("@/assets/_img/shield.png"), "내 정보 변경"],
            ],
            tabs: ["나의 수업 일정", "신청한 수업", "수료한 수업"],
            selectedTab: "나의 수업 일정",

            saData: [],

            sessions: [],
            classesInterested: [],
            classesConfirmed: [],
            classesCompleted: [],
        };
    },
    created() {
        axios
            .get("/client/home/init")
            .then((res) => {
                this.sessions = res.data.sessions;
                this.classesInterested = res.data.interested;
                this.classesConfirmed = res.data.confirmed;
            })
            .catch((err) => console.log(err));
    },
    computed: {
        tabNoClassTextStub() {
            if (this.selectedTab == this.tabs[0]) return "다음 ";
            else if (this.selectedTab == this.tabs[1]) return "신청한 ";
            else return "수료한 ";
        },
        tabData() {
            if (this.selectedTab == this.tabs[0]) return this.classesConfirmed;
            else if (this.selectedTab == this.tabs[1])
                return this.classesInterested;
            else return this.classesCompleted;
        },
        // sessionsAug() {
        //     let out = this.sessions;
        //     return out.sort(function(a, b) {
        //         let aDate = Date.parse(a.start);
        //         let bDate = Date.parse(b.start);

        //         if (aDate < bDate) return -1;
        //         if (aDate > bDate) return 1;
        //         return 0;
        //     });
        // },
        // intAug() {
        //     let out = this.classesInterested;
        //     return out.sort(function(a, b) {
        //         // have sessions vs no sessions
        //         let aSes = 0;
        //         let bSes = 0;
        //         if (a.sessions.length > 0) aSes = 1;
        //         if (b.sessions.length > 0) bSes = 1;

        //         if (aSes > bSes) return -1;
        //         if (aSes < bSes) return 1;
        //         return 0;
        //     });
        // },
        // cnfAug() {
        //     let out = this.classesConfirmed;
        //     return out.sort(function(a, b) {
        //         // have sessions vs no sessions
        //         let aSes = 0;
        //         let bSes = 0;
        //         if (a.sessions.length > 0) aSes = 1;
        //         if (b.sessions.length > 0) bSes = 1;

        //         if (aSes > bSes) return -1;
        //         if (aSes < bSes) return 1;
        //         return 0;
        //     });
        // },
    },
    methods: {
        tabSelectorClass(tab) {
            if (this.selectedTab == tab) return "gray900 weight500 selectedTab";
            else return "gray301 notSelectedTab";
        },

        showDate(string) {
            return moment(string).format("YYYY-MM-DD HH:mm (ddd)");
        },
        getSessionStyle(sindex) {
            let em = "1.2";
            if (sindex == 0) em = "1.6";
            return ` font-size: ${em}em;  `;
        },
        // toggleClassBox(i) {
        //     if (this.showClass == i) this.showClass = "";
        //     else this.showClass = i;
        // },
    },
};
</script>

<style scoped>
.catTitle {
    font-weight: 500;
    color: #1a1a1a;
    font-size: 2rem;
}
.classBox {
    background-color: #f7f7f7;
    border-radius: 20px;
    padding: 3vh 3vw;
    margin: 1vh 2vw;
}
.detBoxField {
    color: #666666;
    size: 0.75rem;
    font-weight: 400;
    margin: 1.5vh 0;
}
.detBoxVal {
    size: 0.9rem;
    color: #333333;
    font-weight: 400;
    margin: 0.75vh 0;
}
.card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
}
.classCard {
    padding: 1vh 2vw;
    border-radius: 20px;
    border: 1px solid #999999;
}
.classField {
    color: #999999;
    font-weight: 400;
    font-size: 0.75rem;
}
.SACard {
    padding: 2vh 2vw;
    background-color: #f7f7f7;
}
</style>
