<template>
    <div class="container">    
        <h1>Access Denied</h1>
        <p>
            You do not have sufficient user rights to view this page 
            or you need to 
            <router-link to="/login">Log in</router-link>    
        </p>
        
    </div>
</template>

<script>
export default {
    name: "AccessDenied",
}
</script>

<style>

</style>