
<template>
    <span class="noselect">
        <a @click="mouseDec('h')"><i class="fa fa-caret-left"></i></a>
        <a @click="mouseInc('h')"><i class="fa fa-caret-right"></i></a>
        <span :style=" hourStyle ">{{ pad(hour) }}</span>
        : 
        <span :style=" minuteStyle ">{{ pad(minute) }}</span>
        <a @click="mouseDec('m')"><i class="fa fa-caret-left"></i></a>
        <a @click="mouseInc('m')"><i class="fa fa-caret-right"></i></a>
        <span> <input type="checkbox" v-model="keyboardActive" />use keyboard</span>
        <span> <a @click="toggleHelp">[?]</a></span>
        <div v-if="showHelp == true" style="font-weight: bold; font-size: 0.7em;">
            click checkbox to use keyboard<br />
            "Arrow Up" "Arrow Down" to change, "Arrow Left" "Arrow Right" to select hours and minutes
        </div>
    </span>

</template>
<script>

export default {
    name: "TimePicker",
    props: {
        h: {
            type: Number,
            required: false,
            default: 12,
        },
        m: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            hour: 12,
            minute: 0,
            selected: 'h',
            keyboardActive: false,
            showHelp: false,
        };
    },
    created: function() {
        this.hour = this.h;
        this.minute = this.m;
        this.addKeys();
    },
    destroyed: function() {
        this.removeKeys();
    },
    computed: {
        hourStyle: function() {
            if (this.selected == 'h')
                return 'background-color: yellow;';
            return '';
        },
        minuteStyle: function() {
            if (this.selected == 'm')
                return 'background-color: yellow;';
            return '';
        },
    },
    methods: {

        toggleHelp: function() {
            this.showHelp = !this.showHelp;
        },
        pad: function (num) {
            return ("00" + num).substr(-2, 2);
        },
        increaseSelected: function() {
            if (this.selected == 'h') {
                this.hour ++;
                if (this.hour > 23)
                    this.hour -= 24;
            } else if (this.selected == 'm') {
                this.minute += 5;
                if (this.minute > 59)
                    this.minute -= 60;
            }
            this.emitTime();
        },
        decreaseSelected: function() {
            if (this.selected == 'h') {
                this.hour --;
                if (this.hour < 0)
                    this.hour += 24;
            } else if (this.selected == 'm') {
                this.minute -= 5;
                if (this.minute < 0)
                    this.minute += 60;
            }
            this.emitTime();
        },
        toggleSelected: function(field = null) {
            if (field !== null) {
                this.selected = field;
            } else {
                if (this.selected == 'h') 
                    this.selected = 'm';
                else   
                    this.selected = 'h';
            }
        },
        mouseInc: function(field) {
            this.toggleSelected(field);
            this.increaseSelected();
        },
        mouseDec: function(field) {
            this.toggleSelected(field);
            this.decreaseSelected();
        },
        addKeys: function() {
            document.addEventListener('keydown', this.timePickerKeys);
        },
        removeKeys: function() {
            document.removeEventListener('keydown', this.timePickerKeys);
        },        
        timePickerKeys: function(event) {
            if (!this.keyboardActive)
                return false;

            if (event.key == 'ArrowUp') {
                event.preventDefault();
                this.increaseSelected();
            } else if (event.key == 'ArrowDown') {
                event.preventDefault();
                this.decreaseSelected();
            } else if (event.key == 'ArrowLeft' || event.key == 'ArrowRight') {
                this.toggleSelected();
            }
            else return -1;
        },
        emitTime: function() {
            this.$emit('emittime', this.hour, this.minute);
        },
    },
}

</script>