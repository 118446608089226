<template>
    <div style="display: flex; flex:1; flex-direction: column;">
        <img style="width: 100%;" src="@/assets/_img/home_1.jpg" />
        <img style="width: 100%;" src="@/assets/_img/home_2.png" />
        <img style="width: 100%;" src="@/assets/_img/home_3.png" />
        <img style="width: 100%;" src="@/assets/_img/home_4.png" />
        <div style="position: relative; text-align: center; color: #fff;">
            <img style="width: 100%;" src="@/assets/_img/home_5.png" />
            <div class="centered_text">
                <p class="font20">웰링비와 함께 셀프케어해요</p>
                <p class="font30 weight800">웰니스 온라인 교육 프로그램</p>
                <div class="spacer_lg">
                    <router-link to="/program" class="progBtn">
                        프로그램 살펴보기
                        <i class="fa fa-arrow-right" />
                    </router-link>
                </div>
            </div>
        </div>
        <div style="flex:1;" class="greenPicBG">
            <div
                style="display: flex; flex-direction: row; margin-top: 14vh; padding-left: 20vw;"
                class="white"
            >
                <div class="qsand font32 weight700">
                    We Make <span class="font64">Wellingbe</span>
                </div>
                <div class="white spacer_h_md">
                    짧은 한 문단의 글을 입력해주세요.<br />
                    죽는 날까지 하늘을 우러러 한 점 부끄럼이 없기를, 잎새에 이는
                    바람에도 나는 괴로워했다.<br />
                    별을 노래하는 마음으로 모든 죽어 가는 것을 사랑해야지
                </div>
            </div>
            <div style="position: relative;">
                <horizontal-scroll class="horizontal-scroll">
                    <div class="outer">
                        <div v-for="(c, i) in items" :key="i" class="hCard">
                            <span class="qsand weight500 font24 gray800">{{
                                c.name
                            }}</span>
                            <span class="qsand spacer_h_xs_l">{{ c.job }}</span>
                            <div
                                class="pad_md bg_gray300 spacer_md"
                                style="flex-wrap: wrap;"
                            >
                                {{ c.txt }}
                            </div>
                        </div>
                    </div>
                </horizontal-scroll>
            </div>
        </div>
    </div>
</template>

<script>
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
export default {
    name: "LandingHome",
    components: { HorizontalScroll },
    data() {
        return {
            items: [
                {
                    name: "Hong Gil-dong",
                    job: "CEO",
                    txt:
                        "짧은 한 문단의 글을 입력해주세요. 죽는 날까지 하늘을 우러러 한 점 부끄럼이 없기를, 잎새에 이는 바람에도 나는 괴로워했다. 별을 노래하는 마음으로 모든 죽어 가는 것을 사랑해야지",
                },
                {
                    name: "Hong Gil-dong2",
                    job: "CEO",
                    txt:
                        "짧은 한 문단의 글을 입력해주세요. 죽는 날까지 하늘을 우러러 한 점 부끄럼이 없기를, 잎새에 이는 바람에도 나는 괴로워했다. 별을 노래하는 마음으로 모든 죽어 가는 것을 사랑해야지",
                },
                {
                    name: "Hong Gil-dong3",
                    job: "CEO",
                    txt:
                        "짧은 한 문단의 글을 입력해주세요. 죽는 날까지 하늘을 우러러 한 점 부끄럼이 없기를, 잎새에 이는 바람에도 나는 괴로워했다. 별을 노래하는 마음으로 모든 죽어 가는 것을 사랑해야지",
                },
                {
                    name: "Hong Gil-dong4",
                    job: "CEO",
                    txt:
                        "짧은 한 문단의 글을 입력해주세요. 죽는 날까지 하늘을 우러러 한 점 부끄럼이 없기를, 잎새에 이는 바람에도 나는 괴로워했다. 별을 노래하는 마음으로 모든 죽어 가는 것을 사랑해야지",
                },
                {
                    name: "Hong Gil-dong5",
                    job: "CEO",
                    txt:
                        "짧은 한 문단의 글을 입력해주세요. 죽는 날까지 하늘을 우러러 한 점 부끄럼이 없기를, 잎새에 이는 바람에도 나는 괴로워했다. 별을 노래하는 마음으로 모든 죽어 가는 것을 사랑해야지",
                },
            ],
        };
    },
    methods: {},
    created() {},
};
</script>

<style scoped>
.centered_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.img_overlay > * {
    color: #fff;
}
.progBtn {
    padding: 2vh 2vw;
    background: transparent;
    border-radius: 4px;

    border: 2px solid #fff;
    text-decoration: none;
}
.progBtn:visited {
    color: inherit;
}
.horizontal-scroll {
    /* position: relative; */
    display: flex;
    width: 100%;
    /* position: absolute; */
    margin-top: 10vh;
    padding-bottom: 10vh;
    z-index: 1;
}
.outer {
    display: flex;
    flex: 1;
    width: auto;
    height: 100%;
    padding-left: 20vw;
    flex-flow: row nowrap;
    align-items: center;
}
.hCard {
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 20vw;
    height: auto;
    color: #666666;
    padding: 2vh 1vw;
    border-radius: 20px;
    /* margin-right: 1vw; */
    margin-bottom: 1vh;
    background-color: #fff;
    white-space: normal;
    /* display: flex;
    flex-direction: row; */
}

.hCard:not(:first-of-type) {
    margin-left: 1vw;
}

.greenPicBG {
    min-height: 50vh;
    min-width: 100vw;
    background-image: url("~@/assets/_img/home_6.png");
    background-size: 100% 100%;
}
/* .horizontal-scroll::after {
    content: "";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 17%;
    height: calc(100% - 20px);
    right: 0;
    position: absolute;
    z-index: 9999999;
    margin-left: auto;
    top: 0;
    backdrop-filter: blur(2px); */
/* background-color: green; */
/* } */
</style>
