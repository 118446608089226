import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase'
import moment from 'moment'


Vue.use(Vuex)
export default new Vuex.Store({
	state: {
		currentUser: {},
		lvl: {
			client: 1,
			coach: 2,
			coachOrAdmin: 4,
			admin: 16,
		},
		token: ''
	},
	mutations: {
		//syncronous
		SET_CURRENT_USER(state, payload) {
			state.currentUser = payload;
		},
		SET_TOKEN(state, payload) {
			state.token = payload;
			axios.defaults.headers.common['authorization'] = 'Bearer ' + payload;
		},
	},
	actions: {
		//async
		SET_USER({ commit }, userData) {
			if(userData)
				commit("SET_CURRENT_USER", userData);
			else commit("SET_CURRENT_USER", null);
		},

		CHECK_AUTH(context) {
			return new Promise((resolve, reject) => {
				firebase.auth().onAuthStateChanged(async (_user) => {
					try { 
						if (_user) {
							// console.log('_user:*****', _user)
							let tkn = await firebase.auth().currentUser.getIdToken();
							context.dispatch("UPDATE_TOKEN", tkn);
							axios.defaults.headers.common['authorization'] = 'Bearer ' + tkn;
							let wbUser = await axios.get('/user/get');
							context.commit('SET_CURRENT_USER', wbUser.data);
						} else {
							context.commit('SET_CURRENT_USER', null);
							context.dispatch("UPDATE_TOKEN", null);
							axios.defaults.headers.common['authorization'] = 'Bearer: ';
						}
						// console.log('current user in checkAuth action:', _user)
						resolve(true)
					} catch { reject()}
				})
			})
		},
		UPDATE_TOKEN({commit}, payload) {
			commit("SET_TOKEN", payload)
			axios.defaults.headers.common['authorization'] = 'Bearer ' + payload;
		},
		RESET_PW(context) {
			let auth = firebase.auth();
			return auth.sendPasswordResetEmail(context.state.currentUser.email)
				.then(() => {return 'success'})
				.catch(err => console.log(err))
		}
	},
	modules: {
	},
	getters: {
		GET_CURRENT_USER: state => state.currentUser,
		GET_TOKEN: state => state.token,
		GET_PATH: state => {
			if(!state.currentUser) return '/public'
			else if(state.currentUser.level == state.lvl.client) return '/client'
			else if(state.currentUser.level == state.lvl.coach) return '/coach'
			else if(state.currentUser.level == state.lvl.admin) return '/admin'
			else return '/public'
		},
		GET_AUTH_CATEGORY: (state) => (lvl) => {
			for(let key in state.lvl) {
				console.log('store, for, key: ', key)
				console.log('store, for, key value: ', state.lvl[key])
				console.log('store, for, lvl: ', lvl)
				if(state.lvl[key] == lvl)
					return key;
			}
		},
		FORMAT_DATE: (str) => {
			return moment(str).format('YYYY-MM-DD HH:mm');
		}
	}
})

