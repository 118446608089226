import {moment} from 'moment'
export default {
    methods: {
        trimDate: function(dateObj) {
            if (typeof(dateObj === 'object') && dateObj !== null)
                return dateObj.toString().substring(0, 15);
            return '';
        },
        trimDateTime: function(dateObj) {
            if (typeof(dateObj === 'object') && dateObj !== null)
                return dateObj.toString().substring(0, 24);
            return '';
        },
        toMysqlDate: function(dateString) {
            if (dateString != null && dateString != undefined) {
                if (dateString.length == 0)
                    return false;
                return moment(dateString).tz('Asia/Seoul').format('YYYY-MM-DD');
            }
            else return dateString;
            
        },
        toMysqlDateTime: function(dateString) {
            if (dateString != null && dateString != undefined) {
                if (dateString.length == 0)
                    return false;
                return moment(dateString).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss');
            }
            else return dateString;
        },
    },
};