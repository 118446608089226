<template>
    <section id="main">
        <div class="container main_container noHeaderPicSpace">
            <section id="content">
                <div>
                    <ClassViewTopNav
                        :user-level="userLevel"
                        :cid="parseInt(cid)"
                    />
                </div>
                <!-- Vue -->
                <div id="vueContainer" v-cloak>
                    <h2>{{ cdata.title }} - Attendance</h2>

                    <div class="table-div">
                        <div class="th">
                            <div>#</div>
                            <div></div>
                            <div>수업 일정</div>
                            <div>학생수</div>
                            <div
                                style="background-color: lightseagreen; color: white; padding-left: 0.5vw; padding-right: 0.5vw;"
                            >
                                P
                            </div>
                            <div
                                style="background-color: lightsalmon; color: white; padding-left: 0.5vw; padding-right: 0.5vw;"
                            >
                                T
                            </div>
                            <div
                                style="background-color: crimson; color: white; padding-left: 0.5vw; padding-right: 0.5vw;"
                            >
                                A
                            </div>
                            <div>?</div>
                        </div>
                        <div v-for="(ses, sindex) in adata" :key="sindex">
                            <div>{{ sindex + 1 }})</div>
                            <div>{{ ses.displayWeekDay }}</div>
                            <div>
                                <a
                                    :href="
                                        pathAuthPrefix +
                                            paths.setAttend +
                                            ses.id
                                    "
                                >
                                    {{ ses.displayStart }}
                                </a>
                            </div>
                            <div>{{ ses.student_count }}</div>
                            <div>
                                <span
                                    v-if="ses.attPresent > 0"
                                    style="color: lightseagreen; font-weight: bold;"
                                >
                                    {{ ses.attPresent }}
                                </span>
                                <span v-else>0</span>
                            </div>
                            <div>
                                <span
                                    v-if="ses.attTardy > 0"
                                    style="color: lightsalmon; font-weight: bold;"
                                >
                                    {{ ses.attTardy }}
                                </span>
                                <span v-else>0</span>
                            </div>
                            <div>
                                <span
                                    v-if="ses.attAbsent > 0"
                                    style="color: crimson; font-weight: bold;"
                                >
                                    {{ ses.attAbsent }}
                                </span>
                                <span v-else>0</span>
                            </div>
                            <div>
                                <span
                                    v-if="
                                        ses.attendance_count < ses.student_count
                                    "
                                    style="color: red;"
                                >
                                    {{
                                        ses.student_count - ses.attendance_count
                                    }}
                                </span>
                                <span v-else>-</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import ClassViewTopNav from "@/components/ClassViewTopNav";
import moment from "moment";
export default {
    name: "ClassViewAttendance",
    components: {
        ClassViewTopNav,
    },
    data() {
        return {
            cid: "",
            adata: [],
            cdata: {},

            paths: {
                setAttend: "/course/attendance/set/",
            },
        };
    },
    created() {
        this.cid = this.$route.params.cid;
        this.userLevel = this.$store.state.currentUser.level;

        axios
            .get("/course/attendance/init", { params: { cid: this.cid } })
            .then((res) => {
                console.log(res);
                this.cdata = res.data.cData;
                this.adata = res.data.aData;
                this.augData();
            })
            .catch((err) => console.log(err));
    },
    computed: {
        pathAuthPrefix() {
            return this.$store.getters.GET_PATH;
        },
    },
    methods: {
        augData() {
            for (let i = 0; i < this.adata.length; i++) {
                let a = this.adata[i];
                if (a.attendance_data != undefined) {
                    a.attPresent = a.attendance_data.reduce(function(acc, el) {
                        if (el.status == "p") return acc + parseInt(el.cnt);
                        else return acc;
                    }, 0);
                    a.attTardy = a.attendance_data.reduce(function(acc, el) {
                        if (el.status == "t") return acc + parseInt(el.cnt);
                        else return acc;
                    }, 0);
                    a.attAbsent = a.attendance_data.reduce(function(acc, el) {
                        if (el.status == "a") return acc + parseInt(el.cnt);
                        else return acc;
                    }, 0);
                } else {
                    // attendance_data not defined
                    a.attPresent = 0;
                    a.attTardy = 0;
                    a.attAbsent = 0;
                }
                let m = moment(a.start);
                a.displayStart = m.format("YYYY-MM-DD HH:mm");
                a.displayWeekDay = m.format("ddd");
            }
        },
    },
};
</script>

<style></style>
