<template>
    <div
        class="bg_lightgray row"
        style="margin-top: 8vh; padding: 2vh 4vw; width: 100%;"
    >
        <div style="width: 100%;">
            <p class="main font18">
                <b>12주 과정</b>
            </p>
            <p class="gray900 font48"><b>AWH</b> 웰니스 교육 패키지 구매하기</p>

            <div class="bg_white spacer_lg">
                <div
                    class="row"
                    style="justify-content: space-between; width: 100%; height: 100%; padding: 2vh 2vw; margin: 0 0;"
                >
                    <!-- left -->
                    <span style="width: 70%;">
                        <div
                            class="gray900 font18 weight500"
                            style="padding: 1vh 1vw; border-bottom: 1px solid #CCCCCC; width: 100%;"
                        >
                            주문하시는 분
                        </div>
                        <!-- <hr class="spacer_md" /> -->
                        <div class="spacer_lg"></div>
                        <div class="row" style="justify-content: space-around;">
                            <div style="flex:1;">
                                이름<span class="sysRed">*</span><br />
                                <input
                                    placeholder="이름"
                                    class="payment_form"
                                />
                            </div>
                            <div class="spacer_h_sm_l"></div>
                            <div style="flex:1;">
                                휴대폰 번호<span class="sysRed">*</span><br />
                                <input
                                    placeholder="휴대폰 번호"
                                    class="payment_form"
                                />
                            </div>
                        </div>
                        <div class="spacer_lg"></div>
                        <div class="row" style="justify-content: space-around;">
                            <div style="flex:1;">
                                E-mail<span class="sysRed">*</span><br />
                                <input
                                    placeholder="E-mail"
                                    class="payment_form"
                                />
                            </div>
                            <div class="spacer_h_sm_l"></div>
                            <div>
                                전화번호<span class="sysRed">*</span><br />
                                <input
                                    placeholder="전화번호"
                                    class="payment_form"
                                />
                            </div>
                        </div>
                        <div class="spacer_lg"></div>
                        <div style="display: flex; flex-direction: column;">
                            <div style="width: 100%;">
                                주소<span class="sysRed">*</span>
                                <div
                                    style="flex-direction: row; display: flex;"
                                >
                                    <span style="width: 85%;">
                                        <input
                                            placeholder="우편번호"
                                            class="payment_form"
                                        />
                                    </span>
                                    <span
                                        style="height: 85%; width: 15%; border: 2px solid #CCCCCC; text-align: center; border-radius: 2px; padding: .6vh 1vw;"
                                        class="gray600 weight400 font14 spacer_h_sm_l"
                                        @click="find_address_ph"
                                    >
                                        주소 검색
                                    </span>
                                </div>
                            </div>
                            <div style="width: 100%; margin-top: 1vh;">
                                <input
                                    placeholder="기본주소"
                                    class="payment_form"
                                />
                                <input
                                    placeholder="상세주소"
                                    class="payment_form"
                                />
                            </div>
                        </div>
                    </span>
                    <!-- right -->
                    <span
                        style="width: 25%; border-left: 1px solid #CCCCCC; display: flex; flex-direction: column;"
                    >
                        <div style="padding: 1vh 2vw; ">
                            <div
                                class="row"
                                style="justify-content: space-between;"
                            >
                                <div class="md_fat">주문</div>
                                <div class="fat">
                                    150,000원
                                </div>
                            </div>
                            <div class="spacer_md"></div>
                            <div
                                class="row"
                                style="justify-content: space-between;"
                            >
                                <div class="md_fat">배송비</div>
                                <div class="fat">0원</div>
                            </div>
                            <div class="spacer_md"></div>
                        </div>
                        <div
                            class="bg_lightblue"
                            style="height: 8vh; padding: 3vh 3vh 2vw;"
                        >
                            <div
                                class="row"
                                style="justify-content: space-between;"
                            >
                                <div class="md_fat">총 주문 금액</div>
                                <div class="weight700 font20 gray700">
                                    <span class="main">150,000</span>원
                                </div>
                            </div>
                        </div>
                        <div class="spacer_lg"></div>
                        <div class="md_fat pad_md">
                            <p>결제수단</p>
                            <div
                                class="spacer_lg"
                                style="justify-content: center; display: flex; flex-direction: row; flex-wrap: wrap;"
                            >
                                <div
                                    v-for="(po, i) in paymentOptions"
                                    :key="i"
                                    :class="paymentOptionStyle(po)"
                                    style="width: 50%;"
                                    @click="selectedPaymentOption = po"
                                >
                                    {{ po }}
                                </div>
                            </div>
                        </div>
                        <div class="spacer_md"></div>
                        <div
                            class="bg_gray300 pad_md25"
                            style="height: 12vh; padding-bottom: 1vh;"
                        >
                            <select style="width: 100%;">
                                <option>OO은행 00000-00-00000 예금주</option>
                                <option>O1은행</option>
                                <option>O2은행</option>
                            </select>
                            <select style="width: 100%;" class="nameSelect">
                                <option class="gray400">입금자명</option>
                                <option>입금자명2</option>
                                <option>입금자명3</option>
                            </select>
                        </div>
                        <div class="pad_md2" style="margin-top: 1vh;">
                            <div
                                class="bg_green rnd_md white pad_sm"
                                style="text-align: center;"
                            >
                                구매하기
                            </div>
                            <div class="spacer_sm"></div>
                            <div
                                class="rnd_md gray600 pad_sm"
                                style="text-align: center; border: 1px solid #CCCCCC"
                            >
                                취소
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Payment",
    data() {
        return {
            selectedPaymentOption: "무통장 입금",
            paymentOptions: [
                "무통장 입금",
                "가상계좌",
                "계좌이체",
                "휴대폰",
                "신용카드",
            ],
        };
    },
    computed: {},
    methods: {
        find_address_ph() {
            console.log("find_address_ph");
        },
        paymentOptionStyle(opt) {
            if (this.selectedPaymentOption == opt)
                return "paymentOption selectedPayment";
            else return "paymentOption notSelectedPaymentOption";
        },
    },
};
</script>

<style scoped>
.md_fat {
    font-weight: 400;
    font-size: 15px;
    color: #4d4d4d;
}
.fat {
    font-weight: 700;
    font-size: 15px;
    color: #4d4d4d;
}
.pad_md2 {
    padding: 1vh 1vw;
}
.pad_md25 {
    padding: 1.5vh 1.5vw;
}

.paymentOption {
    border: 1px solid transparent;
    padding: 1.5vh 0;
    font-size: 14px;
    font-weight: 400;
    width: 50%;
    text-align: center;
}
.selectedPayment {
    color: #2db24e;
    border-color: #2db24e;
}
.notSelectedPaymentOption {
    color: #666666;
    border-color: #cccccc;
}

select {
    font-weight: 400;
    font-size: 13px;
    border-radius: 4px;
    padding: 1vh 1vw;
}
.nameSelect {
    margin-top: 1vh;
    color: #999999;
}
select {
    -webkit-appearance: menulist;
    background-color: #fff;
}
.nameSelect.option:not(:first-of-type) {
    color: #333333;
    background-color: #fff;
}
.payment_form {
    padding: 0.5vh 0.5vw;
    border: 1px solid #cccccc;
    color: #cccccc;
    margin: 0.5vh 1.5vh 0 0;
    border-radius: 8px;
    width: 100%;
    display: flex;
}
</style>
