<template>
    <span>
            <span v-if="displayMode == 'modal'">
                <modal v-if="showLogModal == true" @closeme="showLogModal = false">
                    <h3>Logs</h3>
                    <div v-if="commentPath != ''"
                        class="shadowbox">
                        <input type="text" 
                            v-model="newcomment"
                            placeholder="Leave a comment" />
                        <button @click="submitComment" class="button">Submit</button>
                    </div>
                    <div class="shadowbox">
                        <div v-for="(l, i) in logData" 
                            class="card-1" :key="i">
                            <div style="font-size: 0.7em; line-height: 0.8em;">
                                {{ l.manager_firstname }}
                                {{ l.manager_lastname }} -
                                {{ showDate(l.timestamped) }}
                            </div>
                            <div v-if="l.system_comment != ''">
                                {{ l.system_comment }}
                            </div>
                            <div v-if="l.comment != ''" 
                                style="font-weight: bold;">
                                {{ l.comment }}
                            </div>
                        </div>
                    </div>
                </modal>
                <a @click="pullLogs">{{ linkText }}</a>
            </span>
            <span v-else-if="displayMode == 'inline'">
                <h3>Logs</h3>
                <div v-if="commentPath != ''"
                    class="shadowbox">
                    <input type="text" 
                        v-model="newcomment"
                        placeholder="Leave a comment" />
                    <button @click="submitComment">Submit</button>
                </div>
                <div class="shadowbox">
                    <div v-for="(l, i) in logData" 
                        class="card-1" :key="i">
                        <div style="font-size: 0.7em; line-height: 0.8em;">
                            {{ l.manager_firstname }}
                            {{ l.manager_lastname }} -
                            {{ showDate(l.timestamped) }}
                        </div>
                        <div v-if="l.system_comment != ''">
                            {{ l.system_comment }}
                        </div>
                        <div v-if="l.comment != ''" 
                            style="font-weight: bold;">
                            {{ l.comment }}
                        </div>
                    </div>
                </div>
            </span>
        </span>
</template>

<script>
import axios from 'axios'
import modal from '@/components/Modal'
import moment from 'moment'
import mxNotify from '@/components/mx-notify.js'
export default {
    name: "LogDisplay",
    components: {
        modal
    },
    mixins: [mxNotify],
    props: {
        queryId: {
            type: [Number, String],
            required: true,
        },
        loadPath: {
            type: String,
            required: true,
        },
        commentPath: {
            type: String,
            required: false,
            default: '',
        },
        linkText: {
            type: String,
            required: false,
            default: '[logs]',
        },
        displayMode: {
            type: String,
            required: false,
            default: 'modal', // modal, inline
        },
    },
    data() {
        return {
            showLogModal: false,
            newcomment: '',
            logData: [],
        };
    },
    created: function() {
        if (this.displayMode == 'inline') {
            this.pullLogs();
        }
    },
    methods: {
        pullLogs() {
            let submitObj = { queryId: this.queryId };

            axios.get(this.loadPath, {params: submitObj})
                .then(resData => {
                    this.logData = resData.data;
                    this.showLogModal = true;
                })
        },
        submitComment: function() {
            if (this.newcomment.length < 1)
                return false;
            this.ntfInfo("Sending Data", true);
            let submitObj = { 
                queryId: this.queryId,
                comment: this.newcomment,
            };
            axios.get(this.commentPath, {params: submitObj})
                .then(resData => {
                    this.newcomment = '';
                    this.logData = resData.data;
                    this.ntfSuccess("Log Saved", true);
                })
                .catch(err => {
                    this.ntfError("Oops. Something went wrong. Please try again later!", true);
                    console.log(err);
                })
        },
        showDate(str) {
            return moment(str).format('YYYY-MM-DD HH:mm:ss');
        }   
    },
}
</script>

<style>

</style>