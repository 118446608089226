<template>
    <section id="main">
        <div class="container main_container noHeaderPicSpace">
            <section id="content">
                <div>
                    <ClassViewTopNav
                        :user-level="userLevel"
                        :cid="parseInt(cid)"
                    />
                </div>

                <!-- Vue -->
                <div id="vueContainer" v-cloak>
                    <!-- add modal -->
                    <Modal
                        v-if="showAddStudentModal == true"
                        @closeme="showAddStudentModal = false"
                    >
                        <h3>학생 추가</h3>

                        <div class="shadowbox">
                            <input
                                type="text"
                                v-model="studentSearch"
                                placeholder="Student name"
                                style="display: inline; width: auto"
                            />
                            <button @click="searchStudent" class="small alt">
                                search
                            </button>

                            <div>
                                <div
                                    v-if="searchStudentRes.length > 0"
                                    class="card-2"
                                >
                                    <h4>
                                        {{ searchStudentRes.length }} results
                                    </h4>
                                    <div class="table-div">
                                        <div class="th">
                                            <div>Name</div>
                                            <div>Email</div>
                                            <div>Joined</div>
                                            <div></div>
                                        </div>
                                        <div
                                            v-for="(item,
                                            sindex) in searchStudentRes"
                                            :key="sindex"
                                            style="margin-bottom: 1vh;"
                                        >
                                            <div>
                                                {{ item.firstname }}
                                                {{ item.nickname }}
                                                {{ item.lastname }}
                                            </div>
                                            <div>{{ item.email }}</div>
                                            <div>
                                                {{ showDate(item.joined) }}
                                            </div>
                                            <div>
                                                [
                                                <a @click="addStudent(item.id)"
                                                    >ADD student</a
                                                >
                                                ]
                                            </div>
                                            <div>
                                                [
                                                <a
                                                    target="_blank"
                                                    :href="
                                                        getProfileLink(sindex)
                                                    "
                                                >
                                                    profile</a
                                                >
                                                ]
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            @click="showAddStudentModal = false"
                            class="button primary"
                            style="margin-top: 1vh;"
                        >
                            Cancel
                        </button>
                    </Modal>

                    <!-- show lists of students -->
                    <h2>{{ cData.title }} - Students</h2>
                    <div>
                        <LogDisplay
                            :query-id="parseInt(cData.id)"
                            :load-path="paths.logs"
                            :comment-path="paths.logComment"
                            display-mode="modal"
                        ></LogDisplay>
                    </div>

                    <!-- add -->

                    <button
                        v-if="canModify"
                        class="button primary"
                        @click="openAddModal"
                    >
                        학생 추가
                    </button>

                    <div id="showStudents" class="shadowbox">
                        <h4>수업 승인한 학생</h4>
                        <div class="table-div" style="width: 100%;">
                            <div class="th">
                                <div>#</div>
                                <div>학생 이름</div>
                                <div>이메일</div>
                                <div>가입 일자</div>
                                <div v-if="canModify"></div>
                            </div>
                            <div v-for="(item, sindex) in sData" :key="sindex">
                                <div>{{ sindex + 1 }}</div>
                                <div>
                                    <a
                                        :href="
                                            pathAuthPrefix +
                                                paths.studentProfile +
                                                item.id
                                        "
                                        :title="item.email"
                                        style="font-weight: bold;"
                                    >
                                        {{ item.firstname }}
                                        {{ item.nickname }} {{ item.lastname }}
                                    </a>
                                </div>
                                <div>{{ item.email }}</div>
                                <div>{{ showDate(item.joined) }}</div>
                                <div v-if="canModify">
                                    <a @click="downgradeStudent(item.id)"
                                        >[승인 취소]</a
                                    >
                                    <a @click="dropStudent(item.id)"
                                        >[삭제하기]</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <small v-if="canModify">
                            "승인 취소"= 학생의 상태를 "등록"에서 "관심"으로
                            변경<br />
                            ＂삭제하기"=이 수업에서 학생을 수업에서 내보내기
                        </small>
                    </div>

                    <div style="margin-top: 4vh;"></div>

                    <div id="showStudents" class="shadowbox">
                        <h4>수업 신청한 학생</h4>
                        <div class="table-div" style="width: 100%;">
                            <div class="th">
                                <div>#</div>
                                <div>학생 이름</div>
                                <div>이메일</div>
                                <div>가입 일자</div>
                                <div v-if="canModify"></div>
                            </div>
                            <div v-for="(item, sindex) in iData" :key="sindex">
                                <div>{{ sindex + 1 }}</div>
                                <div>
                                    <a
                                        :href="
                                            pathAuthPrefix +
                                                paths.studentProfile +
                                                item.id
                                        "
                                        :title="item.email"
                                        style="font-weight: bold;"
                                    >
                                        {{ item.firstname }}
                                        {{ item.nickname }} {{ item.lastname }}
                                    </a>
                                </div>
                                <div>{{ item.email }}</div>
                                <div>{{ showDate(item.joined) }}</div>
                                <div v-if="canModify">
                                    <a @click="acceptStudent(item.id)"
                                        >승인하기</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import LogDisplay from "@/components/LogDisplay";
import outputHelpers from "@/components/mixin-output-helpers.js";
import Modal from "@/components/Modal";
import moment from "moment";
import ClassViewTopNav from "@/components/ClassViewTopNav";
export default {
    name: "ClassViewStudents",
    mixins: [outputHelpers],
    components: {
        LogDisplay,
        Modal,
        ClassViewTopNav,
    },
    data() {
        return {
            userLevel: "",
            sData: [],
            iData: [],
            cData: {},
            cid: "",
            studentSearch: "",
            searchStudentPath: "/user/search",
            searchStudentRes: [],
            selectedStudent: {},
            showAddStudentModal: false,

            dp: {
                //datepicker
                mode: "single",
                sel: null,
                attr: [],
            },

            loadedDocs: {
                sa: {},
                goals: {},
            },

            paths: {
                logs: "/logs/pull/crs",
                logComment: "/logs/push/crs",
                addStudent: "/student/add",
                dropStudent: "/student/drop",
                acceptStudent: "/student/accept",
                downgradeStudent: "/student/downgrade",
                studentProfile: "/client/profile/",
            },
        };
    },
    created() {
        this.cid = this.$route.params.cid;
        this.userLevel = this.$store.state.currentUser.level;

        axios
            .get("/course/students/init", { params: { cid: this.cid } })
            .then((res) => {
                this.cData = res.data.cData;
                this.sData = res.data.confirmed;
                this.iData = res.data.interested;
            })
            .catch((err) => console.log(err));
    },
    computed: {
        pathAuthPrefix() {
            if (
                this.$store.state.currentUser.level ==
                this.$store.state.lvl.admin
            )
                return "/admin";
            else return "/coach";
        },
        canModify() {
            return parseInt(this.userLevel) >= this.$store.state.lvl.admin;
        },
    },
    methods: {
        openAddModal: function() {
            this.studentSearch = "";
            this.searchStudentRes = [];
            this.selectedStudent = {};
            this.showAddStudentModal = true;
        },
        searchStudent: function() {
            this.selectedStudent = {};
            let pl = {
                searchString: this.studentSearch,
                level: 1,
            };
            axios
                .get(this.searchStudentPath, { params: pl })
                .then((resData) => {
                    this.searchStudentRes = resData.data;
                })
                .catch((err) => console.log(err));
        },
        getProfileLink: function(sindex) {
            let sid = this.searchStudentRes[sindex].id;
            return this.pathAuthPrefix + this.paths.studentProfile + sid;
        },
        addStudent: function(sid) {
            this.studentSearch = "";
            this.searchStudentRes = [];
            let pl = {
                cid: this.cData.id,
                sid: sid,
            };

            axios
                .get(this.paths.addStudent, { params: pl })
                .then((res) => {
                    this.refreshStudentList(res.data);
                })
                .catch((err) => console.log(err));
        },
        dropStudent: function(sid) {
            if (!confirm("Are you sure?")) return false;

            let pl = {
                cid: this.cData.id,
                sid: sid,
            };

            axios
                .get(this.paths.dropStudent, { params: pl })
                .then((res) => {
                    this.refreshStudentList(res.data);
                })
                .catch((err) => console.log(err));
        },
        acceptStudent: function(sid) {
            if (!confirm("Are you sure?")) return false;

            let pl = {
                cid: this.cData.id,
                sid: sid,
            };

            axios
                .get(this.paths.acceptStudent, { params: pl })
                .then((res) => {
                    this.refreshStudentList(res.data);
                })
                .catch((err) => console.log(err));
        },
        downgradeStudent: function(sid) {
            if (!confirm("Are you sure?")) return false;

            let pl = {
                cid: this.cData.id,
                sid: sid,
            };
            axios
                .get(this.paths.downgradeStudent, { params: pl })
                .then((res) => {
                    this.refreshStudentList(res.data);
                })
                .catch((err) => console.log(err));
        },

        //
        // dateStringToObj: function(string) { // YYYY-MM-DD
        //     if (string == undefined)
        //         return null;
        //     let year = string.substring(0,4);
        //     let month = string.substring(5,7) - 1;
        //     let day = string.substring(8,10);
        //     return new Date(year, month, day);
        // },
        refreshStudentList: function(resData) {
            // if (this.showAddStudentModal)
            //     this.showAddStudentModal = false;
            this.sData = resData.confirmed;
            this.iData = resData.interested;
        },
        showDate(str) {
            return moment(str).format("YYYY-MM-DD HH:mm");
        },
    },
};
</script>

<style></style>
