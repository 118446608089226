<template>
    <section id="main">
        <div class="container main_container noHeaderPicSpace">
            <section id="content">
                <div>
                    <ClassViewTopNav
                        :user-level="userLevel"
                        :cid="parseInt(cid)"
                    />
                </div>

                <!-- Vue -->
                <div id="vueContainer" v-cloak>
                    <modal v-if="add.show == true" @closeme="add.show = false">
                        <h3>추가</h3>
                        <div class="shadowbox">
                            <input
                                type="text"
                                v-model="add.searchTeacher"
                                placeholder="Search for teacher by name or email"
                            />
                            <br />
                            <button @click="searchTeacher" class="button alt">
                                검색
                            </button>
                        </div>
                        <div v-if="add.searchRes.length > 0" class="shadowbox">
                            <div
                                v-for="(r, rindex) in add.searchRes"
                                :key="rindex"
                            >
                                <a @click="selectTeacher(rindex)">
                                    {{ r.firstname }} {{ r.lastname }}
                                </a>
                            </div>
                        </div>
                        <div
                            v-if="add.selectedTeacher != null"
                            class="shadowbox"
                        >
                            {{ add.selectedTeacher.firstname }}
                            {{ add.selectedTeacher.lastname }}
                            배정하시겠습니까?
                            <br />
                            <button @click="addTeacher" class="button primary">
                                추가
                            </button>
                        </div>
                    </modal>

                    <h2>{{ cdata.title }} - 선생님</h2>

                    <button @click="openAddModal" class="button primary">
                        Add
                    </button>
                    <div
                        id="showTeachers"
                        class="shadowbox table-div"
                        style="width: 100%;"
                    >
                        <div class="th">
                            <div>선생님 이름</div>
                            <div>가입 일자</div>
                            <div></div>
                        </div>
                        <div v-for="(t, tindex) in teachers" :key="tindex">
                            <div>
                                {{ t.firstname }}
                                {{ t.lastname }}
                            </div>
                            <div>{{ showDate(t.joined) }}</div>
                            <div>
                                <a @click="removeTeacher(t.id)">삭제하기</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import modal from "@/components/Modal";
import moment from "moment";
import ClassViewTopNav from "@/components/ClassViewTopNav";
export default {
    name: "ClassViewTeachers",
    components: {
        ClassViewTopNav,
        modal,
    },
    data() {
        return {
            cid: "",
            userLevel: "",
            cdata: [],
            paths: {
                searchPath: "/user/search",
                addPath: "/teacher/add",
                removePath: "/teacher/remove",
            },
            add: {
                show: false,
                searchTeacher: "",
                searchRes: [],
                selectedTeacher: null,
            },
        };
    },
    created() {
        this.cid = this.$route.params.cid;
        this.userLevel = this.$store.state.currentUser.level;

        axios
            .get("/course/teachers/init", { params: { cid: this.cid } })
            .then((res) => {
                this.cdata = res.data.cData;
                console.log(res.data);
            })
            .catch((err) => console.log(err));
    },
    computed: {
        teachers: function() {
            return this.cdata.teachers;
        },
    },
    methods: {
        openAddModal: function() {
            this.add.searchTeacher = "";
            this.add.searchRes = [];
            this.add.selectedTeacher = null;
            this.add.show = true;
        },
        searchTeacher: function() {
            if (this.add.searchTeacher.length == 0) return false;

            let pl = {
                searchString: this.add.searchTeacher,
                level: 2,
            };
            axios
                .get(this.paths.searchPath, { params: pl })
                .then((resData) => {
                    console.log(resData);
                    this.add.searchRes = resData.data;
                })
                .catch((err) => console.log(err));
        },
        selectTeacher: function(rindex) {
            this.add.selectedTeacher = this.add.searchRes[rindex];
        },
        addTeacher() {
            let submitObj = {
                cid: this.cdata.id,
                tid: this.add.selectedTeacher.id,
            };
            axios
                .get(this.paths.addPath, { params: submitObj })
                .then((resData) => {
                    this.cdata = resData.data;
                    this.add.show = false;
                })
                .catch((err) => console.log(err));
        },
        removeTeacher(tid) {
            if (!confirm("Are you sure?")) return false;
            let submitObj = {
                cid: this.cdata.id,
                tid: tid,
            };
            axios
                .get(this.paths.removePath, { params: submitObj })
                .then((resData) => {
                    this.cdata = resData.data;
                })
                .catch((err) => console.log(err));
        },
        showDate(string) {
            if (string == 0) return "";
            return moment(string).format("YYYY-MM-DD hh:mm (ddd)");
        },
    },
};
</script>

<style></style>
