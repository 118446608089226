<template>
    <section id="main main_container noHeaderPicSpace">
        <!-- VUE -->
        <div class="container" id="vueContainer" v-cloak>
            <!-- body -->
            <div class="row gtr-200">
                <!-- left -->
                <div class="col-3 col-12-medium">
                    <section id="sidebar">
                        <strong>
                            Other class dates
                        </strong>
                        <div class="table-div">
                            <div v-for="(date, i) in sesListAug" :key="i">
                                <div>{{ date.displayWeekDay }}</div>
                                <div>
                                    <span v-if="date.id != sesData.id">
                                        <a
                                            :href="
                                                pathPrefix +
                                                    paths.setAttend +
                                                    date.id
                                            "
                                        >
                                            {{ date.displayStart }}
                                        </a>
                                    </span>
                                    <span v-else>{{ date.displayStart }}</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <!-- right -->
                <div class="col-9 col-12-medium imp-medium">
                    <section id="content">
                        <div>
                            <a :href="classPath">Back to Class View</a>
                        </div>

                        <div>
                            <h2>Set Attendance</h2>
                            Class: <strong>{{ sesData.course_title }}</strong
                            ><br />
                            Session: <strong>{{ displayStart }}</strong
                            ><br />
                            Duration:
                            <strong>{{ sesData.duration }} minutes</strong>
                            <div>
                                <LogDisplay
                                    :query-id="parseInt(sesData.id)"
                                    :load-path="paths.logs"
                                    :comment-path="paths.logComment"
                                ></LogDisplay>
                            </div>

                            <div class="table-div" style="margin-top: 2vh;">
                                <div class="th">
                                    <div></div>
                                    <div>Student</div>
                                    <div>Status</div>
                                    <div></div>
                                    <div>Set</div>
                                    <div></div>
                                    <div>Comment</div>
                                </div>
                                <div v-for="(s, sindex) in aData" :key="sindex">
                                    <div>{{ sindex + 1 }}</div>
                                    <div>
                                        <a
                                            :href="
                                                paths.studentProfile +
                                                    s.student_id
                                            "
                                            target="_blank"
                                            >{{ s.custom_id }}</a
                                        >
                                        {{ s.firstname }}
                                        <span v-if="s.nickname != ''">{{
                                            s.nickname
                                        }}</span>
                                        {{ s.lastname }}
                                    </div>
                                    <div>
                                        <span
                                            v-html="
                                                showAttendance(s.att_status)
                                            "
                                        ></span>
                                    </div>
                                    <div>
                                        <span v-if="s.att_status == 't'">
                                            <input
                                                type="number"
                                                v-model="s.att_missed_minutes"
                                                min="0"
                                                :max="sesData.duration"
                                                step="5"
                                                @input="
                                                    checkTardyMinutes(sindex)
                                                "
                                                style="width: 3em;"
                                            />
                                            minutes
                                        </span>
                                    </div>
                                    <div>
                                        <a
                                            style="background-color: lightseagreen; color: white; padding-left: 0.5vw; padding-right: 0.5vw; "
                                            @click="
                                                selectAttendanceStatus(
                                                    'p',
                                                    sindex
                                                )
                                            "
                                            >P</a
                                        >
                                        <a
                                            style="background-color: lightsalmon; color: white; padding-left: 0.5vw; padding-right: 0.5vw;"
                                            @click="
                                                selectAttendanceStatus(
                                                    't',
                                                    sindex
                                                )
                                            "
                                            >T</a
                                        >
                                        <a
                                            style="background-color: crimson; color: white; padding-left: 0.5vw; padding-right: 0.5vw;"
                                            @click="
                                                selectAttendanceStatus(
                                                    'a',
                                                    sindex
                                                )
                                            "
                                            >A</a
                                        >
                                    </div>
                                    <div>
                                        <span v-if="s.notYetSaved == true"
                                            >(not saved)</span
                                        >
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="s.att_comment"
                                            maxlength="255"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                v-if="canSave"
                                @click="submitAttendance"
                                class="button"
                            >
                                Save
                            </button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import LogDisplay from "@/components/LogDisplay";
import moment from "moment";
import mxNotify from "@/components/mx-notify.js";
import Vue from "vue";

export default {
    name: "ClassViewSetAttendance",
    mixins: [mxNotify],
    components: {
        LogDisplay,
    },
    data() {
        return {
            sesData: [],
            sesList: [],
            aData: [],
            sid: "",

            paths: {
                setAttend: "/course/attendance/set/",
                submit: "/session/attendance/save",
                logs: "/logs/pull/ses",
                logComment: "/logs/push/ses",
                studentProfile: "/m/students/",
            },
        };
    },
    created() {
        this.sid = this.$route.params.sid;

        axios
            .get("/session/setattend/init", { params: { sid: this.sid } })
            .then((res) => {
                this.aData = res.data.aData;
                this.sesData = res.data.sData;
                this.sesList = res.data.sesList;
                console.log(res.data);
            })
            .catch((err) => console.log(err));
    },
    computed: {
        pathPrefix() {
            return this.$store.getters.GET_PATH;
        },
        sesListAug() {
            return this.sesList.map(function(el) {
                let out = el;
                let m = moment(el.start);
                out.displayStart = m.format("YYYY-MM-DD HH:mm");
                out.displayWeekDay = m.format("ddd");
                return out;
            });
        },
        displayStart() {
            return moment(this.sesData.start).format("dddd YYYY-MM-DD HH:mm");
        },
        classPath() {
            return `${this.pathPrefix}/course/attendance/${this.sesData.course_id}`;
        },
        canSave() {
            if (this.aData.length > 0) return true;
            else return false;
        },
    },
    watch: {},
    beforeMount() {
        this.addNotYetSaved();
    },
    methods: {
        addNotYetSaved() {
            for (var i = 0; i < this.aData.length; i++) {
                if (this.aData[i].att_status == "0") {
                    this.aData[i].att_status = "p";
                    Vue.set(this.aData[i], "notYetSaved", true);
                } else {
                    Vue.set(this.aData[i], "notYetSaved", false);
                }
            }
        },
        showAttendance(status) {
            let color;
            let statusWord;
            if (status == "p") {
                color = "lightseagreen";
                statusWord = "PRESENT";
            } else if (status == "t") {
                color = "lightsalmon";
                statusWord = "TARDY";
            } else if (status == "a") {
                color = "crimson";
                statusWord = "ABSENT";
            }
            return (
                '<span style="font-weight: bold; color: ' +
                color +
                ';">' +
                statusWord +
                "</span>"
            );
        },
        selectAttendanceStatus(status, sindex) {
            this.aData[sindex].att_status = status;
        },
        checkTardyMinutes(sindex) {
            //check boundaries
            let missed = parseInt(this.aData[sindex].att_missed_minutes);
            let dur = parseInt(this.sesData.duration);
            let corrected = missed;

            if (missed == "" || missed < 0) corrected = 0;
            else if (missed > dur) corrected = dur;

            this.aData[sindex].att_missed_minutes = corrected;
        },
        submitAttendance() {
            let submitObj = {
                sessionId: this.sesData.id,
                data: [],
            };
            submitObj.data = this.aData.map(function(el) {
                return {
                    studentId: el.student_id,
                    status: el.att_status,
                    missedMinutes: el.att_missed_minutes,
                    comment: el.att_comment,
                };
            });

            this.ntfInfo("saving");
            axios
                .post(this.paths.submit, { submitObj })
                .then((resData) => {
                    this.ntfSuccess("saved", true);
                    this.aData = resData.data;
                    this.addNotYetSaved();
                })
                .catch((err) => console.log(err));
        },
    },
};
</script>

<style></style>
