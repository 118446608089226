<template>
    <section id="main">
    <div class="container main_container noHeaderPicSpace">

        <section id="content">
            <div>
				<ClassViewTopNav 
					:user-level="userLevel"
					:cid="parseInt(cid)"/>
            </div>

            <!-- Vue -->
            <div id="vueContainer" v-cloak>
                <h2>{{cdata.title}} - Sessions</h2>
                
        <!-- MODAL ADD SESSIONS -->
                <modal v-if="showAddDateModal == true" @closeme="showAddDateModal = false">
                    <H2>Add New Dates</H2>

                    <div class="shadowbox" style="width: 100%;">
                        <div>
                            Classes usually start at:
                            <TimePicker @emittime="getPickerTime" :h="insertData.hour" :m="insertData.minutes"></TimePicker>
                        </div>
                        <div>
                            Class duration:
                            <input type="number" v-model="insertData.duration" style="width: 5em;" /> minutes
                        </div>
                        <div>
                            <vc-date-picker
                                v-model="insertData.dates"
                                :attributes="addCalAttribs"
                                is-inline
                                mode="multiple">
                            </vc-date-picker>
                        </div>

                        <div>{{ insertData.dates.length }} dates selected</div>
                        <button @click="submitSessions" class="button">Save</button>
                        
                    </div>
                </modal>

        <!-- MODAL LOGS -->
                <modal v-if="showLogModal == true" @closeme="showLogModal = false">
                    <h2>{{ trimDateTime(logData.date) }}</h2>
                    <div class="shadowbox">
                        <p v-if="logData.loading == true">loading ...</p>
                        <div v-for="(item, idx) in logData.logs" style="margin-top: 0.5vh;" :key="idx">
                            <span style="font-size: 0.7em;">
                                {{ item.timestamped }} 
                                {{ item.manager_firstname }}
                                {{ item.manager_lastname }}
                            </span>
                            <br />
                            {{ item.system_comment }}
                            <br />
                            {{ item.comment }}
                        </div>
                    </div>
                </modal>

        <!--  MODAL EDIT SESSION -->
                <modal v-if="showEditModal == true" @closeme="showEditModal = false">
                    <h2>Edit Session</h2>
                    <strong>
                        {{ trimDateTime(editData.date) }}
                    </strong>
                    <div class="shadowbox" style="width: 100%;">
                        
                        <strong>Edit Date:</strong>
                        <vc-date-picker
                            v-model="editData.newDate"
                            mode="single"
                            :attributes="editData.attribs"
                            is-inline>
                        </vc-date-picker>
                        <br />
                        
                        <strong>Edit Time:</strong>
                        <TimePicker @emittime="getEditPickerTime" :h="editData.hour" :m="editData.minutes"></TimePicker>
                        <br />

                        <strong>Edit Class Duration:</strong>
                        <input type="number" v-model="editData.duration" style="width: 3em;" />
                        <br />

                        <button class="primary button" @click="updateSession">Submit</button>

                    </div>
                </modal>

        <!-- GOOGLE MEET MODAL -->
                <modal v-if="googleMeet.show == true" @closeme="googleMeet.show = false">
                    <h2>Add/Edit Google Meet Link</h2>

                    <div style="margin-top: 3vh;"></div>
                    Current Google Meet Link:
                    <div v-if="googleMeet.item.google_meet_url != ''">
                        <a :href="googleMeet.item.google_meet_urll">{{ googleMeet.item.google_meet_url }}</a>
                    </div>
                    <div v-else><i>no room set</i></div>

                    <div style="margin-top: 3vh;"></div>
                    <div>
                        <input v-model="googleMeet.newURL" 
                            placeholder="Enter a Google Meet URL"/>
                        <div style="margin-top: 1.5vh;"></div>
                        <button @click="submitgoogleMeetURL"
                            class="button"> Submit 
                        </button>
                    </div>
                </modal>

        <!--  SHOW SESSIONS -->
                <div>
                    {{ sesData.length }} 세션 -
                    {{ totalSessionMinutes }} 분
                </div>
                <div v-if="canModifyAdmin">
                    <button @click="showAddDateModal = true" class="primary button">세션 추가</button>
                </div>
                <div id="showSessions" class="shadowbox">
                    <div class="table-div">
                        <div class="th">
                            <div>#</div>
                            <div></div>
                            <div>수업 일정</div>
                            <div>시간 (분)</div>
                            <div>코멘트</div>
                            <div></div>
                        </div>
                        <div v-for="(ses, sindex) in sesData" :key="sindex">
                            <div>{{ sindex + 1 }}</div>
                            <div>{{ ses.displayWeekDay }}</div>
                            <div>{{ ses.displayStart }}</div>
                            <div style="text-align: right;">{{ ses.duration }}</div>
                            <div>
                                <span v-if="ses.expand">
                                    <div v-if="canModifyAdmin">
                                        <textarea v-model="ses.comment"></textarea>
                                        <button @click="updateComment(sindex)"
                                            class="button primary small">save</button>
                                        <button @click="ses.expand = false"
                                            class="button alt small">cancel</button>
                                    </div>
                                    <div v-else>
                                        {{ ses.comment }}
                                        <a @click="ses.expand = false">...</a>
                                    </div>
                                </span>
                                <span v-else>
                                    {{ ses.comment.substr(0, 25) }}
                                    <a @click="ses.expand = true">...</a>
                                </span>                                
                            </div>
                            <div>
                                <span v-if="canModifyCoach">
                                    <a @click="loadGoogleMeetModal(sindex)"
                                        title="add Google Meet Link"><i class="fa fa-video"></i></a>
                                    <span v-if="ses.google_meet_url != ''"
                                        style="color: forestgreen;"><i class="fa fa-check"></i></span>
                                    <span v-else
                                        style="color: crimson;"><i class="fa fa-times"></i></span>
                                </span>                              
                                <span v-if="canModifyAdmin">
                                    <LogDisplay
                                        :query-id="ses.id"
                                        :load-path="paths.logPull"
                                        :comment-path="paths.logPush"></LogDisplay>
                                    <a @click="editSession(sindex)"
                                        title="edit"><i class="fa fa-edit"></i></a>
                                    <a @click="toggleValid(sindex)"
                                        title="delete"><i class="fa fa-times"></i></a>                              
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="sesDelData.length > 0" style="margin-top: 2vh;">
                    <h2>Deleted Sessions</h2>
                    <div id="showDelSessions" class="shadowbox table-div">
                        <div class="th">
                            <div>#</div>
                            <div></div>
                            <div>start</div>
                            <div>minutes</div>
                            <div>comment</div>
                            <div></div>
                        </div>
                        <div v-for="(ses, sindex) in sesDelData" :key="sindex">
                            <div>{{ sindex + 1 }}</div>
                            <div>{{ ses.displayWeekDay }}</div>
                            <div>{{ ses.displayStart }}</div>
                            <div style="text-align: right;">{{ ses.duration }}</div>
                            <div>
                                <span v-if="ses.expand">
                                    <textarea v-model="ses.comment"></textarea>
                                    <button @click="updateComment(sindex, true)"
                                        class="button primary small">save</button>
                                    <button @click="ses.expand = false"
                                        class="button alt small">cancel</button>
                                </span>
                                <span v-else>
                                    {{ ses.comment.substr(0, 45) }}
                                    <a @click="ses.expand = true">...</a>
                                </span>
                            </div>
                            <div>
                                <span v-if="canModifyAdmin">
                                    [ <a @click="showLogs(sindex, true)">logs</a> ]
                                    [ <a @click="toggleValid(sindex, true)">restore</a> ]                                
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>  

        </section>

    </div>
</section>

</template>

<script>
import axios from 'axios'
import modal from '@/components/Modal'
import moment from 'moment'
import ClassViewTopNav from '@/components/ClassViewTopNav'
import LogDisplay from '@/components/LogDisplay'
import mxNotify from '@/components/mx-notify.js'
import Validate from '@/components/Validate.js'
import Vue from 'vue'
import vueCalendar from '@/components/mixin-vue-calendar.js'
import TimePicker from '@/components/TimePicker'

export default {
	name: "ClassViewSessions",
	mixins: [vueCalendar, Validate, mxNotify],
	components: {
        ClassViewTopNav,
        // DatePicker,
        LogDisplay,
        modal,
        TimePicker,
	},
    data() {
		return {
			userLevel: '', 
			cid: '',
			sesData: [],
			sesDelData: [],
			cdata: [],
			
			insertData: {
				duration: 60,
				hour: 14,
				minutes: 0,
				dates: [],
			},
			logData:  {
				date: {},
				loading: false,
				logs: [],
			},

			googleMeet: {
				show: false,
				item: {},
				newURL: '',
			}, 

			selectedForEditIndex: 0,
				
			showAddDateModal: false,
			showEditModal: false,
			showLogModal: false,
			submitURL: '/session/insert',
			editURL: '/session/update',
			toggleValidURL: '/session/togglevalid',
			fetchLogURL: '/session/logs',

			paths: {
				updateComment: '/session/updatecomment',
				logPull: '/logs/pull/ses',
				logPush: '/logs/push/ses',

				submitGoogleMeetURL: '/session/insertgooglemeet',
			}
        }
    },
    created() {
		this.cid = this.$route.params.cid;
        this.userLevel = this.$store.state.currentUser.level;

        axios.get('/session/list/init', {params: {cid: this.cid}
            }).then(res => {
                console.log(res)
                this.cdata = res.data.cData;                   
                this.sesData = res.data.sessions;
                this.sesDelData = res.data.sessions_deleted;
                this.augmentData();
            }).catch(err => console.log(err))
    },
    computed: {
        dates() {
            return this.insertData.dates.map(day => day.date);
        },
        addCalAttribs() {
            let out = [
                ...this.sesData.map(function(ses) {
                    return {
                        dates: [new Date(ses.start)],
                        dot: 'red',
                        popover: {
                            label: ses.start.substring(11, 16) + " - " + ses.duration + ' minutes',
                        }
                    };
                })
            ];
            console.log(out);
            return out;
        },
        editData() {
            var originalDate = new Date(this.sesData[this.selectedForEditIndex].start);
            return {
                id: this.sesData[this.selectedForEditIndex].id,
                duration: this.sesData[this.selectedForEditIndex].duration,
                date: originalDate,
                hour: originalDate.getHours(),
                minutes: originalDate.getMinutes(),
                newDate: null,
                attribs: [{
                    dates: [new Date(originalDate)],
                    dot: 'blue',
                }],
            };
        },
        totalSessionMinutes() {
            return this.sesData.reduce(function(acc, el){
                return parseInt(el.duration) + acc;
            }, 0);
        },
        canModifyAdmin() {
            if (this.userLevel >= this.$store.state.lvl.admin) 
                return true;
            else return false;
        },
        canModifyCoach() {
            if (this.userLevel >= this.$store.state.lvl.coach) 
                return true;
            else return false;
        },
        hasValidGoogleMeetURL() {
            if (this.googleMeet.newURL == '') return false;
            else if (this.validate_url(this.googleMeet.newURL)) return true;
            else return false;
        },
    },
    methods: {
        augmentData() {
            for(let i=0; i<this.sesData.length; i++) {
                Vue.set(this.sesData[i], 'expand', false);
                let m = moment(this.sesData[i].start);
                this.sesData[i].displayStart = m.format("YYYY-MM-DD HH:mm");
                this.sesData[i].displayWeekDay = m.format("ddd");
            }
            for(let i=0; i<this.sesDelData.length; i++) {
                Vue.set(this.sesDelData[i], 'expand', false);
                let m = moment(this.sesDelData[i].start);
                this.sesDelData[i].displayStart = m.format("YYYY-MM-DD HH:mm");
                this.sesDelData[i].displayWeekDay = m.format("ddd");
            }
        },
        // dateSelected: function(date, array, object) {
        //     console.log(this.editData.newDate);
        // },
        cutCalendarDates() {
            let cutDates = [];
            for (var i=0; i<this.insertData.dates.length; i++) {
                let dateString = this.trimDate(this.insertData.dates[i]);
                cutDates.push(dateString);
            }
            return cutDates;
        },
        //
        submitSessions() {
            let submitObj = {
                cid: this.cid,
                newDates: this.cutCalendarDates(),
                duration: this.insertData.duration,
                startHour: this.insertData.hour,
                startMinute: this.insertData.minutes,
            };

            axios.post(this.submitURL, {submitObj})
                .then(resData => {
                    this.showAddDateModal = false;
                    this.insertData.dates = [];
                    this.refreshData(resData.data);
                })
                .catch(err => console.log(err))
        },
        //
        editSession(sindex) {
            this.selectedForEditIndex = sindex;
            this.showEditModal = true;
        },   
        updateSession() {
            var newDate = this.trimDate(this.editData.newDate);
            let submitObj = {
                cid: this.cdata.id,
                sessionId: this.editData.id,
                oldDate: this.trimDate(this.editData.date),
                newDate: newDate,
                duration: this.editData.duration,
                startHour: this.editData.hour,
                startMinute: this.editData.minutes,
            };

            axios.post(this.editURL, {submitObj})
                .then(resData => {
                    this.showEditModal = false;
                    this.editData.newDate = null;
                    this.refreshData(resData.data);
                })
                .catch(err => console.log(err))
        },
        //
        updateComment(sindex, deleted = false) {
            let obj;
			if (deleted)
                obj = this.sesDelData[sindex];
            else obj = this.sesData[sindex];

            let pl = {
                cid: this.cdata.id,
                updateId: obj.id,
                comment: obj.comment,
            };
			axios.get(this.paths.updateComment, {params: pl})
				.then(resData => this.refreshData(resData.data))
				.catch(err => console.log(err))
        },
        toggleValid(sindex, deleted = false) {
            let toggleId;
			if (!deleted) {
                if (!confirm("Are you sure?"))
                    return false;

                toggleId  = this.sesData[sindex].id;
            } else {
                toggleId  = this.sesDelData[sindex].id;
            }
            
            let submitObj = {
                cid: this.cdata.id,
                sessionId: toggleId,
                deleted: deleted,
            };

            axios.post(this.toggleValidURL, {submitObj})
                .then(resData => {
                    this.refreshData(resData.data)
                })
                .catch(err => console.log(err))
        },       
        //
        refreshData(resData) {
            this.sesData = resData.sessions;
            this.sesDelData = resData.sessions_deleted;
            this.augmentData();
        },
        showLogs: function(sindex, deleted = false) {
			let sessionId;
            if (!deleted) {
                this.logData.date = new Date(this.sesData[sindex].start);
                sessionId = this.sesData[sindex].id;
            } else {
                this.logData.date = new Date(this.sesDelData[sindex].start);
                sessionId = this.sesDelData[sindex].id;
            }

            this.showLogModal = true;

            let submitObj = {
                sessionId: sessionId,
            };
            this.logData.logs = [];
            this.logData.loading = true;
			axios.get(this.fetchLogURL, {params: submitObj})
				.then(resData => {
					this.logData.logs = resData.data;
					this.logData.loading = false;
				})
				.catch(err => console.log(err))
        },
        getPickerTime: function(h, m) {
            this.insertData.hour = h;
            this.insertData.minutes = m;
        },
        getEditPickerTime: function(h, m) {
            this.editData.hour = h;
            this.editData.minutes = m;
        },
        //
        loadGoogleMeetModal(sindex) {
            this.googleMeet.show = true;
            this.googleMeet.item = this.sesData[sindex];
        },
        submitgoogleMeetURL() {
            if (!this.hasValidGoogleMeetURL) {
                this.ntfError("Please enter a valid URL or leave the input field blank.", true);
                return false;
            }
            this.ntfInfo("Submitting Data");
            let pl = {
                sid: this.googleMeet.item.id,
                cid: this.cdata.id,
                url: this.googleMeet.newURL,
            }
			axios.get(this.paths.submitGoogleMeetURL, {params: pl})
				.then(resData => {
					this.googleMeet.show = false;
					this.googleMeet.item = {};
					this.googleMeet.newURL = '';
					this.sesData = resData.data;
                    this.ntfSuccess("Link saved", true);
					this.augmentData();
				})
				.catch(err => console.log(err))
        },
    }
}
</script>

<style>

</style>