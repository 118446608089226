<template>
    <section id="main">
        <div class="container main_container noHeaderPicSpace">
            <section id="content">
                <div id="vueContainer" v-cloak>
                    <modal
                        v-if="create.show == true"
                        @closeme="create.show = false"
                    >
                        <h3>수업 추가</h3>
                        <div class="shadowbox">
                            <div class="table-div">
                                <div>
                                    <div>코드</div>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="create.code"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>코드 글자색</div>
                                    <div>
                                        <ColorPickerSimple
                                            :initialcolor="create.color"
                                            @colorset="setColor"
                                        ></ColorPickerSimple>
                                    </div>
                                </div>
                                <div>
                                    <div>수업명</div>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="create.title"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                @click="submitNewClass"
                                class="button primary"
                            >
                                저장
                            </button>
                        </div>
                    </modal>

                    <modal
                        v-if="edit.show == true"
                        @closeme="edit.show = false"
                    >
                        <h3>수업 수정</h3>
                        <div class="shadowbox">
                            <div class="table-div">
                                <div>
                                    <div>코드</div>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="edit.code"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>코드 글자색</div>
                                    <div>
                                        <ColorPickerSimple
                                            :initialcolor="edit.color"
                                            @colorset="editColor"
                                        ></ColorPickerSimple>
                                    </div>
                                </div>
                                <div>
                                    <div>수업명</div>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="edit.title"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                @click="submitUpdate"
                                class="button primary"
                            >
                                수정
                            </button>
                        </div>
                    </modal>

                    <h3 v-html="listtitle"></h3>

                    <div>
                        <button @click="openCreateModal" class="primary button">
                            수업 추가
                        </button>
                    </div>

                    <small>
                        <div style="float: left; width: 50%;">
                            <i class="fa fa-user-plus"></i> 수업 신청한 학생<br />
                            <i class="fa fa-user-check"></i> 수업 확정된 학생
                        </div>
                        <div style="float: left; width: 50%;">
                            <i class="fa fa-user-graduate"></i> 선생님 <br />
                            <i class="fa fa-calendar-alt"></i> 세션
                        </div>
                        <div style="clear: both;"></div>
                    </small>

                    <div class="shadowbox" style="margin-top: 2vh;">
                        <div class="table-div" style="width: 99%;">
                            <div class="th">
                                <div style="width: 25%;">수업</div>
                                <div class="center">
                                    <i
                                        class="fa fa-user-plus"
                                        title="Interested Students"
                                    ></i>
                                </div>
                                <div class="center">
                                    <i
                                        class="fa fa-user-check"
                                        title="Confirmed Students"
                                    ></i>
                                </div>
                                <div class="center">
                                    <i
                                        class="fa fa-user-graduate"
                                        title="Teachers"
                                    ></i>
                                </div>
                                <div class="center">
                                    <i
                                        class="fa fa-calendar-alt"
                                        title="Sessions"
                                    ></i>
                                </div>
                                <div>첫수업</div>
                                <div>마지막 수업</div>
                                <div></div>
                            </div>
                            <div
                                v-for="(c, cindex) in classDataAug"
                                :key="cindex"
                            >
                                <div :style="rowCss + 'font-weight: bold;'">
                                    <Classcode
                                        :code="c.code"
                                        :color="c.color"
                                    ></Classcode>
                                    <a :href="paths.class + c.id">{{
                                        c.title
                                    }}</a>
                                </div>
                                <div :style="rowCss" class="center">
                                    {{ c.cnt_student_interested }}
                                </div>
                                <div
                                    :style="
                                        rowCss +
                                            setNumberStyle(
                                                c.cnt_student_confirmed
                                            )
                                    "
                                    class="center"
                                >
                                    {{ c.cnt_student_confirmed }}
                                </div>
                                <div
                                    :style="
                                        rowCss + setNumberStyle(c.cnt_teacher)
                                    "
                                    class="center"
                                >
                                    {{ c.cnt_teacher }}
                                </div>
                                <div
                                    :style="
                                        rowCss + setNumberStyle(c.cnt_session)
                                    "
                                    class="center"
                                >
                                    {{ c.cnt_session }}
                                </div>
                                <div :style="rowCss">
                                    {{ showDate(c.s1_start) }}
                                </div>
                                <div :style="rowCss">
                                    {{ showDate(c.sx_start) }}
                                </div>
                                <div :style="rowCss">
                                    <a @click="openEditModal(cindex)">수정</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <endless
                        ref="endlessLoader"
                        :loadpath="loadMorePath"
                        :perpage="parseInt(perpage)"
                        @loaded="appendLoaded"
                    ></endless>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import Classcode from "@/components/Classcode";
import ColorPickerSimple from "@/components/ColorPickerSimple";
import endless from "@/components/endless";
import mixinOutputHelpers from "@/components/mixin-output-helpers.js";
import moment from "moment";
import modal from "@/components/Modal";
export default {
    name: "ClassListAdmin",
    mixins: [mixinOutputHelpers],
    components: {
        Classcode,
        ColorPickerSimple,
        endless,
        modal,
    },
    data() {
        return {
            classdata: [],
            loadedPeriod: [],
            perpage: "",

            paths: {
                class: "/admin/course/",
                create: "/course/create",
                update: "/course/update",
                loadmore: "/course/loadmore/", // use computed value!
            },

            create: {
                show: false,
                title: "",
                code: "",
                color: "#ffffff",
                pricePerHour: 60000,
            },
            edit: {
                show: false,
                id: 0,
                code: "",
                color: "",
                title: "",
            },

            rowCss: "border-bottom: 1px lightgrey solid;",
        };
    },
    created() {
        let path = "/course/get/";
        path += this.$route.params.mode;
        axios
            .get(path)
            .then((res) => {
                console.log(res.data);
                this.classdata = res.data.cData;
                this.perpage = res.data.perpage;
                this.loadedPeriod = res.data.loaded_period;
            })
            .catch((err) => console.log(err));
        this.resetCreate();
    },
    computed: {
        listtitle() {
            let post = "";
            if (this.loadedPeriod == 1) post = " (Current)";
            else if (this.loadedPeriod == 2) post = " (Future)";
            else if (this.loadedPeriod == 3) post = " (Past)";
            else if (this.loadedPeriod == 0) post = " (No Sessions)";

            return (
                '<span style="background-color: yellow;">' +
                "</span>" +
                " Classes" +
                post
            );
        },
        loadMorePath() {
            return this.paths.loadmore + this.loadedPeriod;
        },
        newcode() {
            return this.create.code;
        },
        editcode() {
            return this.edit.code;
        },
        classDataAug() {
            let tmp = this.classdata;
            return tmp.sort(function(a, b) {
                // have sessions vs no sessions
                let aSes = 0;
                let bSes = 0;
                if (a.sessions.length > 0) aSes = 1;
                if (b.sessions.length > 0) bSes = 1;

                if (aSes > bSes) return -1;
                if (aSes < bSes) return 1;
                return 0;
            });
        },
    },
    watch: {
        newcode(newval) {
            if (newval.length > 10) this.create.code = newval.substr(0, 10);
        },
        editcode(newval) {
            if (newval.length > 10) this.edit.code = newval.substr(0, 10);
        },
    },
    methods: {
        resetCreate() {
            this.create.code = "";
            this.create.color = "#ffffff";
            this.create.title = "";
        },
        openCreateModal() {
            this.resetCreate();
            this.create.show = true;
        },
        setColor(hex) {
            console.log(hex);
            this.create.color = hex;
        },
        editColor(hex) {
            this.edit.color = hex;
        },
        openEditModal(cindex) {
            this.edit.id = this.classdata[cindex].id;
            this.edit.code = this.classdata[cindex].code;
            this.edit.color = "#" + this.classdata[cindex].color;
            this.edit.title = this.classdata[cindex].title;
            this.edit.show = true;
        },
        submitNewClass() {
            if (this.create.title.length < 3) {
                alert("Set a title for the new class.");
                return false;
            }
            let submitObj = {
                code: this.create.code,
                color: this.create.color,
                title: this.create.title,
            };
            axios.post(this.paths.create, { submitObj }).then((resData) => {
                this.classdata = resData.data.cData;
                this.resetEndless();
                this.loadedPeriod = resData.data.loaded_period;
                this.create.show = false;
            });
        },
        submitUpdate() {
            if (this.edit.title.length < 3) {
                alert("Set a title for the new class.");
                return false;
            }
            let submitObj = this.edit;
            submitObj.loadedPeriod = this.loadedPeriod;

            axios
                .post(this.paths.update, { submitObj })
                .then((resData) => {
                    this.resetEndless();
                    this.classdata = resData.data.cData;
                    this.edit.show = false;
                })
                .catch((err) => console.log(err));
        },
        setNumberStyle(num) {
            if (num == 0) return "color: red; font-weight: bold;";
            return "";
        },
        appendLoaded(loadedData) {
            this.classdata.push.apply(this.classdata, loadedData.classes);
        },
        resetEndless() {
            if (this.$refs.endless != undefined) this.$refs.endless.reset();
        },
        showDate(string) {
            if (string == 0) return "";
            return moment(string).format("YYYY-MM-DD hh:mm (ddd)");
        },
    },
};
</script>

<style></style>
