<template>
    <div class="bg_light" style="width: 100%;">
        <img style="width: 100%;" src="@/assets/_img/selfdiagnosis_1.jpg" />
        <div style="padding: 14vh 4vw;">
            <div class="font48 weight500 gray900">
                나는 지금 어떤 상태인가요? <span class="main">자가진단</span>을
                진행해보세요.
            </div>
            <div class="spacer_lg"></div>
            <div class="bg_gray" style=" border-radius: 8px; padding: 2vh 2vw;">
                자가진단은 정확한 진단을 내리기에는 어려움이 있으므로 정확한
                증상과 판단을 위해서는 의사의 진료가 필요합니다.
            </div>
            <div class="spacer_xl"></div>
            <div class="row" style="justify-content: center;">
                <div class="button_big gradient_button ">우울증</div>
                <div class="spacer_h_md"></div>
                <div class="button_big plain_button">스트레스</div>
                <div class="spacer_h_md"></div>
                <div class="button_big plain_button">불안장애</div>
            </div>
            <div class="spacer_xl"></div>
            <div>
                <table>
                    <tr>
                        <th colspan="2">
                            지난 2주일 동안 당신은 다음의 문제들로 인해서 얼마나
                            자주 방해를 받았습니까?
                        </th>
                        <th>전혀 방해받지 않았다</th>
                        <th>며칠동안 방해받았다</th>
                        <th>7일 이상 방해받았다</th>
                        <th>거의 매일 방해받았다</th>
                    </tr>
                    <tr v-for="(r, i) in tData" :key="i">
                        <td class="gray900 weight500 font16">{{ i + 1 }}</td>
                        <td class="font17 weight300">{{ r.txt }}</td>

                        <td v-for="k in 4" :key="k">
                            <input
                                v-if="k === r.val"
                                disabled
                                checked
                                type="radio"
                            />
                            <input v-else disabled type="radio" />
                            <span
                                class="main weight500 font16"
                                style="margin-left: .2vw;"
                                >{{ k }}</span
                            >
                        </td>
                    </tr>
                    <tr>
                        <th colspan="2" style="text-align: right;">
                            <!-- 합계 -->
                        </th>
                        <th colspan="4" style="text-align: right;">
                            <!-- 9 점 -->
                        </th>
                    </tr>
                </table>

                <div class="spacer_lg"></div>
                <div
                    class="bg_gray"
                    style=" border-radius: 8px; padding: 2vh 2vw;"
                >
                    만일 당신이 위의 문제 중
                    <b>하나 이상 예라고 응답</b>하셨으면, 이러한 문제들로 인해서
                    당신을 일을 하거나 가정일을 돌보거나 다른 사람과 어울리는
                    것이 얼마나 어려웠습니까?<br /><br />
                    <input
                        type="radio"
                        disabled
                        class="spacer_h_xs_r spacer_h_lg_l"
                    />전혀 어렵지 않았다
                    <input
                        type="radio"
                        disabled
                        class="spacer_h_xs_r spacer_h_lg_l"
                    />약간 어려웠다
                    <input
                        type="radio"
                        disabled
                        class="spacer_h_xs_r spacer_h_lg_l"
                    />많이 어려웠다
                    <input
                        type="radio"
                        disabled
                        class="spacer_h_xs_r spacer_h_lg_l"
                    />매우 많이 어려웠다
                </div>
                <div class="weight400 font13">
                    출처: Spizer RL, Krownke K, Willams JBW. Validation and
                    utility of a self-report version of PRIME-MD: the PHQ
                    primary care study. The Journal of the American Medical
                    Association. 1999; 282(18); 1737-1744.
                </div>
                <div class="spacer_lg"></div>
                <div
                    class="bg_white"
                    style="border-radius: 40px; padding: 5vh; 4vw;"
                >
                    <p class="weight700 font32 gray900">
                        정서검사 결과, <span class="sub_dark">총 9점</span>으로
                        <span class="sub_dark">경미한 수준</span>입니다.
                    </p>
                    <div class="spacer_lg"></div>
                    <div
                        v-for="(box, i) in blueBox"
                        :key="i"
                        :style="boxStyle(box[3])"
                    >
                        <span
                            style="display: flex; align-items: center; width: 30%; margin-right: 1vw;"
                        >
                            <img
                                src="@/assets/_img/Checks.png"
                                class="spacer_h_md_r"
                                style="height: 24px; width: 24px; "
                            />
                            <p
                                class="weight500 font24 spacer_h_sm_r"
                                style="align-self: center;"
                            >
                                {{ box[0] }}
                            </p>
                            <p
                                class="weight400 font16"
                                style="padding-top: 1.5vh;"
                            >
                                {{ box[1] }}
                            </p></span
                        >

                        <p
                            style="width: 70%; white-space: normal;"
                            class="weight300 font18"
                        >
                            {{ box[2] }}
                        </p>
                    </div>
                </div>
                <div class="spacer_xl"></div>
                <div
                    style="display: flex; align-items: center; flex-direction: column;"
                >
                    <div class="weight500 font28">
                        도움이 필요하시다면
                        <span class="main">웰링비 프로그램</span>을
                        신청해보세요.
                    </div>
                    <div class="spacer_lg"></div>
                    <router-link
                        to="/program"
                        style="padding: 3vh 4vw; background-color: #2DB24E; color: #fff; border-radius: 4px;"
                        class="weight500"
                    >
                        웰링비 프로그램 보러가기<i
                            class="fas fa-arrow-right"
                        ></i>
                    </router-link>
                </div>
                <div class="spacer_lg"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Selfdiagnosis",
    data() {
        return {
            tData: [
                {
                    txt:
                        "일 또는 여가 활동을 하는데 흥미나 즐거움을 느끼지 못함",
                    // val: 2,
                    val: "",
                },
                {
                    txt: "기분이 가라앉거나 우울하거나 희망이 없음",
                    val: "",
                },
                {
                    txt:
                        "잠이 들거나 계속 잠을 자는 것이 어려움 또는 잠을 너무 많이 잠",
                    val: "",
                },
                {
                    txt: "피곤하다고 느끼거나 기운이 거의 없음",
                    val: "",
                },
                {
                    txt: "입맛이 없거나 과식을 함",
                    val: "",
                },
                {
                    txt:
                        "자신을 부정적으로 봄 혹은 자신이 실패자라고 느끼거나 자신 또는 가족을 실망시킴",
                    val: "",
                },
                {
                    txt:
                        "신문을 읽거나 텔레비전 보는 것과 같은 일에 집중하는 것이 어려움",
                    val: "",
                },
                {
                    txt:
                        "다른 사람들이 주목할 정도로 너무 느리게 움직이거나 말을 함또는 반대로 평상시보다 많이 움직여서 너무 안절부절 못하거나 들떠 있음",
                    val: "",
                },
                {
                    txt:
                        "자신이 죽는 것이 더 낫다고 생각하거나 어떤 식으로든 자신을 해칠 것이라고 생각함",
                    val: "",
                },
            ],
            blueBox: [
                [
                    "정상",
                    "총점 0 ~ 4점",
                    "적응상의 지장을 초래할만한 우울 관련 증상을 거의 보고하지 않았습니다.",
                    "#EBF9FB",
                ],
                [
                    "경미한 수준",
                    "총점 5 ~ 9점",
                    "경미한 수준의 우울감이 있으나 일상생활에 지장을 줄 정도는 아닙니다.",
                    "#D7F2F6",
                ],
                [
                    "중간 수준",
                    "총점 10 ~ 14점",
                    `중간 수준의 우울감을 비교적 자주 경험하는 것으로 보고하였습니다.
직업적·사회적 적응에 일부 영향을 미칠 수 있어 주의 깊은 관찰과 관심이 필요합니다.`,
                    "#C2EBF1",
                ],
                [
                    "약간 심한 수준",
                    "총점 15 ~ 19점",
                    `약간 심한 수준의 우울감을 자주 경험하는 것으로 보고하였습니다. 
직업적·사회적 적응에 일부 영향을 미칠 경우, 정신건강 전문가의 도움을 받아보시기를 권해 드립니다.`,
                    "#ADE4EC",
                ],
                [
                    "심한 수준",
                    "총점 20 ~ 27점",
                    `광범위한 우울 증상을 매우 자주 심한 수준에서 경험하는 것으로 보고하였습니다.
일상생활의 다양한 영역에서 어려움이 초래될 경우, 추가적인 평가나 정신건강 전문가의 도움을 받아보시기를 권해 드립니다.`,
                    "#98DEE8",
                ],
            ],
        };
    },
    methods: {
        boxStyle(str) {
            // console.log(str);
            // return "";
            return `padding: 2vh 1vw; color: #134C54; justify-content: flex-start; border-radius: 8px; align-items: center; display: flex; margin-bottom: 1vh; background-color: ${str}`;
        },
    },
};
</script>

<style scoped>
table,
th,
td {
    padding: 1.5vh 1.5vw;
    border: 1px solid #cccccc;
    border-collapse: collapse;
    text-align: center;
}
th {
    background-color: #eff7f1;
    box-shadow: inset -1px 0px 0px #b7f1c5;
    color: #23893c;
    border-color: #e5f9ea;
}
table:last-child {
    border-bottom: 2px solid #23893c;
}
table:first-child {
    border-top: 2px solid #23893c;
}
</style>
