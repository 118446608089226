/**
 * dependencies: axios
 */

<template>
    <div>
        <div v-if="!noMoreItems">
            <div v-if="isloading">loading ... </div>
            <div v-else>
                <a @click="endlessLoad">
                    load more
                </a>
            </div>            
        </div>
        <div v-else>
            -
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: "endless",
    props: {
        loadpath: {
            type: String,
            required: true,
        },
        perpage: {
            type: Number,
            required: false,
            default: 50,
        },
        additionalPayload: {
            type: Object,
            required: false,
            default: null,
        },
        /**
         * return data might not be a simple array but an object whose props contain array(s)
         * in this case, I need to know, which prop / array to check concerning how many items
         * were loaded and whether we have loaded everything.
         * 
         * the name of the above prop is to be saved in "mainprop"
         */
        mainprop: { 
            type: String,
            required: false,
            default: '',
        }
    },
    data: function() {
        return {
            docHeight: 0,
            isloading: false,
            noMoreItems: false,
            loaded: [0], // array of offsets used to load data
        };
    },
    created: function() {
        window.addEventListener('scroll', this.handleScroll);
        this.setDocHeight();
    },
    destroyed: function() {

    },
    computed: {
        lastloaded: function() {
            let idx = this.loaded.length - 1;
            return this.loaded[idx];
        },
        loadOffset: function() {
            return this.lastloaded + this.perpage;
        },
    },
    watch: {},
    methods: {
        setDocHeight() {
            this.docHeight = this.calculateDocHeight();
        },
        calculateDocHeight: function() {
            return Math.max(
                document.documentElement["clientHeight"],
                document.body["scrollHeight"],
                document.documentElement["scrollHeight"],
                document.body["offsetHeight"],
                document.documentElement["offsetHeight"]
            );
        },
        endlessLoad: function() {
            if (this.isloading || this.noMoreItems)
                return false;
            this.isloading = true;
            let payload = {
                offset: this.loadOffset, 
                perpage: this.perpage,
            };
            for (var key in this.additionalPayload) {
                if (Object.prototype.hasOwnProperty.call(this.additionalPayload, key)) {
                // if (this.additionalPayload.hasOwnProperty(key)) {
                    payload[key] = this.additionalPayload[key];
                }
            }
                        
            axios.get(this.loadpath, {params: payload})
                .then(resData => {
                    this.isloading = false;
                    this.loaded.push(this.loadOffset);

                    if (resData.data.constructor === Array) {
                        if (resData.data.length < this.perpage) 
                            this.noMoreItems = true;
                    
                        if (resData.data.length > 0)
                            this.$emit('loaded', resData.data);
                    } else { // might load an object with arrays in props

                        // mainprop shoud indicate the property which contains the main data array
                        if (this.mainprop != '') {
                            if (resData.data[this.mainprop].length < this.perpage)
                                this.noMoreItems = true;

                            if (resData.data[this.mainprop].length > 0)
                                this.$emit('loaded', resData.data);

                        } else {
                            this.$emit('loaded', resData.data);
                        }
                    }
                })
                .catch(err => console.log(err))
        },

        handleScroll: function() {
            this.setDocHeight();
            let y1 = window.innerHeight + window.scrollY;
            let y2 = this.docHeight - 40; // start scrolling a bit early
            if (y1 >= y2) {
                // console.log(y1, y2);
                this.endlessLoad();
            }
        },
        reset: function() { // to be called from parent
            this.loaded = [0];
            this.noMoreItems = false;
        },
    },
}
</script>