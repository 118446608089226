<template>
    <div id="app" style="position: relative;">
        <div style="z-index: 11; height: 5vh; position: absolute; width: 100%;">
            <Header :isMobile="isMobile" />
        </div>
        <div style="position: absolute; top: 0; z-index: 1; width: 100%;">
            <!-- <div style="margin-top: 8vh;"></div> -->

            <transition name="fade" mode="out-in">
                <router-view :key="$route.fullPath" />
            </transition>
            <Footer />
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

// import '@/assets/_skin_mono/plugins/jquery.min.js'
// <!-- skin CSS -->
// import "@/assets/_skin_mono/plugins/bootstrap/bootstrap.min.css?v=2";
// import '@/assets/_skin_mono/plugins/owl-carousel/owl.carousel.min.css'
// import "@/assets/_skin_mono/plugins/owl-carousel/owl.theme.default.min.css";
// import "@/assets/_skin_mono/plugins/magnific-popup/magnific-popup.min.css";
// import "@/assets/_skin_mono/plugins/sal/sal.min.css";
// import "@/assets/_skin_mono/css/theme.css?v=12";
import "@/assets/css/additions_octarine.css";
import "@/assets/css/wellingbe.css?v=4";
import "@/assets/plugins/iziToast.min.css";
// <!-- skin Fonts/Icons -->
import "@/assets/_skin_mono/plugins/font-awesome/css/all.css";
// import "@/assets/_skin_mono/plugins/themify/themify-icons.min.css";
// import "@/assets/_skin_mono/plugins/simple-line-icons/css/simple-line-icons.css";

// import Vuetify from "@/assets/plugins/vuetify.js";

export default {
    name: "App",
    components: {
        Header,
        Footer,
    },
    data: () => ({ isMobile: false }),
    beforeDestroy() {
        if (typeof window === "undefined") return;

        window.removeEventListener("resize", this.onResize, { passive: true });
    },

    mounted() {
        this.onResize();

        window.addEventListener("resize", this.onResize, { passive: true });
    },
    computed: {},
    methods: {
        onResize() {
            this.isMobile = window.innerWidth < 700;
            console.log("vuetify: ", this.$vuetify.breakpoint.mobile);
        },
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.slide-enter-active {
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -moz-transition-timing-function: cubic-bezier(0, 0.5, 0.3, 0.5);
    -webkit-transition-timing-function: cubic-bezier(0, 0.5, 0.3, 0.5);
    -o-transition-timing-function: cubic-bezier(0, 0.5, 0.3, 0.5);
    transition-timing-function: cubic-bezier(0, 0.5, 0.3, 0.5);
}

.slide-enter-to,
.slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
.blog {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-content: center;
    align-items: center;
    padding: 10vh 0 20vw 20vw !important;
    max-width: 85vw;
}
.blog img {
    /* max-height: 50vh; */
    max-width: 35vw !important;
    /* width: auto; */
}
.blog_text {
    margin: 2vh;
    max-width: 35vw;
}
.blog_left_align {
    align-self: flex-start;
    margin-left: 10vw;
}
.text_green {
    color: green;
}
.text_red {
    color: red;
}
.text_orange {
    color: orangered;
}
.text_pink {
    color: hotpink;
}
.text_purple {
    color: purple;
}
.text_blue {
    color: blue;
}
.text_underline {
    text-decoration: underline;
}
/* .spacer {
    margin: 3vh;
} */
i {
    padding: 0 0.5vw;
}
.center {
    text-align: center;
}
button {
    margin: 0 0.5vw;
}
.main_container {
    padding-top: 8vh;
}
</style>
