import {moment} from 'moment'
export default {
    methods: {
        articlePreview: function(string) {
            let len = 250;
            let out = string.substr(0, len);
            if (string.length > len) { out += '...'; }
            return out;
        },
        formatDate: function(string) {
            if (string == '' || string == null)
                return '';
            return moment(string).format("YYYY-MM-DD");
        },      
        showNumber1000: function(x, decimals = null) {
            if (x == null || x == '') 
                return 0;
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (decimals !== null)
                return parseFloat(parts.join(".")).toFixed(decimals);
            else return parts[0];
        },

    }
};