<template>
    <span>
        <span :style="componentstyle">{{ code }}</span>
    </span>
</template>

<script>
export default {
    name: "Classcode",
    props: {
        code: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
    },
    data: function() {
        return {};
    },
    computed: {
        correctedColor: function() {
            if (this.color.length == 7)
                return this.color;
            else if (this.color.length == 6)
                return '#' + this.color;
            else return '';
        },
        fgColor: function() {
            let red = parseInt(this.correctedColor.substr(1, 2), 16);
            let green = parseInt(this.correctedColor.substr(3, 2), 16);
            let blue = parseInt(this.correctedColor.substr(5, 2), 16);

            if ((red*0.299 + green*0.587 + blue*0.114) > 186) 
                return '#000000'; 
            else return '#ffffff'; 
        },        
        componentstyle: function() {
            let out = 'background-color: ' + this.correctedColor + ';';
            out += 'color: ' + this.fgColor + '; font-weight: bold;';
            out += 'padding-left: 2px; padding-right: 2px;';
            return out;
        },
    },
}
</script>

<style>

</style>
