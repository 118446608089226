<template>
    <section class="noHeaderPicSpace">
        <modal
            v-show="showSNSModal"
            @closeme="showSNSModal = false"
            height="35vh"
            width="25vw"
            type="1"
        >
            <div class="font32">손쉽게 가입하는<br />웰링비 SNS 회원가입</div>
            <div
                @click="showSNSModal = true"
                class="pad_md rnd_md"
                style="background-color: #04CF5C; color: #fff; margin-top: 3vh;"
            >
                <img src="@/assets/_img/naver.png" class="sns_lnk" />
                네이버로 로그인
            </div>
            <div class="spacer_sm"></div>
            <div
                @click="showSNSModal = true"
                class="pad_md rnd_md"
                style="background-color: #FFD966; color: 3D1F1E;"
            >
                <img src="@/assets/_img/kakao.png" class="sns_lnk" />
                카카오톡으로 로그인
            </div>
        </modal>
        <div>
            <section class="login_container">
                <p class="weight700 font40 gray900">Be Well with</p>
                <p class="weight700 font80 gray900 brand_gradient">
                    Wellingbe
                </p>
                <div id="app">
                    <div class="spacer_lg">
                        <div>
                            <div>
                                <input
                                    class="main_inp spacer_sm"
                                    type="text"
                                    v-model="email"
                                    placeholder="아이디"
                                    autofocus
                                />
                            </div>
                        </div>
                        <div>
                            <div>
                                <input
                                    class="main_inp"
                                    type="password"
                                    id="pwField"
                                    v-model="password"
                                    placeholder="비밀번호"
                                /><a
                                    @click="togglePwShow"
                                    style="margin-left: -30px;"
                                    ><i id="togglePwIcon" class="fa fa-eye"></i
                                ></a>
                            </div>
                            <!-- <div>
                                <span
                                    v-if="pwStatus != ''"
                                    style="color: lightgrey;"
                                    >{{ pwStatus }}</span
                                >
                            </div> -->
                        </div>
                    </div>

                    <div style="margin-top: 2vh;">
                        <button
                            class="gradient_button pad_lg"
                            style="width: 25vw;"
                            @click="submitLogin"
                        >
                            <!-- <button
                            v-if="canLogin"
                            class="gradient_button "
                            style="width: 25vw;"
                            @click="submitLogin"
                        > -->
                            로그인
                        </button>
                        <!-- <button v-else disabled>로그인</button> -->
                    </div>
                    <div class="spacer_lg"></div>
                    <div
                        class="row"
                        style="justify-content: space-between; align-items: center; padding: 0 1vw;"
                    >
                        <span>
                            <input
                                id="keepLogged"
                                type="checkbox"
                                v-model="keepLogged"
                            />
                            <label for="keepLogged" class="spacer_h_xs_l"
                                >자동로그인</label
                            >
                        </span>

                        <span @click="resetPW" class="font13 gray500 bord_gray"
                            >정보찾기</span
                        >
                    </div>
                    <hr class="spacer_lg" />

                    <div style="margin-top: 2vh;"></div>
                    <div
                        @click="showSNSModal = true"
                        class="pad_md rnd_md"
                        style="background-color: #04CF5C; color: #fff;"
                    >
                        <img src="@/assets/_img/naver.png" class="sns_lnk" />
                        네이버로 로그인
                    </div>
                    <div class="spacer_sm"></div>
                    <div
                        @click="showSNSModal = true"
                        class="pad_md rnd_md"
                        style="background-color: #FFD966; color: 3D1F1E;"
                    >
                        <img src="@/assets/_img/kakao.png" class="sns_lnk" />
                        카카오톡으로 로그인
                    </div>
                    <div class="spacer_lg"></div>
                    <div style="text-align: center;">
                        아직 회원이 아니신가요?
                        <br />
                        <router-link :to="paths.register">회원가입</router-link>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
import modal from "@/components/Modal";
import mxNotify from "@/components/mx-notify.js";
import Validate from "@/components/Validate.js";
export default {
    Name: "Login",
    components: { modal },
    mixins: [Validate, mxNotify],
    data() {
        return {
            email: "",
            password: "",
            pwField: null,
            togglePwIcon: null,
            wbUser: "",
            keepLogged: true,
            paths: {
                register: "/register/client",
            },
            showSNSModal: false,
        };
    },
    created() {
        window.addEventListener("keydown", this.kbSupport);
    },
    mounted() {
        this.pwField = document.getElementById("pwField");
        this.togglePwIcon = document.getElementById("togglePwIcon");
    },
    computed: {
        mustValidateLoginAsEmail() {
            if (this.email == "") return true;
            else return false;
        },
        loginOK() {
            if (this.mustValidateLoginAsEmail)
                return this.validate_email(this.email);
            else return this.email.length > 2;
        },
        pwOK() {
            return this.validate_password(this.password);
        },
        canLogin() {
            if (this.loginOK && this.pwOK) return true;
            else return false;
        },
        pwStatus() {
            if (!this.pwOK) return "6 or more characters";
            else return "";
        },
        redirectLocation() {
            return this.$store.getters.GET_PATH + "/home";
        },
    },
    watch: {
        userLevel: function(newVal) {
            if (newVal !== 0) this.$router.push(this.redirectLocation);
        },
    },
    methods: {
        kbSupport(e) {
            if (this.canLogin) {
                if (e.key == "Enter") {
                    this.submitLogin();
                }
            }
        },
        submitLogin() {
            firebase
                .auth()
                .signInWithEmailAndPassword(this.email, this.password)
                .then(() => {
                    this.formReset();
                    firebase
                        .auth()
                        .currentUser.getIdToken()
                        .then((idToken) => {
                            // console.log('token after login: ', idToken)
                            this.$store.commit("SET_TOKEN", idToken);
                            // console.log('store token: ', this.$store.state.token)
                            axios
                                .get("/user/get")
                                .then((res) => {
                                    // console.log(res)
                                    this.ntfSuccess("Login successful", true);
                                    this.$store.dispatch("SET_USER", res.data);
                                    console.log(
                                        "set store user to: ",
                                        this.$store.state.currentUser
                                    );
                                    this.$router.push(this.redirectLocation);
                                })
                                .catch((err) => console.log(err));
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => alert(err.message));
        },
        togglePwShow: function() {
            if (this.pwField.type === "password") {
                this.pwField.type = "text";
                this.togglePwIcon.classList.remove("fa-eye");
                this.togglePwIcon.classList.add("fa-eye-slash");
            } else {
                this.pwField.type = "password";
                this.togglePwIcon.classList.remove("fa-eye-slash");
                this.togglePwIcon.classList.add("fa-eye");
            }
        },
        updateStore() {
            console.log("getWbUser");
            firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    axios
                        .get("/user/get", { params: { token: idToken } })
                        .then((res) => {
                            this.$store.commit("SET_CURRENT_USER", res.data);
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err));
        },
        formReset() {
            this.email = "";
            this.password = "";
        },
        async resetPW() {
            if (!this.loginOK) {
                alert("The email you have entered is not valid.");
                return false;
            }
            try {
                let auth = firebase.auth();
                return auth
                    .sendPasswordResetEmail(this.email)
                    .then(() => {
                        alert(
                            "Please check your Email and follow the instructions to reset your password!"
                        );
                    })
                    .catch((err) => {
                        this.ntfError(
                            "Oops. Something went wrong. Please try again later!",
                            true
                        );
                        console.log(err);
                    });
            } catch {
                this.ntfError(
                    "Oops. Something went wrong. Please try again later!",
                    true
                );
            }
        },
    },
};
</script>

<style scoped>
a:hover {
    cursor: pointer;
}
.login_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.main_inp {
    background-color: #f7f7f7;
    color: #999999;
    width: 20%;
    padding: 1vh 1vw;
    border-radius: 4px;
    width: 25vw;
}
.sns_lnk {
    width: 20px;
    height: 20px;
    margin-right: 0.5vw;
    border-radius: 4px;
}
</style>
