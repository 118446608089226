<template>
    <section id="main">
        <div class="container main_container noHeaderPicSpace">
            <section id="content">
                <div id="vueContainer" v-cloak>
                    <h3>Upcoming sessions</h3>
                    <div v-if="sessionsAug.length > 0">
                        <div
                            v-for="(s, sindex) in sessionsAug"
                            class="shadowbox"
                            :style="getSessionStyle(sindex)"
                            :key="sindex"
                        >
                            {{ showDate(s.start) }}
                            <span v-if="s.google_meet_url != ''">
                                -
                                <a
                                    :href="s.google_meet_url"
                                    target="_blank"
                                    title="Google Meet link"
                                >
                                    <i class="fa fa-video"></i>
                                    {{ s.google_meet_url }}
                                </a>
                            </span>
                            <br />
                            <Classcode
                                :code="s.course_code"
                                :color="s.course_color"
                            >
                            </Classcode>
                            <a :href="paths.class + s.course_id">{{
                                s.course_title
                            }}</a>
                        </div>
                    </div>
                    <div v-else>
                        No upcoming sessions.
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import Classcode from "@/components/Classcode";
import mixinOutputHelpers from "@/components/mixin-output-helpers.js";
import moment from "moment";
export default {
    name: "HomeCoach",
    mixins: [mixinOutputHelpers],
    components: {
        Classcode,
    },
    data() {
        return {
            sessions: [],

            paths: {
                class: "/coach/course/",
            },
        };
    },
    created() {
        axios
            .get("/teacher/home/init")
            .then((res) => {
                this.sessions = res.data.sessions;
            })
            .catch((err) => console.log(err));
    },
    computed: {
        sessionsAug() {
            let tmp = this.sessions;

            return tmp.sort(function(a, b) {
                let aDate = Date.parse(a.start);
                let bDate = Date.parse(b.start);

                if (aDate < bDate) return -1;
                else if (aDate > bDate) return 1;
                else return 0;
            });
        },
    },
    watch: {},
    methods: {
        showDate(string) {
            return moment(string).format("YYYY-MM-DD HH:mm (ddd)");
        },
        getSessionStyle(sindex) {
            let em = "1.2";
            if (sindex == 0) em = "1.6";
            return ` font-size: ${em}em;  `;
        },
    },
};
</script>

<style></style>
