export default {
    data: function() {
        return {
            saFields: {
                genHealth: [
                    { 
                        field: 'blood_pressure',
                        display: '내 혈압은 정상 범위 안에 있다.',
                    },
                    { 
                        field: 'blood_sugar',
                        display: '내 혈당 수치는 정상 범위 안에 있다.',
                    },
                    { 
                        field: 'cholesterol',
                        display: '내 콜레스테롤 수치는 정상 범위 안에 있다.',
                    },
                    { 
                        field: 'weight',
                        display: '내 체중은 정상 범위 안에 있다.',
                    },
                    { 
                        field: 'reg_exam',
                        display: '나는 정기적으로 신체검사를 받는다.',
                    },
                    { 
                        field: 'primary_doc',
                        display: '나는 믿고 다니는 의사와 병원이 있다.',
                    },
                    { 
                        field: 'no_chronic',
                        display: '나는 만성 질환이 없다.',
                    },
                    { 
                        field: 'control_chronic',
                        display: '나는 지병이 있지만 그것을 관리하는 방법을 배웠다.',
                    },
                    { 
                        field: 'meds_coping',
                        display: '기본적으로 내 정신 질환을 통제하는 약을 먹거나 대처 능력이 있다.',
                    },
                    { 
                        field: 'sobriety',
                        display: '나는 술을 끊거나 술 안마시는 시간을 조절할 수 있다.',
                    },
                    { 
                        field: 'areas_improve',
                        display: '나는 내 건강의 어떤 부분을 개선하고 싶은지 안다.',
                    },
                ],
    
                healthResilience: [
                    {
                        field: 'healthy_food',
                        display: '나는 정기적으로 건강한 음식을 챙겨 먹는다.',
                    },
                    {
                        field: 'better_healthy_food',
                        display: '나는 내가 좋아하고 내 식단에 추가할 수 있는 건강한 음식들을 알고 있다.',
                    },
                    {
                        field: 'exercise',
                        display: '나는 규칙적으로 운동을 한다.',
                    },
                    {
                        field: 'better_exercise',
                        display: '나는 내가 즐기고 내 삶을 좋게 하는 신체 활동을 알고 있다.',
                    },
                    {
                        field: 'sleep',
                        display: '나는 규칙적으로 충분한 수면을 취한다.',
                    },
                    {
                        field: 'better_sleep',
                        display: '나는 수면의 질을 향상시키기 위해 할 수 있는 몇 가지 방법을 알고 있다.',
                    },
                ],
    
                mindSpirit: [
                    {
                        field: 'reduce_stress',
                        display: '나는 내 인생에서 스트레스를 줄이는 방법을 알고 있다.',
                    },
                    {
                        field: 'better_reduce_stress',
                        display: '나는 살면서 스트레스를 덜 받기 위해 할 수 있는 몇 가지 방법을 알고 있다.',
                    },
                    {
                        field: 'help_others',
                        display: '나는 다른 사람을 돕는 일에 참여한다.',
                    },
                    {
                        field: 'better_help_others',
                        display: '나는 다른 사람들을 돕기 위해 내가 할 수 있는 일과 내가 그것을 즐길 수 있는 몇 가지 방법을 알고 있다. ',
                    },
                    {
                        field: 'support_network',
                        display: '함께 인생을 즐겁게 지낼 사람들이 있다.',
                    },
                    {
                        field: 'better_support_network',
                        display: '나는 지원해주는 네트워크를 넓히기 위해 할 수 있는 몇 가지 방법을 알고 있다.',
                    },
                    {
                        field: 'optimistic',
                        display: '나는 내 미래에 대해 낙관적인 사람이라고 생각한다.',
                    },
                    {
                        field: 'better_optimistic',
                        display: '나는 낙관적인 미래로 만들기 위해 내가 할 수 있는 일을 알고 있다.',
                    },
                    {
                        field: 'positive',
                        display: '나는 긍정적인 태도를 가지고 있다.',
                    },
                    {
                        field: 'better_positive',
                        display: '나는 부정적인 생각을 다루거나 대처하기 위해 할 수 있는 몇 가지 방법을 알고 있다.',
                    },
                    {
                        field: 'spiritual',
                        display: '나는 어려운 시기 동안 나를 지탱해주는 영적인 믿음과 실천하는 행동이 있다.',
                    },
                    {
                        field: 'better_spiritual',
                        display: '나는 영적인 삶을 강화하기 위해 내가 할 수 있는 몇 가지 행동을 알고 있다.',
                    },
                    {
                        field: 'purpose',
                        display: '나는 삶의 의미와 삶에 대한 목적이 강하게 있다.',
                    },
                    {
                        field: 'better_purpose',
                        display: '나는 의미와 목적을 높이기 위해 할 수 있는 몇 가지 일을 알고 있다.',
                    },
                ],
                
            },
            goalsFields: {
                impact: [
                    {
                        english: 'Improve',
                        question: '목표를 달성하는 것이 내 건강의 질과 회복력을 향상시킨다고 생각하세요?',
                        field: 'q_improve',
                    },
                    {
                        english: 'Measurable',
                        question: '측정 가능한 방법- 내가 그것을 성취했는지를 객관적으로 측정할 수 있나요? 어떤 것을 측정할 수 있으려면, 그것은 보통 얼마나, 얼마나 자주, 또는 얼마나 많은 양 했는지 적어야 됩니다.',
                        field: 'q_measurable',
                    },
                    {
                        english: 'Positively Stated',
                        question: '긍정적인 상태 - 내 인생에서 내가 원하는 새로운 것 얻기 위해 긍정적인 상태이세요? 없애고, 피하고, 바꾸고 싶은 것에 집중하는 것보다 원하는 것을 얻기 위해 노력하는 것이 더 동기가 부여 됩니다.',
                        field: 'q_positive',
                    },
                    {
                        english: 'Achievable',
                        question: '달성가능성-현재 상황과 능력으로 달성할 수 있습니까? 주어진 시간 내에 목표를 달성할 수 없다고 생각되면 일반적으로 금액을 줄이거나 시간을 변경해 해결할 수 있습니다.',
                        field: 'q_achievable',
                    },
                    {
                        english: 'Call Forth Actions',
                        question: '활동 요청- 내가 건강한 습관이나 더 건강한 생활 방식을 만들기 위해 규칙적으로 할 수 있는 조치를 취하고 있나요? 일정 기간 동안 목표를 달성하기 위해 노력해야 되는 것을 명심해야 됩니다. 따라서 목표를 당성하기 위해 할 수 있는 조치가 필요합니다.',
                        field: 'q_action',
                    },
                    {
                        english: 'Time limited',
                        question: '제한 시간 – 언제까지 당설할 계획인지에 따라 시간 제한 이 있나요? 목표를 언제까지  달성할 계획인지 알 수 있도록 정할 필요가 있습니다.',
                        field: 'q_time',
                    },
                ],                
            },
        };
    },
};