<template>
    <section id="main">
        <div class="container main_container noHeaderPicSpace">
            <section id="content">
                <div id="vueContainer" v-cloak>
                    <h3>
                        선생님 프로필:
                        {{ tData.firstname }} {{ tData.lastname }}
                    </h3>
                    <div class="shadowbox">
                        <div class="table-div">
                            <div>
                                <div>이메일</div>
                                <div>
                                    <a :mailto="tData.email">{{
                                        tData.email
                                    }}</a>
                                </div>
                            </div>
                            <div>
                                <div>전화번호</div>
                                <div>{{ tData.mobile }}</div>
                            </div>
                            <div>
                                <div>가입날짜</div>
                                <div>{{ showDate(tData.joined) }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="shadowbox" style="margin-top: 2vh;">
                        <h4>Upcoming Sessions</h4>
                        <div
                            v-if="sData.length > 0"
                            class="table-div"
                            style="width: 99%;"
                        >
                            <div class="th">
                                <div style="width: 55%;">수업</div>
                                <div style="width: 15%;">첫수업</div>
                            </div>
                            <div v-for="(s, idx) in sData" :key="idx">
                                <div>
                                    <classcode
                                        :color="s.course_color"
                                        :code="s.course_code"
                                    ></classcode>
                                    <router-link
                                        :to="
                                            authPrefix + paths.classPath + s.id
                                        "
                                        >{{ s.course_title }}</router-link
                                    >
                                </div>
                                <div>
                                    {{ showDate(s.start) }}
                                </div>
                            </div>
                        </div>
                        <div v-else><i>no classes</i></div>
                    </div>

                    <div class="shadowbox" style="margin-top: 2vh;">
                        <h4>수업</h4>
                        <div
                            v-if="cData.length > 0"
                            class="table-div"
                            style="width: 99%;"
                        >
                            <div class="th">
                                <div style="width: 55%;">수업</div>
                                <div style="width: 15%;">횟수</div>
                                <div style="width: 15%;">첫수업</div>
                                <div style="width: 15%;">마지막 수업</div>
                            </div>
                            <div v-for="(c, idx) in cData" :key="idx">
                                <div>
                                    <classcode
                                        :color="c.color"
                                        :code="c.code"
                                    ></classcode>
                                    <a
                                        :href="
                                            authPrefix + paths.classPath + c.id
                                        "
                                        >{{ c.title }}</a
                                    >
                                </div>
                                <div>
                                    {{ c.cnt_session }}
                                </div>
                                <div>
                                    {{ showDate(c.firstsession) }}
                                </div>
                                <div>
                                    {{ showDate(c.lastsession) }}
                                </div>
                            </div>
                            <endless
                                ref="endlessLoader"
                                :loadpath="loadMorePath"
                                :perpage="parseInt(perpage)"
                                mainprop="courses"
                                @loaded="appendLoaded"
                            ></endless>
                        </div>
                        <div v-else><i>no sessions</i></div>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import endless from "@/components/endless";
import mixinOutputHelpers from "@/components/mixin-output-helpers.js";
import moment from "moment";
import Classcode from "@/components/Classcode";
export default {
    name: "ProfileCoach",
    mixins: [mixinOutputHelpers],
    components: {
        Classcode,
        endless,
    },
    data() {
        return {
            tData: {},
            sData: [],
            cData: [],

            user_id: "",

            perpage: "",

            paths: {
                classPath: "/course/",
                loadmore: "/teacher/loadmore/",
            },
        };
    },
    created() {
        this.user_id = this.$route.params.tid;

        axios
            .get("/teacher/profile/get", { params: { id: this.user_id } })
            .then((res) => {
                console.log(res.data);
                this.tData = res.data.tData;
                this.cData = res.data.cData;
                this.sData = res.data.sData;
                this.perpage = res.data.perpage;
            })
            .catch((err) => console.log(err));
    },
    computed: {
        authPrefix() {
            return this.$store.getters.GET_PATH;
        },
        loadMorePath() {
            return this.paths.loadmore + this.user_id;
        },
    },
    watch: {},
    methods: {
        appendLoaded(loadedData) {
            this.cData.push.apply(this.cData, loadedData.cData);
        },
        resetEndless() {
            if (this.$refs.endless != undefined) this.$refs.endless.reset();
        },
        showDate(string) {
            if (string == null) return "";
            else return moment(string).format("YYYY-MM-DD HH:mm");
        },
    },
};
</script>

<style></style>
