<template>
    <section id="main">
        <div class="container main_container noHeaderPicSpace">
            <section id="content">
                <div id="vueContainer" v-cloak>
                    <modal v-if="sa.view" @closeme="sa.view = false">
                        <DisplaySA :sa="saData"></DisplaySA>
                    </modal>

                    <modal v-if="goals.view" @closeme="goals.view = false">
                        <DisplayGoals :goals="goalsData"></DisplayGoals>
                    </modal>

                    <h3>
                        학생 프로필:
                        {{ sData.firstname }} {{ sData.lastname }}
                    </h3>
                    <div class="shadowbox">
                        <div class="table-div">
                            <div>
                                <div>이메일</div>
                                <div>
                                    <a :mailto="sData.email">{{
                                        sData.email
                                    }}</a>
                                </div>
                            </div>
                            <div>
                                <div>전화번호</div>
                                <div>{{ sData.mobile }}</div>
                            </div>
                            <div>
                                <div>가입날짜</div>
                                <div>{{ showDate(sData.joined) }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="shadowbox" style="margin-top: 2vh;">
                        <h4>숙제</h4>
                        <div class="table-div">
                            <div>
                                <div>자기 평가표</div>
                                <div>
                                    <span v-if="countSA > 0">
                                        <a @click="viewSa">보기</a> - 제출:
                                        {{ showDate(saData.timestamped) }}
                                    </span>
                                    <span v-else>미제출</span>
                                </div>
                            </div>
                            <div>
                                <div>AWH달성목표</div>
                                <div>
                                    <span v-if="countGoals > 0">
                                        <a @click="viewGoals">보기</a> - 제출:
                                        {{ showDate(goalsData.timestamped) }}
                                    </span>
                                    <span v-else>미제출</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="classesConfAug.length > 0"
                        class="shadowbox"
                        style="margin-top: 2vh;"
                    >
                        <h4>확정된 수업</h4>
                        <div class="table-div" style="width: 99%;">
                            <div class="th">
                                <div style="width: 55%;">수업</div>
                                <div style="width: 15%;" class="center">
                                    횟수
                                </div>
                                <div style="width: 15%;">첫수업</div>
                                <div style="width: 15%;">마지막 수업</div>
                            </div>
                            <div v-for="(c, idx) in classesConfAug" :key="idx">
                                <div>
                                    <classcode
                                        :color="c.color"
                                        :code="c.code"
                                    ></classcode>
                                    <a
                                        :href="
                                            authPrefix + paths.classPath + c.id
                                        "
                                        >{{ c.title }}</a
                                    >
                                </div>
                                <div class="center">
                                    {{ c.sessions.length }}
                                </div>
                                <div>
                                    {{ showDate(c.s1_start) }}
                                </div>
                                <div>
                                    {{ showDate(c.sx_start) }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="classesIntAug.length > 0"
                        class="shadowbox"
                        style="margin-top: 2vh;"
                    >
                        <h4>신청한 수업</h4>
                        <div class="table-div" style="width: 99%;">
                            <div class="th">
                                <div style="width: 55%;">수업</div>
                                <div style="width: 15%;" class="center">
                                    횟수
                                </div>
                                <div style="width: 15%;">첫수업</div>
                                <div style="width: 15%;">마지막 수업</div>
                            </div>
                            <div v-for="(c, idx) in classesIntAug" :key="idx">
                                <div>
                                    <classcode
                                        :color="c.color"
                                        :code="c.code"
                                    ></classcode>
                                    <router-link
                                        :to="
                                            authPrefix + paths.classPath + c.id
                                        "
                                        >{{ c.title }}</router-link
                                    >
                                </div>
                                <div class="center">
                                    {{ c.sessions.length }}
                                </div>
                                <div>
                                    {{ showDate(c.s1_start) }}
                                </div>
                                <div>
                                    {{ showDate(c.sx_start) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import mixinOutputHelpers from "@/components/mixin-output-helpers.js";
import DisplaySA from "@/components/DisplaySA";
import DisplayGoals from "@/components/DisplayGoals";
import mxDocfields from "@/components/mx-docfields.js";
import modal from "@/components/Modal";
import moment from "moment";
import Classcode from "@/components/Classcode";
export default {
    name: "ProfileClient",
    mixins: [mixinOutputHelpers, mxDocfields],
    components: {
        Classcode,
        DisplaySA,
        DisplayGoals,
        modal,
    },
    data() {
        return {
            sData: [],
            countSA: "",
            countGoals: "",
            saData: [],
            goalsData: [],
            classesInterested: [],
            classesConfirmed: [],
            user_id: "",

            sa: {
                view: false,
            },
            goals: {
                view: false,
            },

            paths: {
                classPath: "/course/", //use computed
            },
        };
    },
    created() {
        this.user_id = this.$route.params.user_id;

        axios
            .get("/student/get", { params: { id: this.user_id } })
            .then((res) => {
                console.log(res.data);
                this.sData = res.data.student_data;
                this.count_SA = res.data.count.self_assessment;
                this.countGoals = res.data.count.goals;
                this.saData = res.data.self_assessment;
                this.goalsData = res.data.goals;
                this.classesInterested = res.data.interested;
                this.classesConfirmed = res.data.confirmed;
            })
            .catch((err) => console.log(err));
    },
    computed: {
        authPrefix() {
            return this.$store.getters.GET_PATH;
        },
        classesIntAug() {
            let tmp = this.classesInterested;
            return tmp.sort(function(a, b) {
                // have sessions vs no sessions
                let aSes = 0;
                let bSes = 0;
                if (a.sessions.length > 0) aSes = 1;
                if (b.sessions.length > 0) bSes = 1;

                if (aSes > bSes) return -1;
                if (aSes < bSes) return 1;
                return 0;
            });
        },
        classesConfAug() {
            let tmp = this.classesConfirmed;
            return tmp.sort(function(a, b) {
                // have sessions vs no sessions
                let aSes = 0;
                let bSes = 0;
                if (a.sessions.length > 0) aSes = 1;
                if (b.sessions.length > 0) bSes = 1;

                if (aSes > bSes) return -1;
                if (aSes < bSes) return 1;
                return 0;
            });
        },
    },
    watch: {},
    methods: {
        viewSa() {
            this.sa.view = true;
        },
        viewGoals() {
            this.goals.view = true;
        },
        showDate(string) {
            if (string == null) return "";
            else return moment(string).format("YYYY-MM-DD HH:mm");
        },
    },
};
</script>

<style></style>
