<template>
    <section class="container noHeaderPicSpace">
        <div>
            <h3>Register</h3>
            <div id="myVue">
                <div class="flex_box">
                    <input
                        type="text"
                        v-model="regEmail"
                        placeholder="E-mail"
                        v-on:focus="resetEmailError"
                    />

                    <input
                        type="text"
                        v-model="regFirst"
                        placeholder="First Name"
                    />

                    <input
                        type="text"
                        v-model="regLast"
                        placeholder="Last Name"
                    />

                    <input
                        type="number"
                        v-model="regMobile"
                        placeholder="휴대폰 번호"
                    />

                    <input
                        type="password"
                        v-model="regPass"
                        placeholder="비밀번호"
                    />
                    <span style="font-size: 0.8em;">
                        at least 6 characers, special characters allowed
                    </span>

                    <input
                        type="password"
                        v-model="regConfirm"
                        placeholder="비밀번호"
                    />

                    <button @click="submitRegistration" class="button">
                        가입 하기
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import Validate from "../components/Validate.js";
import firebase from "firebase";
import mxNotify from "@/components/mx-notify.js";
export default {
    name: "Register",
    mixins: [Validate, mxNotify],
    data() {
        return {
            mode: "",

            regEmail: "",
            regFirst: "",
            regLast: "",
            regPass: "",
            regConfirm: "",
            regMobile: "",

            emailChecked: false,
            emailUnique: false,
        };
    },
    computed: {
        userLevel() {
            if (this.$route.params.mode == "client")
                return this.$store.state.lvl.client;
            else return this.$store.state.lvl.coach;
        },
        hasValidEmail() {
            return this.validate_email(this.regEmail);
        },
        redirectLocation() {
            return this.$store.getters.GET_PATH + "/home";
        },
    },
    methods: {
        submitRegistration() {
            if (this.regFirst.length < 1) {
                alert("Enter your first name");
                return false;
            } else if (this.regLast.length < 1) {
                alert("Enter your last name");
                return false;
            } else if (!this.validate_password(this.regPass)) {
                alert("Enter a password (at least 6 characters).");
                return false;
            } else if (this.regPass != this.regConfirm) {
                alert("Password and confirm do not match.");
                return false;
            } else if (!this.hasValidEmail) {
                alert("Enter a valid Email");
                return false;
            }

            firebase
                .auth()
                .createUserWithEmailAndPassword(this.regEmail, this.regPass)
                .then((userCredentials) => {
                    console.log(userCredentials);
                    this.submitNewUser();
                })
                .catch((err) => alert(err.message));
        },

        async submitNewUser() {
            try {
                let pl = {
                    firstname: this.regFirst,
                    lastname: this.regLast,
                    email: this.regEmail,
                    mobile: this.regMobile,
                    level: this.userLevel,
                };

                let token = await firebase.auth().currentUser.getIdToken();
                this.$store.commit("SET_TOKEN", token);

                // console.log('committing token to store: ', token);

                await axios.get("/user/add", { params: pl });

                pl.level = this.userLevel;

                this.$store.commit("SET_CURRENT_USER", pl);
                // console.log('committing user to store: ', pl);

                this.$router.push(this.redirectLocation);
            } catch {
                this.ntfError(
                    "Oops. Something went wrong. Please try again later!",
                    true
                );
            }
        },
        resetEmailError() {
            this.emailChecked = false;
            this.emailUnique = false;
            this.emailSms = "";
            this.emailEms = "";
        },
        resetForm() {
            this.resetEmailError();
            this.regEmail = "";
            this.regFirst = "";
            this.regLast = "";
            this.regPass = "";
            this.regConfirm = "";
        },
    },
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

input {
    margin: 2vh 0;
    padding: 1vh 1vw;
    border: 1px solid #cccccc;
    border-radius: 8px;
}

button {
    padding: 2vh 6vw;
    /* border: 1px solid #cccccc; */
    border-radius: 8px;
    background-color: #2db24e;
    color: #fff;
}
</style>
