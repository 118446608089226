import "./components/axios.js";
import App from "./App.vue";
import firebase from "firebase";
import router from "./router";
import store from "./store";
import Vue from "vue";

import moment from "moment";
import Modal from "./components/Modal";
import VCalendar from "@/assets/plugins/v-calendar.umd.min.js";
import vuetify from "@/assets/plugins/vuetify.js"; // path to vuetify export
window.$ = window.jQuery = require("jquery");

// const analytics = firebase.analytics();

Vue.config.productionTip = false;
Vue.prototype.moment = moment;
Vue.component("Modal");

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
    componentPrefix: "vc",
});

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDZ8ADhqkbEbqgXuOdjiVt5_bHYXULIW4I",
    authDomain: "wellingbe-188ec.firebaseapp.com",
    projectId: "wellingbe-188ec",
    storageBucket: "wellingbe-188ec.appspot.com",
    messagingSenderId: "280370484581",
    appId: "1:280370484581:web:b15dda48b5ba92653c7d8c",
    measurementId: "G-0H32TG9WQL",
    // apiKey: "AIzaSyDwyM1fuCMEuoDu78d8Ba7OIlW1nDZs6XQ",
    // authDomain: "test4-961d1.firebaseapp.com",
    // projectId: "test4-961d1",
    // storageBucket: "test4-961d1.appspot.com",
    // messagingSenderId: "449654993968",
    // appId: "1:449654993968:web:a461e146f110311323a9f5",
};

firebase.initializeApp(firebaseConfig);

store.dispatch("CHECK_AUTH").then(() => {
    new Vue({
        router,
        store,
        Modal,
        vuetify,
        render: (h) => h(App),
    }).$mount("#app");
});
