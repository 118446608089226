// dependencies: iziToast
import iziToast from 'izitoast'
export default {
    data: function() {
        return {
            iziSettings: {
                position: 'topRight',
                progressBar: false,
                timeout: 5000, // false to disable
            },
        };
    },
    created: function() {
        this.applyIziSettings(this.iziSettings);
    },
    methods: {
        ntfInfo: function(title, msg, hidePrevious) {
            this.runIzi("info", title, msg, hidePrevious);
        },
        ntfSuccess: function(title, msg, hidePrevious) {
            this.runIzi("success", title, msg, hidePrevious);
        },
        ntfWarning: function(title, msg, hidePrevious) {
            this.runIzi("warning", title, msg, hidePrevious);
        },
        ntfError: function(title, msg, hidePrevious) {
            this.runIzi("error", title, msg, hidePrevious);
        },
        ntfHideAll: function() {
            iziToast.destroy();
        },
        runIzi(fnName, one, two, three) {
            console.log(fnName, one, two, three);
            let hideAll = false;
            let contents;

            if (three != undefined) { // three additional args set
                contents = { title: one, message: two, };
                if (three === true)
                    hideAll = true;
            } else { // under 3 args
                if (two != undefined) { // two additional args set
                    if (typeof two === "boolean") {
                        console.log('eeee');
                        contents = { title: '', message: one };
                        if (two === true)
                            hideAll = true;
                    } else {
                        contents = { title: one, message: two, };
                    }
                } else if (one != undefined) { // under 2 args > 1 arg
                    contents = { title: '', message: one };
                } else {
                    return false; // contents not defined
                }
            }
            if (hideAll)
                this.ntfHideAll();
            iziToast[fnName](contents);
        },
        applyIziSettings: function(settings) {
            iziToast.settings(settings);
        },
    },
};